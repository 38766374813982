<nz-tabset style="border-bottom: 2px solid #ffa646; min-height: 43px; width: 100%; margin-bottom: 1px" (nzSelectChange)="selectTab($event)"
           [nzSelectedIndex]="selectedTab" nzHideAdd nzType="editable-card">
    <nz-tab [nzForceRender]="true" *ngFor="let tab of getTabs() | async"
            [nzTitle]="tabTitle">
        <ng-template #tabTitle>
            {{tab?.title}}
            <a style="color: #fff" nz-dropdown [nzDropdownMenu]="menu">
                <span class="tabMore" nz-icon nzType="more" nzTheme="outline"></span>
            </a>
        </ng-template>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <li class="flex-tab" nz-menu-item (click)="reloadTab(tab)">
                    <span>Recarregar</span>
                    <i class="fa-fw fas fa-redo"></i>
                </li>
                <li nz-menu-divider></li>
                <div style="min-width: 220px">
                    <li class="flex-tab" nz-menu-item (click)="openInNew(tab)">
                        <span>Abrir em nova guia</span>
                        <i class="fa-fw far fa-window-restore"></i>
                    </li>
                </div>
                <li nz-menu-divider></li>
                <li class="flex-tab" nz-menu-item (click)="removeTabById(tab.id)">
                    <span>Fechar</span>
                    <i class="fa-fw fas fa-times"></i>
                </li>
                <li *ngIf="tabs.length > 1" class="flex-tab" nz-menu-item (click)="removeOthersTabs(tab.id)">
                    <span>Fechar outras abas</span>
                    <i class="fa-fw far fa-window-close"></i>
                </li>
                <li class="flex-tab" nz-menu-item (click)="removeAllTabs()">
                    <span>Fechar todas abas</span>
                    <i class="fa-fw fas fa-window-close"></i>
                </li>
            </ul>
        </nz-dropdown-menu>
    </nz-tab>
</nz-tabset>