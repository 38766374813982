export class Favorito {

    id: string;
    descricao: string;
    descricaoEstendida: string;

    constructor(obj?: any) {

        if (obj) {

            const {
                id,
                descricao,
                descricaoEstendida,
            } = obj;

            this.id = id;
            this.descricao = descricao;
            this.descricaoEstendida = descricaoEstendida;

        }

    }

}
