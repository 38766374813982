import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractService} from '@services/abstract.service';
import {formatDate} from '@angular/common';
import {Usuario} from '@models/usuario.model';

@Injectable({providedIn: 'root'})

export class UsuarioDetalheService extends AbstractService<Usuario> {

    constructor(http: HttpClient) {
        super(http, '/administracao/usuario', {});
    }

    get(id: number | string): Observable<any> {

        return this.http.get(`${this.baseUrl}/detalhe/${id}`);

    }

    save(id, campos): Observable<any> {

        if (campos.primeiroNome) {

            campos.nome = campos.primeiroNome + (campos.sobreNome ? ' ' + campos.sobreNome : '');
            delete campos.primeiroNome;
            delete campos.sobreNome;

        }

        if (campos.nascimentoData) {

            campos.nascimentoData = formatDate(campos.nascimentoData, 'YYYY-MM-dd', 'pt_BR');

        }

        return this.http.put(`${this.baseUrl}/detalhe/${id}`, campos);

    }

    emailBoasVindas(arrayUsuarios: any): Observable<any> {

        return this.http.post(`${this.baseUrl}/administracao/usuario/emailBoasVindas`, {usuarios: arrayUsuarios});

    }


    getAvatar(id: string): Observable<any> {

        return this.http.get(`${this.baseUrl}/detalhe/${id}/avatar`);

    }

    uploadAvatar(id: string, formData: FormData): Observable<any> {

        return this.http.post(`${this.baseUrl}/detalhe/${id}/avatar`, formData);

    }

    removerAvatar(id: string): Observable<any> {

        return this.http.delete(`${this.baseUrl}/detalhe/${id}/avatar`);
    }

    getUserLogado(): Observable<any> {

        return this.http.get(`${this.apiUrl}/usuario-logado/detalhe`);

    }

    gerarToken(id: string): Observable<any> {

        return this.http.post(`${this.apiUrl}/administracao/perfil/${id}/gerar-token-agent`, {});

    }



}
