import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './pages/error.component';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { UiModule } from '../ui.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ErrorRoutingModule } from './error-routing.module';


@NgModule({
    declarations: [ErrorComponent],
    imports: [
        CommonModule,
        NzResultModule,
        NzButtonModule,
        CommonModule,
        UiModule,
        RouterModule,
        ReactiveFormsModule,
        ErrorRoutingModule
    ],
    exports: [
        ErrorComponent
    ]
})
export class ErrorModule {
}
