import { UrlTypeEnum } from '../enums/url-type.enum';

export class Tab {
    public id: number;
    public title: string;
    public data: any;
    public active: boolean;
    public componentName: string;
    public url: string;
    public urlType: UrlTypeEnum;

    constructor(componentName: string, title: string, url: string, urlType: UrlTypeEnum, data?: any) {
        this.data = data;
        this.componentName = componentName;
        this.title = title;
        this.urlType = urlType;
        this.url = url;
    }

}
