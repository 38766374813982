import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import Visibilidade from '@models/visibilidade.model';
import {AbstractService} from '@services/abstract.service';
import {environment} from '../../../../environments/environment';
import {Helpers} from "../../../core/helpers";
import {formataDatas} from "../../../shared/components-helper";


@Injectable({providedIn: 'root'})
export class UsuariosLogadosService extends AbstractService<Visibilidade> {

    constructor(http: HttpClient) {
        super(http, '/monitoramento/logins-usuarios', Visibilidade);
    }


    exportExcel(filtros: any, search: string = null, tipo: string): any {

        filtros.tipoExportacao = tipo;

        if (filtros.ultimoLoginDe) {
            filtros.ultimoLoginDe = formataDatas(filtros.ultimoLoginDe, 'AAAA-MM-DD');
        }

        if (filtros.ultimoLoginAte) {
            filtros.ultimoLoginAte = formataDatas(filtros.ultimoLoginAte, 'AAAA-MM-DD');
        }

        const queryStr = this.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/monitoramento/logins-usuarios/exportar${queryStr}`,
            {responseType: 'blob'});

    }
}
