import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import Visibilidade from '@models/visibilidade.model';
import {AbstractService} from '../../../core/services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class RelatoriosService extends AbstractService<Visibilidade> {

    constructor(http: HttpClient) {
        super(http, '/administracao/visibilidades', Visibilidade);
    }

    exportExcelNSU(): any {
        return this.http.get(`${environment.apiUrl}/elasticsearch/manifestacao/exportar/log`,
            {responseType: 'blob'});

    }

}
