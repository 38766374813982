import {ErrorComponent} from '../../modules/error/pages/error.component';
import {ExternalPageComponent} from '../../modules/external-page/pages/external-page.component';
import {UsuarioLogadoComponent} from '../../modules/usuario-logado/usuario-logado.component';
import {UsuariosComponent} from '../../modules/administracao/usuarios/usuarios.component';
import {AvisosComponent} from '../../modules/administracao/avisos/avisos.component';
import {UsuariosLogadosComponent} from '../../modules/monitoramento/usuarios-logados/usuarios-logados.component';
import {UsuarioDetalheComponent} from '../../modules/administracao/usuarios/usuario-detalhe/usuario-detalhe.component';
import {VisibilidadesComponent} from '../../modules/administracao/visibilidades/visibilidades.component';
import {VisibilidadeDetalheComponent} from '../../modules/administracao/visibilidades/visibilidade-detalhe/visibilidade-detalhe.component';
import {PlanosComponent} from '../../modules/administracao/planos/planos.component';
import {PlanoDetalheComponent} from '../../modules/administracao/planos/plano-detalhe/plano-detalhe.component';
import {ModulosComponent} from '../../modules/administracao/modulos/modulos.component';
import {ModuloDetalheComponent} from '../../modules/administracao/modulos/modulo-detalhe/modulo-detalhe.component';
import {AvisoDetalheComponent} from '../../modules/administracao/avisos/aviso-detalhe/aviso-detalhe.component';
import {PerfilDetalheComponent} from '../../modules/administracao/perfis/perfil-detalhe/perfil-detalhe.component';
import {PerfisComponent} from '../../modules/administracao/perfis/perfis.component';
import {ContasComponent} from '../../modules/contas/contas.component';
import {ContaDetalheComponent} from '../../modules/contas/conta-detalhe/conta-detalhe.component';
import {CreditosCaptchaComponent} from '../../modules/administracao/captcha/creditos-captcha.component';
import {RelatoriosComponent} from '../../modules/monitoramento/relatorios/relatorios.component';
import {ContasLimitesComponent} from '../../modules/relatorios/contas-limites/contas-limites.component';
import {LoadingComponent} from '../../modules/loading/loading.component';
import {StatusBaixasComponent} from '../../modules/monitoramento/statusBaixas/statusBaixas.component';
import {JobManagerComponent} from '../../modules/monitoramento/jobManager/jobManager.component';
import {CreditosComponent} from '../../modules/administracao/creditos/creditos.component';

export const componentsClassConst = {
    CreditosCaptchaComponent: {
        component: CreditosCaptchaComponent,
        name: 'CreditosCaptchaComponent',
    },
    ContasComponent: {
        component: ContasComponent,
        name: 'ContasComponent',
    },
    ContaDetalheComponent: {
        component: ContaDetalheComponent,
        name: 'ContaDetalheComponent',
    },
    UsuariosComponent: {
        component: UsuariosComponent,
        name: 'UsuariosComponent',
    },
    AvisosComponent: {
        component: AvisosComponent,
        name: 'AvisosComponent',
    },
    UsuariosContasComponent: {
        component: UsuariosLogadosComponent,
        name: 'UsuariosContasComponent',
    },
    UsuarioDetalheComponent: {
        component: UsuarioDetalheComponent,
        name: 'UsuarioDetalheComponent',
    },
    VisibilidadesComponent: {
        component: VisibilidadesComponent,
        name: 'VisibilidadesComponent',
    },
    PlanosComponent: {
        component: PlanosComponent,
        name: 'PlanosComponent',
    },
    PlanoDetalheComponent: {
        component: PlanoDetalheComponent,
        name: 'PlanoDetalheComponent',
    },
    ModulosComponent: {
        component: ModulosComponent,
        name: 'ModulosComponent',
    },
    ModuloDetalheComponent: {
        component: ModuloDetalheComponent,
        name: 'ModuloDetalheComponent',
    },
    AvisoDetalheComponent: {
        component: AvisoDetalheComponent,
        name: 'AvisoDetalheComponent',
    },
    VisibilidadeDetalheComponent: {
        component: VisibilidadeDetalheComponent,
        name: 'VisibilidadeDetalheComponent',
    },
    UsuarioLogadoComponent: {
        component: UsuarioLogadoComponent,
        name: 'UsuarioLogadoComponent',
    },
    PerfisComponent: {
        component: PerfisComponent,
        name: 'PerfisComponent',
    },
    PerfilDetalheComponent: {
        component: PerfilDetalheComponent,
        name: 'PerfilDetalheComponent',
    },
    RelatoriosComponent: {
        component: RelatoriosComponent,
        name: 'RelatoriosComponent',
    },
    ContasLimitesComponent: {
        component: ContasLimitesComponent,
        name: 'ContasLimitesComponent',
    },
    ExternalPageComponent: {
        component: ExternalPageComponent,
        name: 'ExternalPageComponent',
    },
    ErrorComponent: {
        component: ErrorComponent,
        name: 'Error',
    },
    LoadingComponent: {
        component: LoadingComponent,
        name: 'LoadingComponent',
    },
    StatusBaixasComponent: {
        component: StatusBaixasComponent,
        name: 'StatusBaixasComponent',
    },
    CreditosComponent: {
        component: CreditosComponent,
        name: 'CreditosComponent',
    },
    JobManagerComponent: {
        component: JobManagerComponent,
        name: 'JobManagerComponent',
    },
};
