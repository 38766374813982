import {Component, Input, OnInit, ViewChildren} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AvisoDetalheService} from './aviso-detalhe.service';
import {Perfil} from '@models/perfil.model';
import {ToastrService} from 'ngx-toastr';
import {DataService} from '@services/data.service';
import {Subscription} from 'rxjs';
import {NzTreeNode} from 'ng-zorro-antd/tree';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Usuario} from '@models/usuario.model';
import {UsuarioService} from '@services/usuario.service';
import {TabHandlerInterface} from '../../../../shared/interfaces/tab-handler.interface';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {buildUrl, findComponentByUrl} from '../../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {NzModalService} from 'ng-zorro-antd/modal';

interface RecursivoParams {
    nodes: NzTreeNode[];
    stack: string[];
}

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-visibilidade-detalhe',
    templateUrl: './aviso-detalhe.component.html',
    styleUrls: ['./aviso-detalhe.component.scss']
})

export class AvisoDetalheComponent extends AbstractListTable<any> implements OnInit {


    readonly registerLink = '/usuarios/cadastrar';

    @Input() data;
    @ViewChildren('treePerfil') treePerfil;

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigarório',
        }
    };

    aviso: any;
    checkedKeys = [];
    userAdmin = false;
    currentUser: Subscription;

    formDadosCadastrais: FormStack;
    formAdicionarConta: FormStack;
    formPermissoes: FormStack;

    carregando = false;
    gravando = false;
    loginCpf = true;
    permissoesTree: NzTreeNode[];

    tabNumber = 0;

    qtdFiltrosAtivos = 0;

    usuarios: any;
    contas: any;


    pagination: any = {
        usuarios: Pagination,
        contas: Pagination
    };
    comboContas: any = [];

    avisoPreview: any = null;

    modalPreviewVisible = false;

    constructor(
        private dataService: DataService,
        private usuarioService: UsuarioService,
        private toastService: ToastrService,
        private fb: UntypedFormBuilder,
        private modalService: NzModalService,
        private avisoDetalheService: AvisoDetalheService,
        public toastrService: ToastrService,
        private tabService: TabService
    ) {
        super(usuarioService, Usuario, toastService);

        this.formDadosCadastrais = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, [Validators.required]],
                ativo: [null, [Validators.required]],
                titulo: [null, [Validators.required]],
                mensagem: [null, [Validators.required]],
                dataInicio: [null, [Validators.required]],
                dataFim: [null, [Validators.required]],
            })
        };

        this.formAdicionarConta = {
            modalVisible: false,
            formGroup: this.fb.group({
                contas: [null, [Validators.required]],
            })
        };

    }

    ngOnInit(): void {

        this.carregar(this.data.id);
    }


    getSelectedItens() {

        const recursivo = ({nodes, stack}: RecursivoParams) => {

            for (const node of nodes) {

                if (node.children && node.children.length > 0) {

                    stack = recursivo({nodes: node.children, stack});

                }

                if ((node.isChecked || node.isHalfChecked) && stack.indexOf(node.key) === -1) {

                    stack.push(node.key);
                }


            }

            return stack;

        };

        let selected = [];

        this.treePerfil.forEach(tree => {

            selected = recursivo({nodes: tree.getTreeNodes(), stack: selected});

        });

        return selected;

    }

    setSelectedItens(keys: string[]) {

        const recursivo = (nodes: NzTreeNode[], hasChecked = 0) => {

            for (const node of nodes) {

                let count = 0;

                if (node.children && node.children.length > 0) {

                    count = recursivo(node.children);

                } else if (keys.indexOf(node.key) > -1) {

                    count++;

                }

                node.isChecked = count > 0;
                hasChecked += count;

            }

            return hasChecked;

        };

        this.treePerfil.forEach(tree => {

            recursivo(tree.getTreeNodes());

        });


    }

    async carregar(id, quiet = false) {

        if (!quiet) {
            this.carregando = true;
        }
        this.avisoDetalheService.retornaAviso(id).subscribe((response) => {
            this.aviso = response;
            this.carregando = false;
        });

    }

    editar(): void {

        for (const name in this.formDadosCadastrais.formGroup.controls) {

            console.log(this.aviso.ativo);

            // if (name) {

            this.formDadosCadastrais.formGroup.get(name).setValue(this.aviso[name]);

            // }
        }

        this.formDadosCadastrais.modalVisible = true;

    }

    adicionarConta(): void {

        this.getComboContas();
        this.formAdicionarConta.modalVisible = true;

    }

    fechar(formulario: FormStack): void {

        formulario.modalVisible = false;
        this.gravando = false;

    }

    confirmar(formulario: FormStack) {

        this.gravando = true;

        if (formulario.formGroup.valid) {

            this.gravar(this.data.id, formulario.formGroup.value);

        } else {

            this.gravando = false;

        }

    }

    gravar(id, dados) {

        this.avisoDetalheService.atualizar(id, dados).subscribe(
            () => {
                this.fechar(this.formDadosCadastrais);
                this.toastrService.success('Dados atualizados com sucesso!');
                this.gravando = false;
                this.carregar(this.data.id, true);
            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.gravando = false;

            }
        );

    }

    changeTabs(event: any = null) {
        this.currentParams.pageIndex = 1;
        this.queryTable(this.currentParams, this.currentSearch);
    }


    queryTableUsuarios(): void {

        this.loading = true;

        const url = this.avisoDetalheService.baseUrl + '/detalhe/' + this.data.id + '/usuarios';


        this.abstractService.listToTable(this.currentParams, this.currentSearch, url).subscribe((response) => {

            this.usuarios = [];
            this.usuarios = response?.data || response;

            this.pagination.usuarios = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loading = false;
        });

    }

    queryTableContas(): void {

        this.loading = true;

        const url = this.avisoDetalheService.baseUrl + '/detalhe/' + this.data.id + '/contas';

        this.abstractService.listToTable(this.currentParams, this.currentSearch, url).subscribe((response) => {

            this.contas = [];
            this.contas = response?.data || response;

            this.pagination.contas = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loading = false;
        });

    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;
        this.currentSearch = search;

        this.queryTableContas();
    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }


    btnResetSearch() {

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.queryTable(this.currentParams, this.currentSearch);

    }

    showConfirmRemover(id: string): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover o Aviso?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.removerConta(id)
        });

    }

    removerConta(id: string): void {

        this.loading = true;
        this.avisoDetalheService.removerConta(this.aviso.id, id).subscribe(
            () => {
                this.toastrService.success('Conta removida com sucesso do aviso!');
                this.queryTableContas();
                this.loading = false;
            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.loading = false;

            }
        );
    }

    getComboContas() {
        this.avisoDetalheService.getComboContas().subscribe((resultado) => {
            this.comboContas = [];
            this.comboContas = resultado;
        });
    }

    confirmarAddContas() {

        this.gravando = true;

        if (this.formAdicionarConta.formGroup.valid) {

            this.avisoDetalheService.addContas(this.aviso.id, this.formAdicionarConta.formGroup.value).subscribe((res) => {

                this.queryTableContas();
                this.toastService.success('Conta(s) adicionada(s) com sucesso!');
                this.gravando = false;
                this.formAdicionarConta.formGroup.reset();
                this.formAdicionarConta.modalVisible = false;

            }, (res) => {
                this.toastService.error(res.error.message);
                this.gravando = false;
            });


        } else {

            this.toastService.error('Selecione ao menos uma conta');
            this.gravando = false;

        }

    }

    preview(titulo: string, mensagem: string, tipo: string): void {

        this.avisoPreview = {
            mensagem: '<h3>' + titulo + '</h3>' + mensagem,
            tipo
        };

        this.modalPreview(true);

    }


    modalPreview(vivisle: boolean) {
        this.modalPreviewVisible = vivisle;
    }


}

