import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TabService} from '@services/tab.service';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {Form, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {JobManagerService} from './jobManager.service';
import {VisibilidadeService} from '@services/visibilidade.service';
import {PerfilService} from '@services/perfil.service';
import {Router} from '@angular/router';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {formataDatas} from '../../../shared/components-helper';
import {Pagination} from '@models/pagination.model';
import {TableQueryParamsModel} from '@models/tableQueryParams.model';
import {data} from "@reducers/reducer";

interface FormStack {
    modalVisible: boolean;
    table: 'obrigacao' | 'domicilioEletronico' | 'certidao';
    formGroup: UntypedFormGroup;
}

interface DataItem {
    conta: string;
    tipo: string;
    classificacao: string;
}

@Component({
    selector: 'app-creditos',
    templateUrl: './jobManager.component.html',
    styleUrls: ['./jobManager.component.scss']
})
export class JobManagerComponent extends AbstractListTable<any> implements OnInit, TabHandlerInterface {

    screenPermissoes = {
        cadastrar: '8979354a884d',
        perfilAcesso: '89793694884d',
        visibilidade: '897937c0884d'
    };


    currentsParams: { jobs: NzTableQueryParams } = {
        jobs: new TableQueryParamsModel()
    };
    currentsSearches: { jobs: string, contas: string } = {
        jobs: '',
        contas: ''
    };
    paginations: { jobs: Pagination } = {
        jobs: new Pagination()
    };
    loadings: {
        salvandoAlteracao: boolean;
        jobs: boolean,
        modalContas: boolean,
        modalFiltrar: boolean,
    } = {
        salvandoAlteracao: false,
        jobs: false,
        modalContas: false,
        modalFiltrar: false
    };
    dados: { obrigacoes: any[], domicilios: any[], certidoes: any[] } = {obrigacoes: [], domicilios: [], certidoes: []};
    tabNumber = 0;

    qtdsFiltros: { jobs: number } = {
        jobs: 0
    };
    status = false;

    formFiltrarObrigacoes: FormStack;
    formFiltrarDomicilios: FormStack;
    formFiltrarCertidoes: FormStack;

    formEditarObrigacoes: FormStack;
    formEditarDomiciliios: FormStack;
    formEditarCertidoes: FormStack;

    strTipoObrigacao: any = {
        A: 'Acessória',
        P: 'Principal',
        S: 'Secundária',
    };

    strOrgaoDomicilio: any = {
        federal: 'Federal',
        estadual: 'Estadual',
        muicipal: 'Municipal'
    };

    strOrgaoCeridao: any = {
        F: 'Federal',
        E: 'Estadual',
        M: 'Municipal',
        I: 'Imobiliária',
    };

    baixaEditando: { id: string, emManutencao: boolean } = {id: '', emManutencao: false};

    @ViewChild('inputBuscaContas') inputBuscaContas: ElementRef;
    erros: any[] = [];
    modalErrosVisible: boolean;
    arrayJobs: any[] = [];
    colunasServidores: { id: string, descricao: string }[] = [];
    arrayTodasContas: { id: string, nome: string, identificador: string, classificacao: string, tipo: string }[];
    visibles: { modalContas: boolean } = {modalContas: false};


    queueSelecionada: { queue: string, prefix: string };

    checkedConta = new Set<string>();
    allChecked: boolean;
    optionsContas: { label: string, value: string }[];
    private currentSortContas: { key: string, value: string };

    selectedTipo: string;
    selectedClassificacao: string;

    constructor(
        private fb: UntypedFormBuilder,
        private service: JobManagerService,
        private toastService: ToastrService,
        private visibilidadeService: VisibilidadeService,
        private perfilService: PerfilService,
        private tabService: TabService,
        private router: Router) {
        super(service, null, toastService);


        this.checkedConta = new Set();
        this.getPermissoesPerfil();

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                identificadores: [null, null],
            })
        };


    }

    ngOnInit() {


    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
    }


    getJobs(params: NzTableQueryParams, search: string = null): void {

        this.currentsParams.jobs = params;
        this.currentsSearches.jobs = search;


        const filtros = this.formFiltrar.formGroup.value;
        this.currentsParams.jobs.filter = [];

        for (let [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                const val: any = valor;
                if (typeof val.getDate === 'function') {
                    valor = formataDatas(valor, 'AAAA-MM-DD');
                }
                this.currentsParams.jobs.filter.push({key: chave, value: valor});
            }
        }

        this.countActivesFilters();

        this.loadings.jobs = true;
        this.abstractService.listToTable(params, search, this.service.baseUrls.table).subscribe((response) => {


            if (response?.servers) {
                this.colunasServidores = response?.servers;
            }

            if (response?.queues) {
                this.arrayJobs = response.queues;
            }

            this.loadings.jobs = false;
        }, (res) => {
            this.loadings.jobs = false;
        });

    }

    // async queryTable(params: NzTableQueryParams, search: string = null): void {
    //
    //     this.currentParams = params;
    //
    //     this.currentParams.filter = [];
    //
    //     const filtros = this.formFiltrar.formGroup.value;
    //
    //     for (let [chave, valor] of Object.entries(filtros)) {
    //         if (valor) {
    //             const val: any = valor;
    //             if (typeof val.getDate === 'function') {
    //                 valor = formataDatas(valor, 'AAAA-MM-DD');
    //             }
    //             this.currentParams.filter.push({key: chave, value: valor});
    //         }
    //     }
    //
    //     this.countActiveFilters();
    //
    //     this.loadings.jobs = true;
    //
    //
    //     this.service.getBaixa()
    //
    //     setTimeout(() => {
    //
    //         this.colunasServidores.push({id: 'prime1', descricao: 'Prime 1'});
    //         this.colunasServidores.push({id: 'super1', descricao: 'Super 1'});
    //
    //         this.arrayJobs.push(
    //             {label: 'dec-fila', itens: {prime1: 10, super1: 50}}
    //         );
    //
    //
    //         this.loadings.jobs = false;
    //
    //         this.pagination = new Pagination(
    //             50,
    //             1,
    //             1,
    //             50);
    //
    //         // this.pagination = new Pagination(
    //         //     response?.per_page || 50,
    //         //     response?.current_page || 1,
    //         //     response?.last_page || 1,
    //         //     response?.total || 50);
    //
    //     }, 500);
    //     // this.abstractService.listToTable(params, search).subscribe((response) => {
    //     //
    //     //     this.items = [];
    //     //     this.items = response?.data || response;
    //     //
    //     //     this.pagination = new Pagination(
    //     //         response?.per_page || 50,
    //     //         response?.current_page || 1,
    //     //         response?.last_page || 1,
    //     //         response?.total || 50);
    //     //
    //     //     this.refreshCheckedStatus();
    //     //
    //     //     this.loading = false;
    //     // }, error => {
    //     //     this.loading = false;
    //     //     this.toastrService.error(error.error.message);
    //     // });
    //
    // }


    countActivesFilters(): void {
        this.qtdsFiltros = {jobs: 0};

        for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {
            if (valor) {
                this.qtdsFiltros.jobs += 1;
            }
        }
    }

    async openContas(visible: boolean, queue: string = '', prefix: string = '') {

        this.visibles.modalContas = visible;
        this.checkedConta.clear();
        this.loadings.salvandoAlteracao = false;
        const stringA = 'b';
        const stringB = 'a';
        console.log(stringA.localeCompare(stringB));
        if (visible) {
            this.selectedTipo = '';
            this.selectedClassificacao = '';
            this.queueSelecionada = {queue, prefix};

            this.loadings.modalContas = true;

            const retorno: any = await this.service.getContasQueue(this.queueSelecionada.queue, this.queueSelecionada.prefix, this.currentSortContas).toPromise();
            if (retorno.contas) {
                this.arrayTodasContas = retorno.contas;
            }
            if (retorno.queues) {
                retorno.queues.forEach((value) => {
                    this.updateCheckedConta(value, true);
                });
            }
            this.loadings.modalContas = false;

            this.focusBuscaContas();
        }

    }

    sortAgeFn(a: any, b: any): number {
        return Number(a.conta) - Number(b.conta);
    }

    async queryTableContas(params: NzTableQueryParams) {

        if (this.currentSortContas !== params.sort[0]) {
            this.currentSortContas = params.sort[0];


            console.log('query');
        }
        // this.loadings.modalContas = true;
        // const retorno: any = await this.service.getContasQueue(this.queueSelecionada.queue, this.queueSelecionada.prefix).toPromise();
        // if (retorno.contas) {
        //     this.arrayTodasContas = retorno.contas;
        // }
        // if (retorno.queues) {
        //     retorno.queues.forEach((value) => {
        //         this.updateCheckedConta(value, true);
        //     });
        // }
        // this.loadings.modalContas = false;

    }


    onContaChecked(id: string, checked: boolean): void {
        this.updateCheckedConta(id, checked);
    }

    updateCheckedConta(id: string, checked: boolean): void {
        if (checked) {
            this.checkedConta.add(id);
        } else {
            this.checkedConta.delete(id);
        }
        this.focusBuscaContas();
    }

    onAllContasChecked(checked: boolean) {
        // tslint:disable-next-line:no-shadowed-variable
        this.arrayTodasContas.forEach((data: any) => {

            if (this.contemEmFiltros(data)) {
                this.updateCheckedConta(data.id, checked);
            }
        });
    }

    async salvar() {
        this.loadings.salvandoAlteracao = true;

        const retorno: any = await this.service.salvar(this.queueSelecionada, Array.from(this.checkedConta)).toPromise();

        this.loadings.salvandoAlteracao = false;

        if (retorno.sucesso) {
            this.toastService.success(retorno.mensagem ? retorno.mensagem : 'Dados gravados com sucesso');
            this.openContas(false);
            this.getJobs(this.currentsParams.jobs, this.currentsSearches.jobs);
        }

    }

    btnResetSearch() {

        this.currentsSearches.jobs = '';
        this.currentsParams.jobs = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.getJobs(this.currentsParams.jobs, this.currentsSearches.jobs);

    }

    async onModalFiltrar(visible: boolean) {

        this.modalFiltrar(visible);

        if (visible) {

            this.loadings.modalFiltrar = true;
            const retorno: any = await this.service.getComboContas().toPromise();
            this.optionsContas = retorno;
            this.loadings.modalFiltrar = false;
        }


    }

    confirmaFiltrar(): void {

        this.currentsParams.jobs.pageIndex = 1;

        this.getJobs(this.currentsParams.jobs, this.currentsSearches.jobs);

        this.onModalFiltrar(false);

    }

    contemEmFiltros(data: any) {
        if ((!this.currentsSearches.contas ||
                (this.currentsSearches.contas &&
                    (data.nome.search(this.currentsSearches.contas) !== -1 ||
                        data.identificador.search(this.currentsSearches.contas) !== -1))) &&
            (!this.selectedClassificacao || (this.selectedClassificacao && data.classificacao === this.selectedClassificacao)) &&
            (!this.selectedTipo || (this.selectedTipo && data.tipo === this.selectedTipo))

        ) {
            return true;
        }
        return false;

    }

    private focusBuscaContas() {
        setTimeout(() =>
                this.inputBuscaContas.nativeElement.focus()
            , 500);
    }

}
