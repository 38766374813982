import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TabService} from '@services/tab.service';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {Form, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {StatusBaixasService} from './statusBaixas.service';
import {VisibilidadeService} from '@services/visibilidade.service';
import {PerfilService} from '@services/perfil.service';
import {Router} from '@angular/router';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {formataDatas} from '../../../shared/components-helper';
import {Pagination} from '@models/pagination.model';
import {TableQueryParamsModel} from '@models/tableQueryParams.model';

interface FormStack {
    modalVisible: boolean;
    table: 'obrigacao' | 'domicilioEletronico' | 'certidao';
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-creditos',
    templateUrl: './statusBaixas.component.html',
    styleUrls: ['./statusBaixas.component.scss']
})
export class StatusBaixasComponent extends AbstractListTable<any> implements OnInit, TabHandlerInterface {

    screenPermissoes = {
        cadastrar: '8979354a884d',
        perfilAcesso: '89793694884d',
        visibilidade: '897937c0884d'
    };


    currentsParams: { obrigacoes: NzTableQueryParams, domicilios: NzTableQueryParams, certidoes: NzTableQueryParams } = {
        obrigacoes: new TableQueryParamsModel(),
        domicilios: new TableQueryParamsModel(),
        certidoes: new TableQueryParamsModel()
    };
    currentsSearches: { obrigacoes: string, domicilios: string, certidoes: string } = {
        obrigacoes: '',
        domicilios: '',
        certidoes: ''
    };
    paginations: { obrigacoes: Pagination, domicilios: Pagination, certidoes: Pagination } = {
        obrigacoes: new Pagination(200),
        domicilios: new Pagination(200),
        certidoes: new Pagination(200)
    };
    loadings: {
        obrigacoes: boolean,
        domicilios: boolean,
        certidoes: boolean,
        modalEditar: boolean,
        salvando: boolean
    } = {
        obrigacoes: false,
        domicilios: false,
        certidoes: false,
        modalEditar: false,
        salvando: false
    };
    dados: { obrigacoes: any[], domicilios: any[], certidoes: any[] } = {obrigacoes: [], domicilios: [], certidoes: []};
    tabNumber = 0;

    qtdsFiltros: { obrigacoes: number, domicilios: number, certidoes: number } = {
        obrigacoes: 0,
        domicilios: 0,
        certidoes: 0
    };
    status = false;

    formFiltrarObrigacoes: FormStack;
    formFiltrarDomicilios: FormStack;
    formFiltrarCertidoes: FormStack;

    formEditarObrigacoes: FormStack;
    formEditarDomiciliios: FormStack;
    formEditarCertidoes: FormStack;

    strTipoObrigacao: any = {
        A: 'Acessória',
        P: 'Principal',
        S: 'Secundária',
    };

    strOrgaoDomicilio: any = {
        federal: 'Federal',
        estadual: 'Estadual',
        muicipal: 'Municipal'
    };

    strOrgaoCeridao: any = {
        F: 'Federal',
        E: 'Estadual',
        M: 'Municipal',
        I: 'Imobiliária',
    };

    baixaEditando: { id: string, emManutencao: boolean } = {id: '', emManutencao: false};

    @ViewChild('inputMotivoObrigacao') inputMotivoObrigacao: ElementRef;
    @ViewChild('inputMotivoDomicilio') inputMotivoDomicilio: ElementRef;
    @ViewChild('inputMotivoCertidao') inputMotivoCertidao: ElementRef;
    erros: any[] = [];
    modalErrosVisible: boolean;

    constructor(
        private fb: UntypedFormBuilder,
        private service: StatusBaixasService,
        private toastService: ToastrService,
        private visibilidadeService: VisibilidadeService,
        private perfilService: PerfilService,
        private tabService: TabService,
        private router: Router) {
        super(service, null, toastService);

        this.getPermissoesPerfil();

        this.formFiltrarObrigacoes = {
            modalVisible: false,
            table: 'obrigacao',
            formGroup: this.fb.group({
                tipo: [null, null],
                orgao: [null, null],
                emManutencao: [null, null],
                temDownload: [null, null],
            })
        };

        this.formFiltrarDomicilios = {
            modalVisible: false,
            table: 'domicilioEletronico',
            formGroup: this.fb.group({
                orgao: [null, null],
                emManutencao: [null, null],
                ligado: [null, null],
            })
        };

        this.formFiltrarCertidoes = {
            modalVisible: false,
            table: 'certidao',
            formGroup: this.fb.group({
                orgao: [null, null],
                emManutencao: [null, null],
                ligado: [null, null],
            })
        };

        this.formEditarObrigacoes = {
            modalVisible: false,
            table: 'obrigacao',
            formGroup: this.fb.group({
                emManutencao: [null, [Validators.required]],
                manutencaoMotivo: [null, null],
                manutencaoIdTarefa: [null, null],
                ligado: [null, null]
            })
        };

        this.formEditarDomiciliios = {
            modalVisible: false,
            table: 'domicilioEletronico',
            formGroup: this.fb.group({
                emManutencao: [null, [Validators.required]],
                manutencaoMotivo: [null, null],
                manutencaoIdTarefa: [null, null],
                ligado: [null, null]
            })
        };

        this.formEditarCertidoes = {
            modalVisible: false,
            table: 'certidao',
            formGroup: this.fb.group({
                emManutencao: [null, [Validators.required]],
                manutencaoMotivo: [null, null],
                manutencaoIdTarefa: [null, null],
                ligado: [null, null]
            })
        };

    }

    ngOnInit() {

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
    }


    getObrigacoes(params: NzTableQueryParams, search: string = null): void {

        this.currentsParams.obrigacoes = params;
        this.currentsSearches.obrigacoes = search;


        const filtros = this.formFiltrarObrigacoes.formGroup.value;
        this.currentsParams.obrigacoes.filter = [];

        for (let [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                const val: any = valor;
                if (typeof val.getDate === 'function') {
                    valor = formataDatas(valor, 'AAAA-MM-DD');
                }
                this.currentsParams.obrigacoes.filter.push({key: chave, value: valor});
            }
        }

        this.countActivesFilters();

        this.loadings.obrigacoes = true;
        this.abstractService.listToTable(params, search, this.service.baseUrls.obrigacoes).subscribe((response) => {

            this.dados.obrigacoes = [];
            this.dados.obrigacoes = response?.data || response;

            this.paginations.obrigacoes = new Pagination(
                response?.per_page || 200,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 200);

            this.loadings.obrigacoes = false;
        }, (res) => {
            this.loadings.obrigacoes = false;
        });

    }

    getDomicilios(params: NzTableQueryParams, search: string = null): void {

        this.currentsParams.domicilios = params;
        this.currentsSearches.domicilios = search;


        const filtros = this.formFiltrarDomicilios.formGroup.value;
        this.currentsParams.domicilios.filter = [];

        for (let [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                const val: any = valor;
                if (typeof val.getDate === 'function') {
                    valor = formataDatas(valor, 'AAAA-MM-DD');
                }
                this.currentsParams.domicilios.filter.push({key: chave, value: valor});
            }
        }

        this.countActivesFilters();

        this.loadings.domicilios = true;
        this.abstractService.listToTable(params, search, this.service.baseUrls.domicilios).subscribe((response) => {

            this.dados.domicilios = [];
            this.dados.domicilios = response?.data || response;

            this.paginations.domicilios = new Pagination(
                response?.per_page || 200,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 200);

            this.loadings.domicilios = false;
        }, (res) => {
            this.loadings.domicilios = false;
        });

    }

    getCertidoes(params: NzTableQueryParams, search: string = null): void {

        this.currentsParams.certidoes = params;
        this.currentsSearches.certidoes = search;


        const filtros = this.formFiltrarCertidoes.formGroup.value;
        this.currentsParams.certidoes.filter = [];

        for (let [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                const val: any = valor;
                if (typeof val.getDate === 'function') {
                    valor = formataDatas(valor, 'AAAA-MM-DD');
                }
                this.currentsParams.certidoes.filter.push({key: chave, value: valor});
            }
        }

        this.countActivesFilters();

        this.loadings.certidoes = true;
        this.abstractService.listToTable(params, search, this.service.baseUrls.certidoes).subscribe((response) => {

            this.dados.certidoes = [];
            this.dados.certidoes = response?.data || response;

            this.paginations.certidoes = new Pagination(
                response?.per_page || 200,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 200);

            this.loadings.certidoes = false;
        }, (res) => {
            this.loadings.certidoes = false;
        });

    }

    countActivesFilters(): void {
        this.qtdsFiltros = {obrigacoes: 0, domicilios: 0, certidoes: 0};


        for (const [chave, valor] of Object.entries(this.formFiltrarObrigacoes.formGroup.value)) {
            if (valor) {
                this.qtdsFiltros.obrigacoes += 1;
            }
        }

        for (const [chave, valor] of Object.entries(this.formFiltrarDomicilios.formGroup.value)) {
            if (valor) {
                this.qtdsFiltros.domicilios += 1;
            }
        }

        for (const [chave, valor] of Object.entries(this.formFiltrarCertidoes.formGroup.value)) {
            if (valor) {
                this.qtdsFiltros.certidoes += 1;
            }
        }

    }

    onModalFiltrarVisible(form: FormStack, visible: boolean) {
        form.modalVisible = visible;
    }

    async onModalAlterarVisible(form: FormStack, visible: boolean, id: string = '') {


        form.modalVisible = visible;
        if (visible) {
            this.loadings.modalEditar = true;
            this.baixaEditando.id = id;
            const res = await this.service.getBaixa(form.table, id).toPromise();
            this.baixaEditando.emManutencao = res.emManutencao === 1;

            form.formGroup.get('emManutencao').setValue(res.emManutencao === 1);
            form.formGroup.get('manutencaoIdTarefa').setValue(res.manutencaoIdTarefa);
            form.formGroup.get('manutencaoMotivo').setValue(res.manutencaoMotivo);
            form.formGroup.get('ligado').setValue(res.ligado === 1);

            this.loadings.modalEditar = false;
        }else {
            const campos = ['manutencaoMotivo', 'manutencaoIdTarefa'];
            for (const campo of campos) {
                form.formGroup.get(campo).clearValidators();
                form.formGroup.get(campo).setValue(null);
            }
        }
    }

    onConfirmarFiltrar(modal: 'obrigacoes' | 'domicilios' | 'certidoes') {

        switch (modal) {
            case 'obrigacoes': {
                this.currentsParams.obrigacoes.pageIndex = 1;

                this.getObrigacoes(this.currentsParams.obrigacoes, this.currentsSearches.obrigacoes);

                this.onModalFiltrarVisible(this.formFiltrarObrigacoes, false);
                break;
            }
            case 'domicilios': {
                this.currentsParams.domicilios.pageIndex = 1;

                this.getDomicilios(this.currentsParams.domicilios, this.currentsSearches.domicilios);

                this.onModalFiltrarVisible(this.formFiltrarDomicilios, false);
                break;
            }
            case 'certidoes': {
                this.currentsParams.certidoes.pageIndex = 1;

                this.getCertidoes(this.currentsParams.certidoes, this.currentsSearches.certidoes);

                this.onModalFiltrarVisible(this.formFiltrarCertidoes, false);
                break;
            }
        }

    }

    onConfirmarEditar(form: FormStack) {

        if (form.formGroup.valid) {

            this.loadings.salvando = true;

            this.service.alterar(this.baixaEditando.id, form.formGroup.value, form.table).subscribe((res: any) => {
                this.loadings.salvando = false;
                this.toastService.success('Registro alterado com sucesso.');
                this.onModalFiltrarVisible(form, false);
                this.updateTable();


                if (res.erros && this.erros.length > 0) {
                    Object.entries(res.erros).forEach((value) => {
                        this.erros.push({conta: value[0], erro: value[1]});
                    });
                    this.onModalErrosVisible(true);
                }


            }, (res) => {
                this.loadings.salvando = false;
                this.toastService.error('Problemas ao editar registro. Contate o Administrador.');
            });
        } else {
            Object.values(form.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }

    }

    btnResetSearch() {
        switch (this.tabNumber) {
            case 0: {
                this.currentsSearches.obrigacoes = '';
                this.currentsParams.obrigacoes = {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    sort: [],
                    filter: [],
                };

                this.formFiltrarObrigacoes.formGroup.reset();
                this.getObrigacoes(this.currentsParams.obrigacoes, this.currentsSearches.obrigacoes);
                break;
            }

            case 1: {
                this.currentsSearches.domicilios = '';
                this.currentsParams.domicilios = {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    sort: [],
                    filter: [],
                };

                this.formFiltrarDomicilios.formGroup.reset();
                this.getDomicilios(this.currentsParams.domicilios, this.currentsSearches.domicilios);
                break;
            }

            case 2: {
                this.currentsSearches.certidoes = '';
                this.currentsParams.certidoes = {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    sort: [],
                    filter: [],
                };

                this.formFiltrarCertidoes.formGroup.reset();
                this.getCertidoes(this.currentsParams.certidoes, this.currentsSearches.certidoes);
                break;
            }
        }

    }

    updateTable() {

        switch (this.tabNumber) {
            case 0: {
                this.getObrigacoes(this.currentsParams.obrigacoes, this.currentsSearches.obrigacoes);
                break;
            }
            case 1: {
                this.getDomicilios(this.currentsParams.domicilios, this.currentsSearches.domicilios);
                break;
            }
            case 2: {
                this.getCertidoes(this.currentsParams.certidoes, this.currentsSearches.certidoes);
                break;
            }
        }

    }

    async changeSwitchManutencao(emManutencao: boolean, form: FormStack) {

        if (emManutencao) {
            const campos = ['manutencaoMotivo', 'manutencaoIdTarefa'];
            for (const campo of campos) {
                form.formGroup.get(campo).setValidators([Validators.required]);
                form.formGroup.get(campo).markAsDirty();
                form.formGroup.get(campo).updateValueAndValidity();
            }
            this.focusMotivo(form.table);
        } else {

            const campos = ['manutencaoMotivo', 'manutencaoIdTarefa'];
            for (const campo of campos) {
                form.formGroup.get(campo).clearValidators();
                form.formGroup.get(campo).setValue(null);
            }
        }


    }

    async changeSwitchLigado(ligado: boolean, form: FormStack) {

        if (!ligado) {
            // const campos = ['manutencaoMotivo', 'manutencaoIdTarefa'];
            // for (const campo of campos) {
            //     form.formGroup.get(campo).setValidators([Validators.required]);
            //     form.formGroup.get(campo).markAsDirty();
            //     form.formGroup.get(campo).updateValueAndValidity();
            // }
            // this.focusMotivo(form.table);
        } else {

            // const campos = ['manutencaoMotivo', 'manutencaoIdTarefa'];
            // for (const campo of campos) {
            //     form.formGroup.get(campo).clearValidators();
            //     form.formGroup.get(campo).setValue(null);
            // }
        }


    }

    focusMotivo(table: 'obrigacao' | 'domicilioEletronico' | 'certidao') {

        setTimeout(() => {
            switch (table) {
                case 'obrigacao':
                    this.inputMotivoObrigacao.nativeElement.focus();
                    break;
                case 'domicilioEletronico':
                    this.inputMotivoDomicilio.nativeElement.focus();
                    break;
                case 'certidao':
                    this.inputMotivoCertidao.nativeElement.focus();
                    break;
            }
        }, 300);
    }

    onModalErrosVisible(visible: boolean) {
        this.modalErrosVisible = visible;
        if (!visible) {
            this.erros = [];
        }
    }
}
