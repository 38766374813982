import {NzTableFilterValue, NzTableSortOrder} from 'ng-zorro-antd/table/src/table.types';

export class TableQueryParamsModel {
  pageIndex: number;
  pageSize: number;
  sort: Array<{
    key: string;
    value: NzTableSortOrder;
  }>;
  filter: Array<{
    key: string;
    value: NzTableFilterValue;
  }>;

  constructor() {
    this.pageIndex = 1;
    this.pageSize = 1;
    this.sort = [];
    this.filter = [];
  }
}
