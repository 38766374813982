<div class="header">
    <div class="header-logo">
        <img alt="logo" src="../../assets/logo-icone.png"/>
    </div>
    <app-megamenu class="header-menu"></app-megamenu>
    <Tabset class="header-tabs"></Tabset>
    <div class="header-user">
        <user-menu></user-menu>
    </div>
</div>
