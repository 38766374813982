<nz-page-header class="site-page-header" nzTitle="Administração" nzSubtitle="Gerenciamento de Usuários">
</nz-page-header>
<nz-content>
    <nz-card [nzExtra]="cardExtra" nzTitle="Usuários"
             [nzLoading]="loading" [nzBodyStyle]="{'padding': loading ? '15px' : '0px'}"
             [ngClass]="expandedCard ? 'card-fullscreen' : ''">
        <ng-template #cardExtra>
            <div class="nav-item d-flex">

                <div class="nav-item">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalCadastrar(true);"
                        *ngIf="hasPermissao(screenPermissoes.cadastrar, currentPermissoes)">
                        Cadastrar
                    </button>
                </div>

                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button class="centralized-icon" [nzDropdownMenu]="menuAcoes"
                        [nzType]="'default'" nz-button nz-dropdown style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-submenu nzTitle="Manutenção Coletiva">
                            <ul>
                                <li nz-menu-item nzDisabled>Ações</li>
                                <li nz-menu-divider></li>
                                <li (click)="onAlterarPerfilDeAcesso()" [nzDisabled]="checkedItems?.size === 0"
                                    *ngIf="hasPermissao(screenPermissoes.perfilAcesso, currentPermissoes)"
                                    nz-menu-item>
                                    Alterar
                                    Perfil de Acesso
                                </li>
                                <li (click)="onAlterarVisibilidadeColetiva()" [nzDisabled]="checkedItems?.size === 0"
                                    *ngIf="hasPermissao(screenPermissoes.visibilidade, currentPermissoes)"
                                    nz-menu-item>
                                    Alterar Visibilidade
                                </li>
                                <li (click)="modalStatus(true)"
                                    [nzDisabled]="checkedItems?.size === 0" nz-menu-item>
                                    Alterar status Ativo/Inativo
                                </li>
                            </ul>
                        </li>
                        <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar Planilha</li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!expandedCard ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>

        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzLoading]="loading"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  [nzShowTotal]="rangeTemplate"
                  (nzQueryParams)="queryTable($event)"
                  class="fonte-doze table-small">
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked"
                    id="check-all">
                </th>
                <th [nzShowSort]="false"></th>
                <th nzColumnKey="nome" [nzSortFn]="true" nzLeft>Nome</th>
                <th nzColumnKey="login" [nzSortFn]="true">Login</th>
                <th nzColumnKey="email" [nzSortFn]="true">Email</th>
                <th nzColumnKey="admin" [nzSortFn]="true">Admin Sistema</th>
                <th nzColumnKey="perfil_id" [nzSortFn]="true">Perfil de Acesso</th>
                <th *ngIf="true" nzColumnKey="">Visibilidades</th>
                <th nzColumnKey="ultimoLoginData" [nzSortFn]="true" [nzSortOrder]="'descend'">Último Login</th>
                <th nzColumnKey="created_at" [nzSortFn]="true">Cadastro Data</th>
                <th *ngIf="false" nzColumnKey="ativo" [nzSortFn]="true">Ativo</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let usuario of basicTable.data">
                <td nzLeft (nzCheckedChange)="onItemChecked(usuario.id, $event)"
                    [nzChecked]="checkedItems.has(usuario.id)"
                    [nzDisabled]="usuario.disabled">
                </td>
                <td nzLeft  style="width: 30px;">
                    <img nz-image class="imagem-avatar" [nzSrc]="'data:image/png;base64,' + usuario?.avatar"
                         [nzFallback]="fallbackAvatar"
                         alt="{{usuario?.nome}}" nzDisablePreview>
                </td>
                <td nzLeft>
                    <a nz-button nzType="link"
                       (click)="openTab('/administracao/usuario/detalhe', usuario.id, {id: usuario.id, nome: usuario.nome})">{{usuario?.nome}}</a>
                    <nz-tag [nzColor]="'#f50'" *ngIf="!usuario?.ativo" style="margin-left: 10px; font-size: 10px">
                        Inativo
                    </nz-tag>
                </td>
                <td nzLeft>{{usuario?.login}}</td>
                <td>{{usuario?.email}}</td>
                <td nzAlign="center">{{usuario?.admin ? 'Sim' : '-'}}</td>
                <td>{{usuario?.perfil_descricao}}</td>
                <td *ngIf="true">
                    <span *ngIf="usuario.visibilidades.length === 0" nz-typography nzDisabled><em>nenhuma</em></span>
                    <nz-tag *ngFor="let visibilidade of usuario?.visibilidades"
                        (click)="onVerVisibilidade(visibilidade?.id, visibilidade?.descricao)"
                            style="cursor: pointer;"
                    >{{visibilidade?.descricao}}</nz-tag>
                </td>
                <td nzAlign="center">{{usuario?.ultimoLoginData | date:'dd/MM/YY - HH:mm:ss'}}</td>
                <td nzAlign="center">{{usuario?.created_at | date:'dd/MM/YY - HH:mm:ss'}}</td>
                <td *ngIf="false" nzAlign="center">{{getStatusLabel(usuario?.ativo)}}</td>
            </tr>
            </tbody>
            <ng-template #rangeTemplate let-range="range" let-total>
                {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
            </ng-template>
        </nz-table>
    </nz-card>

</nz-content>


<nz-modal *ngIf="comboPerfis?.length" [(nzVisible)]="displayProfileModal"
          [nzAutofocus]="null" [nzFooter]="modalFooter" nzTitle="Alterar Perfil de Acesso">
    <ng-template nzModalContent>
        <nz-select [(ngModel)]="selectedProfile"
                   [nzPlaceHolder]="'Selecione'" style="width: 100%;">
            <nz-option *ngFor="let option of comboPerfis" [nzLabel]="option.label"
                       [nzValue]="option.value"></nz-option>
        </nz-select>
    </ng-template>
    <ng-template #modalFooter>
        <button (click)="saveBulkToProfiles()" nz-button nzType="primary">Ok</button>
        <button (click)="handleCancel()" nz-button nzType="default">Cancelar</button>
    </ng-template>
</nz-modal>

<nz-modal *ngIf="visibilidadeOptions?.length" [(nzVisible)]="displayVisibilidadeModal"
          [nzAutofocus]="null" [nzFooter]="modalFooter" nzTitle="Alterar Visibilidades" (nzOnCancel)="handleCancel()">
    <ng-template nzModalContent>
        <nz-select [(ngModel)]="selectedVisibilidades"
                   [nzPlaceHolder]="'Selecione as visibilidades'"
                   nzMode="multiple">
            <nz-option *ngFor="let option of visibilidadeOptions" [nzLabel]="option.label"
                       [nzValue]="option.value"></nz-option>
        </nz-select>
        <label nz-checkbox [(ngModel)]="limparVisibilidades">Limpar as já cadastradas anteriormente</label>
    </ng-template>

    <ng-template #modalFooter>
        <button (click)="addNewVisibilidade()" class="new-markup-bnt" nz-button nzType="link">
            <em nz-icon nzType="plus-circle"></em>Nova visibilidade
        </button>
        <button (click)="vincularVisibilidades()" nz-button nzType="primary">Ok</button>
        <button (click)="handleCancel()" nz-button nzType="default">Cancelar</button>
    </ng-template>
</nz-modal>

<nz-modal (nzOnCancel)="closeNewVisibilidade()" (nzOnOk)="adicionarNovaVisibilidade()"
          [(nzVisible)]="newVisibilidadeModal"
          [nzAutofocus]="null"
          nzTitle="Nova Visibilidade">
    <ng-template nzModalContent>
        <nz-form-item>
            <label for="novaVisibilidade"></label>
            <input [(ngModel)]="newVisibilidade" id="novaVisibilidade" nz-input placeholder="descrição" type="text">
        </nz-form-item>
    </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="formCadastrar.modalVisible" [nzTitle]="'Cadastrar Novo Usuário'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)"
          [nzWidth]="720">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formCadastrar.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzFor="primeiroNome" nzRequired>Nome/Sobrenome</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <div nz-row [nzGutter]="8">
                        <div nz-col [nzSpan]="12">
                            <input nz-input type="text" formControlName="primeiroNome" placeholder="Nome"/>
                        </div>
                        <div nz-col [nzSpan]="12">
                            <input nz-input type="text" formControlName="sobreNome" placeholder="Sobrenome"/>
                        </div>
                    </div>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Email</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input formControlName="email" placeholder="informe o seu Email" type="email" size="60"
                           maxlength="60"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired
                               nzTooltipTitle="O CPF deverá ser utilizado como login do usuário">Login
                </nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <input nz-input type="text" formControlName="login" placeholder="Login do usuário"
                           autocomplete="off" size="60" maxlength="20"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Senha</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-input-group [nzSuffix]="showPassword">
                        <input nz-input
                           [type]="passwordVisible ? 'text' : 'password'"
                           formControlName="senha"
                           placeholder="nova senha"
                           size="60"
                           nz-popover
                           nzPopoverTrigger="focus"
                           nzPopoverPlacement="right"
                           [nzPopoverTitle]="validadePasswordTitle"
                           [nzPopoverContent]="validadePasswordContent"
                           (ngModelChange)="verificaSenha($event, true)"
                           maxlength="60"/>
                    </nz-input-group>
                </nz-form-control>
                <ng-template #showPassword>
                    <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                          (click)="passwordVisible = !passwordVisible" style="cursor: pointer;"></span>
                </ng-template>
                <ng-template #validadePasswordTitle>
                    <h5 nz-typography style="margin: 0;">
                        <i class="fa-solid fa-key" style="margin-right: 3px;"></i> Segurança da Senha
                    </h5>
                </ng-template>
                <ng-template #validadePasswordContent>
                    <div [ngStyle]="passwordValidate.length && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.length"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.length" style="margin-right: 3px;"></span>
                        <span>A senha deve conter entre 8 e 30 caracteres.</span>
                    </div>
                    <div [ngStyle]="passwordValidate.lowercase && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.lowercase"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.lowercase"
                              style="margin-right: 3px;"></span>
                        <span>A senha deve conter ao menos uma letra minúscula.</span>
                    </div>
                    <div [ngStyle]="passwordValidate.uppercase && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.uppercase"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.uppercase"
                              style="margin-right: 3px;"></span>
                        <span>A senha deve conter ao menos uma letra maiúscula.</span>
                    </div>
                    <div [ngStyle]="passwordValidate.number && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.number"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.number" style="margin-right: 3px;"></span>
                        <span>A senha deve conter ao menos um número.</span>
                    </div>
                </ng-template>
            </nz-form-item>
            <ng-template #showPasswordConfirm>
                    <span nz-icon [nzType]="passwordConfirmVisible ? 'eye-invisible' : 'eye'"
                          (click)="passwordConfirmVisible = !passwordConfirmVisible" style="cursor: pointer;"></span>
            </ng-template>
            <nz-form-item [ngClass]="[!this.passwordValidate.confirm ? 'ant-form-item-with-help' : '']">
                <nz-form-label [nzSpan]="5" nzRequired>Confirma</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-input-group [nzSuffix]="showPasswordConfirm">
                        <input nz-input
                               [type]="passwordConfirmVisible ? 'text' : 'password'"
                               formControlName="confirmaSenha"
                               placeholder="confirme a senha"
                                 size="60" maxlength="60"
                               (ngModelChange)="verificaConfirmSenha($event)"/>
                    </nz-input-group>
                    <div *ngIf="!this.passwordValidate.confirm"
                         class="ant-form-item-explain ant-form-item-explain-error ng-star-inserted">
                        <div class="ng-trigger ng-trigger-helpMotion">As senhas não coincidem</div>
                    </div>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Perfil de Acesso</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select formControlName="perfil_id" [nzPlaceHolder]="'Selecione'">
                        <nz-option *ngFor="let option of comboPerfis" [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" >Visibilidade</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select formControlName="visibilidade_id"
                               nzMode="multiple"
                               [nzPlaceHolder]="'Selecione'"
                               nzShowSearch>
                        <nz-option *ngFor="let option of comboVisibilidades" [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaCadastro()">Confirmar</button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Nome</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.nome !== '' && formFiltrar.formGroup.value.nome !== null">
                    <input nz-input type="text" formControlName="nome" placeholder="Nome"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Login</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.login !== '' && formFiltrar.formGroup.value.login !== null">
                    <input nz-input type="text" formControlName="login" placeholder="Nome"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Email</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.email !== '' && formFiltrar.formGroup.value.email !== null">
                    <input nz-input type="text" formControlName="email" placeholder="Nome"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Admin Sistema?</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.adminSistema">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="adminSistema">
                        <nz-option
                                [nzLabel]="'Sim'" [nzValue]="true">
                        </nz-option>
                        <nz-option
                                [nzLabel]="'Não'" [nzValue]="false">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Inativo</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.inativo">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="inativo">
                        <nz-option
                                [nzLabel]="'Mostrar somente inativos'" [nzValue]="'somente'">
                        </nz-option>
                        <nz-option
                                [nzLabel]="'Mostrar também inativos'" [nzValue]="'tambem'">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Perfil</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.perfil">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="perfil">
                        <nz-option *ngFor="let opt of comboPerfis"
                                   [nzLabel]="opt.label" [nzValue]="opt.value">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Visibilidade</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.visibilidade">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="visibilidade">
                        <nz-option nzLabel="Sem visibilidade" nzValue="semVisibilidade"></nz-option>
                        <nz-option *ngFor="let opt of comboVisibilidades"
                                   [nzLabel]="opt.label" [nzValue]="opt.value">
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Cadastrado em</nz-form-label>
                <div nz-col [nzSpan]="14">
                    <div nz-row>
                        <nz-form-control nz-col [nzSpan]="12"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrar.formGroup.value.cadastroDataDe">
                            <nz-date-picker formControlName="cadastroDataDe" nzFormat="dd/MM/yyyy"
                                            nzPlaceHolder="De:"
                            ></nz-date-picker>
                        </nz-form-control>
                        <nz-form-control nz-col [nzSpan]="12" class="text-right"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrar.formGroup.value.cadastroDataAte">
                            <nz-date-picker formControlName="cadastroDataAte" nzFormat="dd/MM/yyyy"
                                            nzPlaceHolder="Até:"></nz-date-picker>
                        </nz-form-control>
                    </div>
                </div>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Exportar'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [nzAutoTips]="autoTips" [formGroup]="formExportar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.exportar" (click)="confirmaExportar()">Exportar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formStatus.modalVisible"
          [nzTitle]="'Alterar status Ativo/Inativo'" [nzClosable]="true"
          (nzOnCancel)="modalStatus(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formStatus.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Usuário Ativo?</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="ativo" style="width: 100%;">
                        <nz-option [nzLabel]="'Sim'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Não'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalStatus(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaAlterarStatus()" [nzLoading]="loadings.alterandoStatus">
            Alterar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="modalContasVisibilidadeVisible"
          [nzTitle]="'Contas da visibilidade ' + nomeVisibilidadeSelecionada" [nzClosable]="true"
          (nzOnCancel)="modalContasVisibilidade(false)"
          [nzWidth]="848">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.visibilidades"></nz-skeleton>
        <nz-table #basicTable
                  [nzData]="arrayContasVisibilidade"
                  [nzShowPagination]="false"
                  [nzFrontPagination]="false"
                  class="table-small"
                  [nzScroll]="{ x: 'auto', y: '340px' }"
                  style="width: 100%; font-size: 10px;"
                  *ngIf="!loadings.visibilidades"
        >
            <thead>
            <tr>
                <th>Identidicador</th>
                <th>Nome</th>
                <th>Tipo</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of basicTable.data">
                <td>{{ data?.identificador }}</td>
                <td style="white-space: nowrap; width: 50%;">{{ data?.nome }}</td>
                <td>{{ data?.tipo }}</td>
            </tr>
            </tbody>
        </nz-table>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalContasVisibilidade(false);">Fechar</button>
    </div>
</nz-modal>
