import BulkChanges from '@models/entity.model';
import {Utils} from '../utils';

export class UsuarioLogado implements BulkChanges {
    id: string;
    nome: string;
    primeiroNome: string;
    sobreNome: string;
    email: string;
    token: string;
    telefone: string;
    cpf: string;
    nascimentoData: string;
    departamento: string;
    login: string;
    loginMFA: string;
    senha: string;
    senhaValidadeData: string;
    senhaAlteracaoData: string;
    // tslint:disable-next-line:variable-name
    perfil_id: string;
    // tslint:disable-next-line:variable-name
    perfil_descricao: string;
    admin: number;
    ativo: number;
    ultimoLoginData: string;
    penultimoLoginData: string;
    // tslint:disable-next-line:variable-name
    updated_at: string;
    // tslint:disable-next-line:variable-name
    created_at: string;
    visibilidades: [];
    srcAvatar: string;
    tempoSessao: number;

    constructor(obj?: any) {
        if (obj) {
            for (const prop of Object.keys(obj)) {
                this[prop] = obj[prop];
            }

            if (this.nome) {

                const partNames = this.nome.split(' ');

                this.primeiroNome = partNames.shift();
                this.sobreNome = partNames.length > 0 ? partNames.join(' ') : null;

            }

        }

    }

    convertToSave() {

        const properties = [];
        const usuarioLogado = new UsuarioLogado();

        for (const prop of properties) {

            usuarioLogado[prop] = this[prop];

        }

        if (Utils.isEmpty(usuarioLogado.id)) {

            delete usuarioLogado.id;

        }

        return usuarioLogado;

    }

    toBulk(id: string | string[], itens: string[]) {

        return {
            id,
            itens
        };
    }

    bulkChange(propertyChanged: string[], checkedItems: string[]) {
        return {
            usuarios: checkedItems,
            visibilidades: propertyChanged
        };
    }
}
