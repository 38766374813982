import BulkChanges from '@models/entity.model';
import {CrudModel} from '@models/crud.model';

export class Perfil extends CrudModel implements BulkChanges {

    id: string;
    descricao: string;
    quantidadeMembros: string;
    permissoes: string[];
    // tslint:disable-next-line:variable-name
    updated_at: string;
    // tslint:disable-next-line:variable-name
    created_at: string;

    public constructor(obj?: any) {

        super(CrudModel);

        if (obj) {
            for (const prop of Object.keys(obj)) {
                this[prop] = obj[prop];
            }
        }
    }

    convertToSave() {
        const properties = ['id', 'descricao'];
        const perfil = new Perfil();
        for (const prop of properties) {
            perfil[prop] = this[prop];
        }
        return perfil;
    }

    toBulk(id: string, itens: string[]) {

        return {
            id,
            itens
        };

    }

    bulkChange(propertyChanged: string[], checkedItems: string[]) {
    }
}
