import {Component, OnInit} from '@angular/core';
import {DeployInfoData} from './deploy-info.data';

@Component({
    selector: 'deploy-info',
    templateUrl: './deploy-info.html'
})

export class DeployInfo implements OnInit {

    deployInfoData;

    constructor() {

        this.deployInfoData = DeployInfoData;
    }

    ngOnInit() {
    }

}

