import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {map} from 'rxjs/operators';
import {Token} from '../../shared/models/token.model';
import {Resolve, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import jwtDecode from 'jwt-decode';
import {TabService} from '@services/tab.service';

@Injectable({providedIn: 'root'})
export class AuthenticationService implements Resolve<any> {
    public currentTokenSubject: BehaviorSubject<Token>;
    public currentToken: Observable<Token>;

    constructor(
        private http: HttpClient,
        private router: Router,
        private toastr: ToastrService,
        private tabService: TabService) {
        const mastertaxStorage = JSON.parse(localStorage.getItem('console-mastertax'));
        this.currentTokenSubject = new BehaviorSubject<Token>(mastertaxStorage ? mastertaxStorage : null);
        this.currentToken = this.currentTokenSubject.asObservable();


    }

    public get currentTokenValue(): Token {
        return this.currentTokenSubject.value;
    }

    login(login: string, senha: string, codigoMFA: string = null) {

        const authObj = {
            login,
            senha,
            codigoMFA
        };

        return this.http.post<any>(`${environment.apiUrl}/auth/login`, authObj)
            .pipe(map(token => {
                // this.store.dispatch(Add(jwtDecode(token.access_token), 'user'));
                if (token.senhaExpirada) {
                    return {
                        senhaExpirada: true,
                        token: token.token ? token.token : null,
                        usuario_nome: token.usuario_nome ? token.usuario_nome : null,
                        usuario_email: token.usuario_email ? token.usuario_email : null,
                        usuario_login: token.usuario_login ? token.usuario_login : null,
                    };
                }

                if (token.autenticacaoDoisFatores) {
                    return {autenticacaoDoisFatores: true, email: token.email ? token.email : null};
                }

                if (token.senhaInvalida) {
                    return {
                        senhaInvalida: true,
                        tentativasRestantes: token.tentativasRestantes ? token.tentativasRestantes : null,
                        loginBloqueado: token.loginBloqueado ? token.loginBloqueado : false,
                    };
                }

                localStorage.removeItem('console-mastertax');
                localStorage.setItem('console-mastertax', JSON.stringify(token));
                this.currentTokenSubject.next(token);

                return token;
            }));
    }

    logout(message?: string, redirect = true, click = false) {
        if (this.currentTokenSubject.value) {
            this.http.post<any>(`${environment.apiUrl}/auth/logout`, {})
                .subscribe(obj => {
                    this.closeSession(message, click);
                    if (redirect) {
                        this.router.navigate(['/login']);
                    }
                }, (error) => {
                    if (error === 'Unauthorized') {
                        if (this.currentTokenSubject.value) {
                            this.closeSession(message, click);
                            this.router.navigate(['/login']);
                        }
                    }
                });
        }
    }

    closeSession(message, click = false) {
        localStorage.removeItem('console-mastertax');
        this.currentTokenSubject.next(null);
        this.tabService.closeAll();
        this.toastr.success(click ? 'Deslogado com sucesso!' : 'Sessão expirada.', message);
    }

    isLogged = () => {
        const mastertaxStorage = JSON.parse(localStorage.getItem('console-mastertax'));

        if (mastertaxStorage && mastertaxStorage.expires_in != null) {
            const agora = this.formatDatime(new Date());
            if (mastertaxStorage.expires_in <= agora) {
                this.closeSession('');
                return false;
            } else {
                const now = new Date(new Date().setHours(new Date().getHours() + 2));
                mastertaxStorage.expires_in = this.formatDatime(now);
                localStorage.setItem('console-mastertax', JSON.stringify(mastertaxStorage));
                return true;
            }
        }

        return mastertaxStorage && mastertaxStorage.token != null;
    }

    decodePayloadJWT() {
        try {
            return this.currentTokenValue.user;
        } catch (error) {
            return null;
        }
    }

    resetPassword(email: string) {
        return this.http.post(`${environment.apiUrl}/auth/reset-password`, {email});
    }

    changePassword(email: string, passwordToken: string, password: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/auth/change-password`, {email, passwordToken, password});
    }

    resolve() {
        this.logout();
        this.router.navigate(['change-password']);
    }

    formatDatime(date) {

        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();

        const hour = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);

        return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;
    }

    reenviarCodigoMFA(login: string) {

        return this.http.post(`${environment.apiUrl}/auth/reenviar-codigo-two-factor`, {login});
    }
}
