import {Component, Input, OnInit, ViewChildren, ViewEncapsulation} from '@angular/core';
import {NzFormatEmitEvent, NzTreeNode} from 'ng-zorro-antd/tree';
import {MegamenuService} from './megamenu.service';
import {TabService} from '@services/tab.service';
import {UrlTypeEnum} from '../../../shared/enums/url-type.enum';
import {ExternalPageComponent} from '../../../modules/external-page/pages/external-page.component';
import {Tab} from '@models/tab.model';
import {Subscription} from 'rxjs';
import {Favorito} from '@components/megamenu/favorito.model';
import {FavoritosService} from '@components/megamenu/favoritos.service';
import {Recente} from '@components/megamenu/recente.model';
import {RecentesService} from '@components/megamenu/recentes.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {DataService} from '@services/data.service';

@Component({
    selector: 'app-megamenu',
    templateUrl: './megamenu.component.html',
    styleUrls: ['./megamenu.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class MegaMenuComponent implements OnInit {

    @ViewChildren('treeMegaMenu') treesMegaMenu;
    isVisibleTop = false;
    @Input() expandAll = false;
    searchValue = null;

    expand = false;

    menus = [];
    favoritos: Favorito[];
    recentes: Recente[];

    components = {
        ExternalPageComponent
    };
    exibirBeta = true;
    carregando: boolean;
    userAdmin = false;
    currentUser: Subscription;

    constructor(
        private dataService: DataService,
        private megamenuService: MegamenuService,
        private favoritosService: FavoritosService,
        private recentesService: RecentesService,
        private tabService: TabService
    ) {


    }

    ngOnInit() {

        this.currentUser = this.dataService.currentUser.subscribe((data) => {
            if (data) {

                this.userAdmin = data.admin === 1;

            }

        });

        this.getMenuItens();
        this.getRecentes();

    }

    validaPesquisa() {
        this.searchValue = this.searchValue?.length === 0 ? null : this.searchValue;
    }


    showMegaMenu(): void {

        this.isVisibleTop = true;

        setTimeout(() => {
            document.getElementById('autoFocus').focus();
        }, 500);

    }

    closeMegaMenu(): void {

        this.searchValue = null;
        this.isVisibleTop = false;

    }

    getMenuItens(): void {

        this.carregando = true;

        this.megamenuService.getTree().subscribe((data: any[]) => {

            this.menus = this.megamenuService.transformMegamenuItem(data, 1, false, this.exibirBeta);
            this.favoritos = this.favoritosService.transformItem(data, [], this.exibirBeta);

            this.carregando = false;
        });

    }

    handleOk(): void {

        this.isVisibleTop = false;

    }

    handleCancel(): void {

        this.isVisibleTop = false;

    }

    nzEvent(event: NzFormatEmitEvent): void {

        const origin = event.node.origin;

        if (origin.url) {

            this.clickItem(origin);

        } else {

            const parentNode = event.node;
            const expanded = !parentNode.isExpanded;
            this.expandChild(parentNode, expanded);

        }

    }

    clickItem(origin): void {

        if (origin.url) {

            this.updateMenuRecentes(origin);
            let urlType: UrlTypeEnum;
            let componentName = 'ExternalPageComponent';

            switch (origin.urlType) {
                case UrlTypeEnum.embedded:
                    urlType = UrlTypeEnum.embedded;
                    break;
                case UrlTypeEnum.external:
                    urlType = UrlTypeEnum.external;
                    break;
                default:
                    urlType = UrlTypeEnum.nativo;

                    const component = findComponentByUrl(origin.url);
                    origin.url = buildUrl(component, '');
                    componentName = component.name;

                    break;
            }

            const data = {
                link: origin.url,
                extra: origin.extra
            };


            this.openTab(componentName, origin.description ? origin.description : origin.title, origin.url, data, urlType);
            this.closeMegaMenu();

        }
    }

    openTab(componentName: string, title: string, url: string, data?: {}, urlType?: UrlTypeEnum) {

        const newTab = new Tab(componentName, title, url, urlType, data);
        this.tabService.addTab(newTab);

    }

    expandChild(node: NzTreeNode, expanded: boolean) {

        node.isExpanded = expanded;

        if (node.children) {

            node.children.forEach(child => this.expandChild(child, expanded));

        }

    }

    expandAllHandler(expanded = true) {

        this.expand = expanded;

        this.searchValue = null;
        const expandTreeNodes = (node: NzTreeNode) => {

            node.isExpanded = expanded;

            if (node.children) {

                node.children.forEach(expandTreeNodes);

            }
        };

        this.treesMegaMenu.forEach(treeMegaMenu => {

            treeMegaMenu.handleSearchValue('');
            treeMegaMenu.getTreeNodes().forEach(expandTreeNodes);

        });

    }

    updateMenuRecentes(menu) {

        this.recentesService.toggle(menu.key, true).subscribe(null, null, () => {

            this.getRecentes();

        });
    }

    getRecentes() {

        this.recentesService.list().subscribe(itens => {

            const recentes = [];

            for (const item of itens) {

                const menuItem = this.getMenusById(item.menu_id);

                if (menuItem) {

                    const recenteItem = new Recente();
                    recenteItem.id = menuItem.key;
                    recenteItem.descricao = menuItem.title;
                    recenteItem.descricaoEstendida = menuItem.description;

                    recentes.push(recenteItem);

                }

            }

            this.recentes = recentes;

        });

    }

    clickRecente(id: string) {

        const found = this.getMenusById(id);

        if (found) {
            this.clickItem(found.origin);
        }

    }

    setMenuItemRececente(event, id, toggle = true) {

        event.stopPropagation();

        const menuItem = this.getMenusById(id);

        if (menuItem && toggle) {

            const item = new Recente();
            item.id = menuItem.key;
            item.descricao = menuItem.title;
            item.descricaoEstendida = menuItem.description;

            this.recentes.push(item);

        } else {

            this.recentes = this.recentes.filter(recente => recente.id !== id);

        }

        this.recentesService.toggle(menuItem.key, toggle).subscribe(null, null, () => {

            this.getRecentes();

        });

    }

    clickFavorito(id: string) {

        const found = this.getTreeItemById(id);

        if (found) {
            this.clickItem(found.origin);
        }

    }

    setMenuItemFavorito(event, id, toggle = true) {

        event.stopPropagation();

        const menuItem = this.getTreeItemById(id);

        if (menuItem) {

            menuItem.origin.favorito = toggle;

        }

        if (menuItem && toggle) {

            const item = new Favorito();
            item.id = menuItem.key;
            item.descricao = menuItem.origin.titleAlt;
            item.descricaoEstendida = menuItem.origin.description;

            this.favoritos.push(item);

        } else {

            this.favoritos = this.favoritos.filter(favorito => favorito.id !== id);

        }

        this.favoritosService.toggle(menuItem.key, toggle).subscribe(() => {

        });

    }

    carregaMenu() {

        this.getMenuItens();

    }

    getTreeItemById(id) {

        const expandTreeNodes = (node: NzTreeNode) => {

            if (node.key === id) {
                return node;
            }

            if (node.children) {

                for (const item of node.children) {

                    const found = expandTreeNodes(item);

                    if (found) {
                        return found;
                    }

                }
            }
        };

        for (const tree of this.treesMegaMenu) {

            for (const item of tree.getTreeNodes()) {

                const found = expandTreeNodes(item);

                if (found) {
                    return found;
                }

            }

        }

    }

    getMenusById(id) {

        const expandTreeNodes = (node) => {

            if (node.key === id) {
                return node;
            }

            if (node.children) {

                for (const item of node.children) {

                    const found2 = expandTreeNodes(item);

                    if (found2) {
                        return found2;
                    }

                }
            }
        };

        for (const menus of this.menus) {

            const found = expandTreeNodes(menus);

            if (found) {
                return found;
            }

        }

    }

}
