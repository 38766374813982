import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {TabService} from '@services/tab.service';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {Tab} from '@models/tab.model';
import {buildUrl, getComponentConfigByName} from '../../../shared/components-helper';

@Component({
    selector: 'Navbar-List',
    templateUrl: './navbar-list.component.html',
    styleUrls: ['./navbar-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarListComponent implements OnInit, TabHandlerInterface {
    generalFilter = '';
    @Input() registerLink: string;
    @Input() registerComponent: string;
    @Input() tabTitle: string;
    @Output() generalFilterHandler = new EventEmitter<string>();
    @Output() resetForm = new EventEmitter<boolean>();
    @Output() registerClick = new EventEmitter<boolean>();

    constructor(private router: Router, private tabService: TabService) {
    }

    ngOnInit(): void {
    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = getComponentConfigByName(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    reset() {
        this.generalFilter = '';
        this.resetForm.emit(true);
    }

    search() {
        this.generalFilterHandler.emit(this.generalFilter);
    }

    register(event) {

        if (this.registerClick) {

            this.registerClick.emit(true);

        } else {

            this.openTab(this.registerComponent);

        }
    }
}
