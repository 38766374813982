<nz-page-header class="site-page-header" nzTitle="Créditos" nzSubtitle="Captcha">
</nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="16">
        <div nz-col nzXs="24" nzMd="12" nzLg="6">
            <nz-card [nzTitle]="avatarEmpresas" style="height: 95%;" class="card-admin">
                <p>
                    Valor disponível de créditos para Captcha
                    <br/>
                    <br/>
                </p>
                <h5 nz-typography>{{this.creditos[0]?.balance | currency : 'BRL'}}</h5>
            </nz-card>
        </div>
    </div>
    <ng-template #avatarEmpresas>
        <i class="fas fa-dollar-sign"></i> <span style="font-weight: 400; padding-left: 5px">Créditos Captcha</span>
    </ng-template>
</nz-content>
