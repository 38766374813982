import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {AbstractService} from '@services/abstract.service';
import {Recente} from '@components/megamenu/recente.model';

@Injectable({providedIn: 'root'})

export class RecentesService extends AbstractService<Recente> {

    constructor(http: HttpClient) {
        super(http, '/menu/recentes', {});
    }

    list(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/menu/recentes`);
    }

    toggle(id, toggle = true): Observable<any> {

        if (toggle) {

            return this.http.post(`${environment.apiUrl}/menu/recentes/${id}`, '');

        } else {

            return this.http.delete(`${environment.apiUrl}/menu/recentes/${id}`);

        }

    }

}
