import {Component, Input, NgZone, OnInit, ViewChildren} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PerfilDetalheService} from './perfil-detalhe.service';
import {Perfil} from '@models/perfil.model';
import {ToastrService} from 'ngx-toastr';
import {DataService} from '@services/data.service';
import {Subscription} from 'rxjs';
import {NzTreeNode} from 'ng-zorro-antd/tree';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {UsuarioService} from '@services/usuario.service';
import {Usuario} from '@models/usuario.model';
import {buildUrl, findComponentByUrl} from '../../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';

interface RecursivoParams {
    nodes: NzTreeNode[];
    stack: string[];
}

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-perfil-detalhe',
    templateUrl: './perfil-detalhe.component.html',
    styleUrls: ['./perfil-detalhe.component.scss']
})

export class PerfilDetalheComponent extends AbstractListTable<any> implements OnInit {

    @Input() data;
    @ViewChildren('treePerfil') treePerfil;

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigarório',
        }
    };

    perfil: Perfil;
    checkedKeys = [];
    userAdmin = false;
    currentUser: Subscription;

    formDadosCadastrais: FormStack;
    formPermissoes: FormStack;

    carregando = true;
    gravando = false;
    loginCpf = true;
    permissoesTree: NzTreeNode[];

    tabNumber = 0;

    loadings = {
        tree: false
    };

    screenPermissoes = {
        alterarPermissoesPlano: '978946a28852'
    };

    logsDeAlteracoes = [];

    constructor(
        private dataService: DataService,
        private usuarioService: UsuarioService,
        private fb: UntypedFormBuilder,
        private perfilDetalheService: PerfilDetalheService,
        private toastr: ToastrService,
        private tabService: TabService,
        private zone: NgZone,
    ) {
        super(usuarioService, Usuario, toastr);

        this.getPermissoesPerfil();

        this.formDadosCadastrais = {
            modalVisible: false,
            formGroup: this.fb.group({
                descricao: [null, Validators.required],
            })
        };


    }

    ngOnInit(): void {

        this.currentUser = this.dataService.currentUser.subscribe((data) => {
            if (data) {

                this.userAdmin = data.admin === 1;

            }

        });

        this.carregar(this.data.id);
        this.getPermissoesTree();

    }

    getPermissoesTree() {

        this.permissoesTree = [];
        this.loadings.tree = true;
        this.perfilDetalheService.getTree(this.data.id).subscribe(res => {

            this.permissoesTree = this.perfilDetalheService.transformPermissoesItem(res, 0, true, true);

            setTimeout(() => {
                this.setSelectedItens(this.perfil.permissoes);
            }, 2000);


        });
    }


    getSelectedItens() {

        const recursivo = ({nodes, stack}: RecursivoParams) => {

            for (const node of nodes) {

                if (node.children && node.children.length > 0) {

                    stack = recursivo({nodes: node.children, stack});

                }

                if ((node.isChecked || node.isHalfChecked) && stack.indexOf(node.key) === -1) {

                    stack.push(node.key);
                }


            }

            return stack;

        };

        let selected = [];

        this.treePerfil.forEach(tree => {

            selected = recursivo({nodes: tree.getTreeNodes(), stack: selected});

        });

        return selected;

    }

    setSelectedItens(keys: string[]) {

        if (this.permissoesTree.length > 0) {


            const recursivo = (nodes: NzTreeNode[], hasChecked = 0) => {

                for (const node of nodes) {

                    let count = 0;

                    if (node.children && node.children.length > 0) {

                        count = recursivo(node.children);

                    } else if (keys.indexOf(node.key) > -1) {

                        count++;

                    }


                    node.isExpanded = node.origin.tipo === 'menu';
                    node.isChecked = count > 0;
                    hasChecked += count;

                }

                return hasChecked;

            };

            this.treePerfil.forEach(tree => {

                recursivo(tree.getTreeNodes());

            });
            this.loadings.tree = false;
        } else {
            setTimeout(() => {
                this.setSelectedItens(this.perfil.permissoes);
            }, 200);
        }


    }

    async carregar(id, quiet = false) {

        if (!quiet) {
            this.carregando = true;
        }

        this.perfil = new Perfil(await this.perfilDetalheService.get(id).toPromise() as Perfil);

        if (!quiet) {
            this.carregando = false;
        }

    }

    editar(formulario: FormStack): void {

        for (const name in formulario.formGroup.controls) {

            if (name) {

                formulario.formGroup.get(name).setValue(this.perfil[name]);

            }
        }

        formulario.modalVisible = true;

    }

    fechar(formulario: FormStack): void {

        formulario.modalVisible = false;
        this.gravando = false;

    }

    confirmar(formulario: FormStack) {

        this.gravando = true;

        const dadosPerfil = {};

        for (const key in formulario.formGroup.controls) {

            if (key) {

                const campo = formulario.formGroup.get(key);

                campo.markAsDirty();
                campo.updateValueAndValidity();

                dadosPerfil[key] = campo.value;

            }

        }

        if (formulario.formGroup.valid) {

            this.gravar(this.data.id, formulario.formGroup.value, formulario);

        } else {

            this.gravando = false;

        }

    }

    gravar(id, dados, formulario = null) {

        this.perfilDetalheService.save(id, dados).subscribe(
            () => {

                this.carregar(this.data.id, true);

                if (formulario) {

                    this.fechar(formulario);

                }

                this.toastrService.success('Dados atualizados com sucesso!');
                this.gravando = false;
            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.gravando = false;

            }
        );

    }

    gravarPermissoes() {


        this.gravando = true;
        this.perfilDetalheService.save(this.data.id, {permissoes: this.getSelectedItens()}).subscribe(
            () => {

                this.carregar(this.data.id, true);
                this.getPermissoesTree();

                this.toastrService.success('Dados atualizados com sucesso!');
                this.gravando = false;
            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.gravando = false;

            }
        );
    }

    changeTabs(event: any = null) {

        this.btnResetSearch();

        switch (this.tabNumber) {
            case 0: {
                // this.queryTable(this.currentParams, this.currentSearch);
                break;
            }
            case 1: {
                this.getLogAlteracoes(this.currentParams);
                break;
            }

            case 2: {
                this.carregar(this.data.id, true);
                this.getPermissoesTree();

                break;
            }

        }

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.loading = true;

        const url = this.perfilDetalheService.baseUrl + '/' + this.data.id + '/membros';

        this.abstractService.listToTable(params, search, url).subscribe((response) => {

            this.items = response?.data || response;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.refreshCheckedStatus();

            this.loading = false;
        });

    }

    getLogAlteracoes(params: NzTableQueryParams): void {

        this.currentParams = params;

        this.loading = true;

        this.usuarioService.logDeAlteracao('perfil', this.perfil.id).subscribe((response) => {

            this.logsDeAlteracoes = response;

            this.refreshCheckedStatus();

            this.loading = false;
        }, err => {
            this.loading = false;
            this.toastrService.error(err.error.message);
        });

    }

    btnResetSearch() {

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.queryTable(this.currentParams, this.currentSearch);

    }

}
