import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import Visibilidade from '@models/visibilidade.model';
import {AbstractService} from '@services/abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Helpers} from '../../../core/helpers';

@Injectable({providedIn: 'root'})
export class JobManagerService extends AbstractService<Visibilidade> {

    baseUrls: { table: string } = {
        table: environment.apiUrl + '/monitoramento/job-manager'
    };

    constructor(http: HttpClient) {
        super(http, '/administracao/visibilidades', Visibilidade);
    }


    getContasQueue(queue: string, servidor: string, sort: {key: string, value: string}) {

        return this.http.get(`${environment.apiUrl}/monitoramento/job-manager/retorna-contas-queue/${queue}/${servidor}`);

    }

    salvar(queueSelecionada: {queue: string; prefix: string}, strings: string[]) {

        return this.http.put(`${environment.apiUrl}/monitoramento/job-manager/retorna-contas-queue/${queueSelecionada.queue}/${queueSelecionada.prefix}`, {contas: strings});
    }

    getComboContas() {
        return this.http.get(`${environment.apiUrl}/filtros/contas/select`);
    }
}
