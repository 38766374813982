import {UntypedFormControl} from '@angular/forms';
import {Helpers} from './helpers';

export function isCPF(control: UntypedFormControl): { [key: string]: boolean } | null {
    if (!control.value) {
        return null;
    }

    if (Helpers.isValidCPF(control.value)) {
        return null;
    } else {
        return {cnpj: true};
    }
}

export function isCNPJ(control: UntypedFormControl): { [key: string]: boolean } | null {
    if (!control.value) {
        return null;
    }

    if (Helpers.isValidCnpj(control.value)) {
        return null;
    } else {
        return {cnpj: true};
    }
}

export function isCEP(control: UntypedFormControl): { [key: string]: boolean } | null {
    return Helpers.isValidCEP(control.value) ? null : {cep: true};
}

export function isPhone(control: UntypedFormControl): { [key: string]: boolean } | null {
    return Helpers.isPhoneNumber(control.value) ? null : {cep: true};
}
