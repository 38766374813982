import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {Observable, Subject} from 'rxjs';
import {UsuarioLogado} from '@models/usuarioLogado.model';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class UserService extends AbstractService<UsuarioLogado> {

    constructor(http: HttpClient) {
        super(http, '/user', UsuarioLogado);
    }

    currentuser: Subject<UsuarioLogado> = new Subject<UsuarioLogado>();

    getCurrentUser = () => this.currentuser.asObservable();

    usuarioLogado = (): Observable<UsuarioLogado> => this.http.get<UsuarioLogado>(`${environment.apiUrl}/usuario-logado`);


}
