import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {AbstractService} from '@services/abstract.service';
import {Megamenu} from '@components/megamenu/megamenu.model';

@Injectable({providedIn: 'root'})

export class MegamenuService extends AbstractService<Megamenu> {

    constructor(http: HttpClient) {
        super(http, '/menu', {});
    }

    public getTree() {
        return this.http.get(`${environment.apiUrl}/menu/tree`);
    }

    transformMegamenuItem(items, nivel = 1, expanded = false, beta = false) {

        const megamenuItems = [];
        let ordem = 0;

        for (const item of items) {

            if (!beta) {
                if (item.beta) {
                    continue;
                }
            }

            // Caso permissão, não incluir no menu
            if (item.type !== 'menu'){
                continue;
            }

            const megamenuItem = {
                level: nivel,
                title: item.title,
                description: item.titleAlt,
                icon: item.icon,
                url: item.url,
                urlType: item.urlType,
                open: true,
                expanded,
                selected: false,
                selectable: false,
                disabled: false,
                children: item.children ? this.transformMegamenuItem(item.children, nivel + 1, expanded, beta) : [],
                isLeaf: item.children ? item.children.length === 0 : true,
                key: item.id,
                tipo: item.type,
                ordem: ++ordem,
                extra: item.extra,
                beta: item.beta,
                favorito: item.favorite ? item.favorite : false
            };

            megamenuItems.push(megamenuItem);
        }

        return megamenuItems;

    }

}
