<nz-page-header class="site-page-header" nzTitle="Monitoramento" nzSubtitle="Relatórios">
</nz-page-header>
<nz-content>
    <div nz-row [nzGutter]="16">
        <div nz-col nzXs="24" nzMd="12" nzLg="6">
            <nz-card [nzTitle]="avatarEmpresas" style="height: 95%;" class="card-admin">
                <p>
                    Relatório de status de NSU por empresa.
                    <br/>
                    <br/>
                </p>
                <p>
                    <button nz-button nzType="primary" [nzLoading]="loadingExcelNSU" (click)="confirmaExportarNSU()">
                        Download
                    </button>
                </p>

            </nz-card>
        </div>
        <div nz-col nzXs="24" nzMd="12" nzLg="6">
            <nz-card [nzTitle]="avatarLimites" style="height: 95%;" class="card-admin">
                <p>
                    Status de consumo de limites por módulos cadastrados.
                    <br/>
                    <br/>
                </p>
                <p>
                    <button nz-button nzType="primary" (click)="openTab('/relatorios/contas-limites')">
                        Abrir
                    </button>
                </p>

            </nz-card>
        </div>
    </div>
    <ng-template #avatarEmpresas>
        <em nz-icon nzType="file" nzTheme="fill"></em> <span style="font-weight: 400; padding-left: 5px">Status NSU por Empresa</span>
    </ng-template>
    <ng-template #avatarLimites>
        <em nz-icon nzType="area-chart" nzTheme="outline"></em> <span style="font-weight: 400; padding-left: 5px">Limite x Utilizados</span>
    </ng-template>
</nz-content>

<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Exportar Empresas'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formExportar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadingExcelNSU" (click)="confirmaExportarNSU()">Exportar
        </button>
    </div>
</nz-modal>
