<nz-page-header class="site-page-header" nzTitle="Monitoramento"
                nzSubtitle="Monitoramento de Jobs">
</nz-page-header>

<nz-content>
    <nz-card class="ant-card-table" nzTitle="Jobs" [nzExtra]="cardExtra"
             [nzBodyStyle]="{'padding': loading ? '15px' : '0px'}"
             [ngClass]="expandedCard ? 'card-fullscreen' : ''"
             style="margin-bottom: 500px;">
        <ng-template #cardExtra>
            <div class="nav-item d-flex">

                <nz-badge [nzCount]="qtdsFiltros.jobs"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="onModalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>


                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input type="text" nz-input placeholder="Busca" [(ngModel)]="currentsSearches.jobs">
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch
                            (click)="getJobs(currentsParams.jobs, currentsSearches.jobs);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!expandedCard ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>
        <nz-table #tabelaProcessamentos
                  [nzData]="arrayJobs"
                  [nzLoading]="loadings.jobs"
                  [nzShowPagination]="false"
                  [nzFrontPagination]="false"
                  (nzQueryParams)="getJobs($event)"
                  [nzFooter]="tableFooter"
                  nzSize="small"
                  class="table-small fonte-doze">
            <thead>
            <tr>
                <th nzAlign="left" nzLeft nzWidth="100px" nzColumnKey="queue" [nzSortFn]="true">Job</th>
                <th nzAlign="center"
                    *ngFor="let colunaServidor of colunasServidores">{{colunaServidor.descricao}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of tabelaProcessamentos.data" style="">
                <ng-container>
                    <td nzAlign="left" nzLeft>
                        <span style="padding-left: 15px">{{item.queue}}</span>
                    </td>
                    <td nzAlign="center" *ngFor="let colunaServidor of colunasServidores; index as mesIndex"
                        style="width: 100px;">

                        <ng-container>
                            <a nz-button nzType="link" (click)="openContas(true, item.queue, colunaServidor.id)">
                                {{item.itens[colunaServidor.id] ? item.itens[colunaServidor.id] : '-'}}
                            </a>
                        </ng-container>

                    </td>
                </ng-container>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #tableFooter>
            {{arrayJobs ? arrayJobs.length : 0}} registros
        </ng-template>
    </nz-card>
</nz-content>


<nz-modal [(nzVisible)]="visibles.modalContas" [nzTitle]="tamplateTitiloContas" [nzClosable]="true"
          (nzOnCancel)="openContas(false)"
          [nzWidth]="948">
    <ng-template #tamplateTitiloContas>Queue: <b>{{queueSelecionada.queue}}</b> em <b>{{queueSelecionada.prefix}}</b>
    </ng-template>
    <div nz-row *nzModalContent>

        <nz-skeleton *ngIf="loadings.modalContas"></nz-skeleton>


        <nz-card
                [nzBodyStyle]="{'background-color': 'transparent', 'padding': '0', 'border' : 'none', 'box-shadow' : 'none'}"
                [nzExtra]="extraCardContas"
                *ngIf="!loadings.modalContas" style="box-shadow: none; border: none">
            <ng-template #extraCardContas>
                <div style="display: flex">

                    <div style="width: 300px;">
                        <span style="position: absolute; top: -10px;">Tipo:</span>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Selecione..."
                                   style="margin-right: 20px; width: 80%"
                                   [(ngModel)]="selectedTipo">
                            <nz-option nzLabel="Contabilidade" nzValue="Contabilidade"></nz-option>
                            <nz-option nzLabel="Empresarial" nzValue="Empresarial"></nz-option>
                        </nz-select>
                    </div>

                    <div style="width: 300px;">
                        <span style="position: absolute; top: -10px;">Classificação:</span>
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Selecione..."
                                   style="margin-right: 20px; width: 80%"
                                   [(ngModel)]="selectedClassificacao">
                            <nz-option nzLabel="Excellence" nzValue="excellence"></nz-option>
                            <nz-option nzLabel="Super" nzValue="super"></nz-option>
                            <nz-option nzLabel="Prime" nzValue="prime"></nz-option>
                        </nz-select>
                    </div>


                    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                        <input #inputBuscaContas type="text" nz-input placeholder="Busca"
                               [(ngModel)]="currentsSearches.contas">
                        <ng-template #suffixIconButton>
                            <button nz-button nzType="default"
                                    (click)="currentsSearches.contas=''"
                            >
                                <span nz-icon nzType="clear" nzTheme="outline"></span></button>
                        </ng-template>
                    </nz-input-group>
                </div>
            </ng-template>
            <nz-table
                #rowSelectionTable

                [nzShowPagination]="false"
                [nzFrontPagination]="false"
                [nzData]="arrayTodasContas"

                class="table-small fonte-doze"
                style="width: 100%;"
                [nzScroll]="{ y: '440px' }"

            >
                <thead>
                <tr>
                    <th nzWidth="35px">
                        <label nz-checkbox [(ngModel)]="allChecked" style="position: absolute"
                               (nzCheckedChange)="onAllContasChecked($event)"></label> `
                    </th>
                    <th>Conta</th>
                    <th nzWidth="15%">Tipo
                    </th>
                    <th nzWidth="15%">Classificação
                    </th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let data of rowSelectionTable.data">
                    <tr *ngIf="contemEmFiltros(data)">
                        <td style="width: 35px">
                            <label nz-checkbox [nzChecked]="checkedConta.has(data.id)"
                                   (nzCheckedChange)="onContaChecked(data.id, $event)"
                                   style="position: absolute"></label> `
                        </td>
                        <td style="cursor: pointer" (click)="onContaChecked(data.id, !checkedConta.has(data.id))">
                            {{ data.nome }}
                            <br><small>({{ data.identificador }})</small>
                        </td>
                        <td style="cursor: pointer"
                            (click)="onContaChecked(data.id, !checkedConta.has(data.id))">{{ data.tipo }}</td>
                        <td style="cursor: pointer"
                            (click)="onContaChecked(data.id, !checkedConta.has(data.id))">{{ data.classificacao }}</td>
                    </tr>
                </ng-container>
                </tbody>
            </nz-table>
            <nz-alert *ngIf="!loadings.modalContas" nzType="info" [nzMessage]="tamplateTextoAlert"
                      style="width: 100%; margin-top: 20px;"></nz-alert>

            <ng-template #tamplateTextoAlert>
                <b>{{checkedConta.size == 0 ? 'nenhuma' : checkedConta.size}}</b> {{checkedConta.size <= 1 ? 'conta selecionada' : 'contas selecionadas' }}
            </ng-template>

        </nz-card>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="openContas(false)">
            Cancelar
        </button>
        <button nz-button nzType="primary" [disabled]="loadings.modalContas || loadings.salvandoAlteracao"
                (click)="salvar()" [nzLoading]="loadings.salvandoAlteracao">
            Feito, salvar
        </button>

    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formFiltrar.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="onModalFiltrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.modalFiltrar"></nz-skeleton>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrar.formGroup"
              *ngIf="!loadings.modalFiltrar"
              style="width: 100%">
            <nz-form-item>
                <nz-form-label [nzSpan]="3">Conta(s)</nz-form-label>
                <nz-form-control [nzSpan]="18">
                    <nz-select formControlName="identificadores" [nzPlaceHolder]="'Selecione a(s) conta(s)'"
                               nzMode="multiple">
                        <nz-option *ngFor="let option of optionsContas"
                                   [nzLabel]="option.label + ' (' + option.value + ')'"
                                   [nzValue]="option.value" nzCustomContent>
                            {{option.label}} <br><small>({{option.value}})</small>
                        </nz-option>
                    </nz-select>

                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="onModalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar
        </button>
    </div>
</nz-modal>
