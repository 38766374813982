import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@NgModule({
  imports: [
    CommonModule,
    NzMenuModule,
    NzLayoutModule,
    NzDropDownModule,
    NzAvatarModule,
    NzButtonModule,
    NzIconModule,
    NzTableModule,
    NzFormModule,
    NzInputModule,
    NzGridModule,
    NzToolTipModule,
    NzPopoverModule,
    NzCardModule,
    NzDividerModule,
    NzSelectModule,
    NzModalModule,
    NzAnchorModule,
    NzRadioModule,
    NzSpinModule
  ],
  providers: [],
  declarations: [],
  exports: [
    NzMenuModule,
    NzLayoutModule,
    NzDropDownModule,
    NzAvatarModule,
    NzButtonModule,
    NzIconModule,
    NzTableModule,
    NzFormModule,
    NzInputModule,
    NzGridModule,
    NzToolTipModule,
    NzPopoverModule,
    NzCardModule,
    NzDividerModule,
    NzSelectModule,
    NzModalModule,
    NzAnchorModule,
    NzRadioModule,
    NzSpinModule
  ]
})
export class NgZorroModule { }