import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../auth/authentication.service';
import {UserService} from '@services/user.service';
import {TabService} from '@services/tab.service';
import {Tab} from '@models/tab.model';
import {TabHandlerInterface} from '../../../../shared/interfaces/tab-handler.interface';
import {buildUrl, findComponentByUrl} from '../../../../shared/components-helper';
import {UsuarioLogado} from '@models/usuarioLogado.model';

@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, TabHandlerInterface {

    currentUser: UsuarioLogado;

    constructor(
        private router: Router, private authenticationService: AuthenticationService,
        private userService: UserService, private tabService: TabService
    ) {
    }

    ngOnInit() {

        this.userService.usuarioLogado().subscribe(user => {
            this.currentUser = user;

            localStorage.setItem('perfil_id', user.perfil_id);

            if (user.srcAvatar) {
                this.currentUser.srcAvatar = 'data:image/png;base64,' + user.srcAvatar;
            }

        });
        // this.userService.currentuser.subscribe(user => this.currentUser = user);
    }

    logout(click: boolean) {
        if (click){
            this.authenticationService.logout('', true, true);
        }else{
            this.authenticationService.logout();
        }
    }

    openTab(path, queryParams = '', data = '') {
        const component = findComponentByUrl(path);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }
}

