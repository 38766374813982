import { Injectable } from '@angular/core';

@Injectable()
export class Utils {

    static camelCase(key) {
        const regex = /(_\w)/g;
        return key.replace(regex, match => match[1].toUpperCase());
    }

    static getValueInString(data, regex) {
        return data.match(regex);
    }

    static snakeCase(key) {
        const regex = /[\w]([A-Z])/g;
        return key.replace(regex, match => `${ match[0] }_${ match[1] }`).toLowerCase();
    }

    static isEmpty(value: any) {
        return value == null || value === 'undefined' || value === undefined || !value || value === '' || value.length === 0;
    }

    static toBoolean(value: string): boolean {
        return value === 'true';
    }

    static replaceToEmpty(value: string, match: string) {
        return value.replace(match, '');
    }

    static objectToString(messages: any[]) {
        const messageArray = [];
        for (const message of Object.keys(messages)) {
            messageArray.push(messages[message]);
        }

        return messageArray;
    }

    static getBasename(path: string, ext: boolean = true) {
        const basename = path.split('/').reverse()[0];
        return ext ? basename : basename.split('.')[0];
    }

    static getExtension(filename: string) {
        return filename.split('/').reverse()[0].split('.')[1];
    }

    static getType(mimeType: string) {
        const map = new Map<string, string>();
        map.set('application/pdf', 'pdf');
        map.set('application/xml', 'xml');
        map.set('image/png', 'png');
        map.set('image/jpeg', 'jpg');
        map.set('image/gif', 'gif');
        map.set('default', 'bpmn');
        return map.get(mimeType);
    }

    public static sliceTextByLength(text, length, start, end) {
        return text && text.length > length ? `${ text.slice(start, end) }...` : text;
    }

    static toSnakeCase(object) {
        const newObject = {};
        for (const prop of Object.keys(object)) {
            newObject[this.snakeCase(prop)] = object[prop];
        }

        return newObject;
    }

    static prepareConstToSelectElement(object: object) {
        const objects: object[] = [];
        Object.keys(object).forEach((type) => {
            objects.push(Object.assign({}, object[type]));
        });

        return objects;
    }

    static generateFileByUrl(uri: any) {
        let byteString;
        if (uri.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(uri.split(',')[1]);
        } else {
            byteString = unescape(uri.split(',')[1]);
        }

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new File([ia], 'User Signature.png', { type: 'image/png' });
    }

    static generateFileByBase64(base64: any) {
        const byteString = atob(base64.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new File([ab], 'Signature.png', { type: 'image/png' });
    }

    static createFile(data, signatureImageUrl, metadata) {
        return new File(data, signatureImageUrl, metadata);
    }

    sliceTextByLength(text, length, start, end) {
        return text && text.length > length ? `${ text.slice(start, end) }...` : text;
    }

}
