import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractService} from '@services/abstract.service';
import {Perfil} from '@models/perfil.model';

@Injectable({providedIn: 'root'})

export class PerfilDetalheService extends AbstractService<Perfil> {

    constructor(http: HttpClient) {
        super(http, '/administracao/perfil', {});
    }

    get(id: number | string): Observable<any> {

        return this.http.get(`${this.baseUrl}/${id}/detalhe`);

    }

    save(id, campos): Observable<any> {

        return this.http.put(`${this.baseUrl}/${id}`, campos);

    }

    public getTree(id: number | string) {
        return this.http.get(`${this.baseUrl}/${id}/permissoes`);
    }

    transformPermissoesItem(items, nivel = 1, expanded = false, beta = true) {

        const permissoesItems = [];
        let ordem = 0;

        for (const item of items) {

            const permissaoItem = {
                level: nivel,
                title: item.title, //  + ' (' + item.id + ')',
                description: item.titleAlt,
                descricao: item.description,
                icon: item.icon,
                url: item.url,
                urlType: item.urlType,
                open: false,
                expanded: item.type === 'menu',
                selectable: false,
                disabled: false,
                children: item.children ? this.transformPermissoesItem(item.children, nivel + 1, expanded, beta) : [],
                isLeaf: item.children ? item.children.length === 0 : true,
                key: item.id,
                tipo: item.type,
                ordem: ++ordem,
                extra: item.extra,
                beta: item.beta,
                favorito: item.favorite ? item.favorite : false
            };

            permissoesItems.push(permissaoItem);
        }

        return permissoesItems;

    }

}
