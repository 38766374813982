import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthenticationService } from '../auth/authentication.service';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentToken = this.authenticationService.currentTokenValue;

        if (currentToken && currentToken.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${ currentToken.token }`
                }
            });
        } else {

            if (!window.location.pathname.includes('change-password')) {
                this.router.navigate(['/login']);
            }

        }

        return next.handle(request);
    }
}
