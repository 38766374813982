import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import Visibilidade from '@models/visibilidade.model';
import {AbstractService} from './abstract.service';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class VisibilidadeService extends AbstractService<Visibilidade> {

    constructor(http: HttpClient) {
        super(http, '/administracao/visibilidades', Visibilidade);
    }

    retornaVisibilidades(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/administracao/visibilidades`);
    }


    remover(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/administracao/visibilidade/detalhe/${id}`);

    }

    cadastrarVisibilidade(dados: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/administracao/visibilidade`, dados);

    }

    exportExcel(filtros: any, search: string = null, tipo: string): any {

        filtros.tipoExportacao = tipo;

        const queryStr = this.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/contas/exportar${queryStr}`,
            {responseType: 'blob'});

    }

}
