<div id="profile-container" nz-dropdown [nzPlacement]="'bottomRight'" [nzDropdownMenu]="userMenu">
    <nz-badge id="profile">
        <nz-avatar *ngIf="!currentUser?.srcAvatar" nzIcon="user" [nzSize]="30" class="mr-sm"></nz-avatar>
        <img *ngIf="currentUser?.srcAvatar"
             nz-image class="imagem-avatar"
             [nzSrc]="currentUser?.srcAvatar"
             alt="{{currentUser.nome}}"
             nzDisablePreview>
    </nz-badge>
    <div *ngIf="currentUser else carregandoUsuario"
         style="min-width: 130px; max-width: 130px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
        {{ this.currentUser?.nome}}
    </div>
    <ng-template #carregandoUsuario>
        <div class="lds-ellipsis" style="width: 118px; margin-left: 12px"
             nz-tooltip="Carregando. Por favor aguarde." nzTooltipPlacement="bottom">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </ng-template>
</div>
<nz-dropdown-menu #userMenu="nzDropdownMenu">
    <ul nz-menu>
        <li (click)="openTab('/usuario-logado')" nz-menu-item>
            <em class="far fa-fw fa-id-card"></em> Meu Usuário
        </li>
        <li nz-menu-divider></li>
        <li nz-menu-item nzDisabled>
            <deploy-info></deploy-info>
        </li>
        <li nz-menu-divider></li>
        <li nz-menu-item (click)="logout(true)">
            <em class="fas fa-fw fa-power-off"></em> Sair
        </li>
    </ul>
</nz-dropdown-menu>
