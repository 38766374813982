import {UrlTypeEnum} from '../enums/url-type.enum';


export const componentsConfig = {
    CreditosCaptchaComponent: {
        url: '/captcha/creditos',
        urlType: UrlTypeEnum.nativo,
        title: 'Creditos Captcha',
        name: 'CreditosCaptchaComponent',
    },
    ContasComponent: {
        url: '/contas',
        urlType: UrlTypeEnum.nativo,
        title: 'Contas',
        name: 'ContasComponent',
    },
    ContaDetalheComponent: {
        url: '/conta/detalhe/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Detalhe da Conta',
        name: 'ContaDetalheComponent',
    },
    UsuarioLogadoComponent: {
        url: '/usuario-logado',
        urlType: UrlTypeEnum.nativo,
        title: 'Meu Usuário',
        name: 'UsuarioLogadoComponent',
    },

    StatusBaixasComponent: {
        url: '/monitoramento/statusBaixas',
        urlType: UrlTypeEnum.nativo,
        title: 'Monitoramento de Status',
        name: 'StatusBaixasComponent',
    },
    JobManagerComponent: {
        url: '/monitoramento/jobManager',
        urlType: UrlTypeEnum.nativo,
        title: 'Monitoramento de Jobs',
        name: 'JobManagerComponent',
    },
    UsuariosContasComponent: {
        url: '/monitoramento/usuarios-logados',
        urlType: UrlTypeEnum.nativo,
        title: 'Usuários Contas',
        name: 'UsuariosContasComponent',
    },

    RelatoriosComponent: {
        url: '/monitoramento/relatorios',
        urlType: UrlTypeEnum.nativo,
        title: 'Relatórios',
        name: 'RelatoriosComponent',
    },

    ContasLimitesComponent: {
        url: '/relatorios/contas-limites',
        urlType: UrlTypeEnum.nativo,
        title: 'Limites x Utilizados',
        name: 'ContasLimitesComponent',
    },

    UsuariosComponent: {
        url: '/administracao/usuarios',
        urlType: UrlTypeEnum.nativo,
        title: 'Usuários',
        name: 'UsuariosComponent',
    },

    AvisosComponent: {
        url: '/administracao/avisos',
        urlType: UrlTypeEnum.nativo,
        title: 'Avisos',
        name: 'AvisosComponent',
    },


    VisibilidadeDetalheComponent: {
        url: '/administracao/visibilidades/visibilidade-detalhe/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Visibilidade Detalhe',
        name: 'VisibilidadeDetalheComponent',
    },
    VisibilidadesComponent: {
        url: '/administracao/visibilidades',
        urlType: UrlTypeEnum.nativo,
        title: 'Visibilidades',
        name: 'VisibilidadesComponent',
    },
    PlanoDetalheComponent: {
        url: '/administracao/planos/plano-detalhe/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Plano Detalhe',
        name: 'PlanoDetalheComponent',
    },
    PlanosComponent: {
        url: '/administracao/planos',
        urlType: UrlTypeEnum.nativo,
        title: 'Planos',
        name: 'PlanosComponent',
    },
    AvisoDetalheComponent: {
        url: '/administracao/avisos/aviso-detalhe/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Aviso Detalhe',
        name: 'AvisoDetalheComponent',
    },
    ModuloDetalheComponent: {
        url: '/administracao/modulos/modulo-detalhe/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Módulo Detalhe',
        name: 'ModuloDetalheComponent',
    },
    ModulosComponent: {
        url: '/administracao/modulos',
        urlType: UrlTypeEnum.nativo,
        title: 'Módulos',
        name: 'ModulosComponent',
    },
    UsuarioDetalheComponent: {
        url: '/administracao/usuario/detalhe/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Detalhe do Usuário',
        name: 'UsuarioDetalheComponent',
    },
    PerfisComponent: {
        url: '/administracao/perfis-de-acesso',
        urlType: UrlTypeEnum.nativo,
        title: 'Perfil de Acesso',
        name: 'PerfisComponent',
    },
    PerfilDetalheComponent: {
        url: '/administracao/perfil/detalhe/:id',
        urlType: UrlTypeEnum.nativo,
        title: 'Detalhe do Perfil',
        name: 'PerfilDetalheComponent',
    },
    ExternalPageComponent: {
        url: '/embedded',
        urlType: UrlTypeEnum.embedded,
        title: 'Embedded',
        name: 'ExternalPageComponent',
    },
    ErrorComponent: {
        url: '/error',
        urlType: UrlTypeEnum.nativo,
        title: 'Error',
        name: 'Error',
    },
    LoadingComponent: {
        url: '/loading',
        urlType: UrlTypeEnum.nativo,
        title: 'loading...',
        name: 'LoadingComponent',
    },
    CreditosComponent: {
        url: '/administracao/creditos',
        urlType: UrlTypeEnum.nativo,
        title: 'Creditos',
        name: 'CreditosComponent',
    }
};
