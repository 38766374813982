import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractService} from '@services/abstract.service';
import {Perfil} from '@models/perfil.model';
import {environment} from '../../../../../environments/environment';

@Injectable({providedIn: 'root'})


export class PlanoDetalheService extends AbstractService<Perfil> {

    constructor(http: HttpClient) {
        super(http, '/administracao/planos', {});
    }

    retornaPlano(id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/administracao/planos/detalhe/${id}`);

    }

    atualizar(id: string, campos: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/administracao/planos/detalhe/${id}`, campos);

    }


    salvarPermissoes(id: string, campos: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/administracao/planos/detalhe/${id}/salvarPermissoes`, {permissoes: campos});

    }


    public getTree(id: number | string) {
        return this.http.get(`${environment.apiUrl}/administracao/planos/detalhe/${id}/permissoes`);
    }

    transformPermissoesItem(items, nivel = 1, expanded = false, beta = true) {

        const permissoesItems = [];
        let ordem = 0;

        for (const item of items) {

            if (!beta) {
                if (item.beta) {
                    continue;
                }
            }

            const permissaoItem = {
                level: nivel,
                title: item.title, //  + ' (' + item.id + ')',
                description: item.description,
                icon: item.icon,
                url: item.url,
                urlType: item.urlType,
                open: true,
                expanded,
                selectable: false,
                disabled: false,
                children: item.children ? this.transformPermissoesItem(item.children, nivel + 1, expanded, beta) : [],
                isLeaf: item.children ? item.children.length === 0 : true,
                key: item.id,
                tipo: item.type,
                ordem: ++ordem,
                extra: item.extra,
                beta: item.beta,
                alfa: item.release === 'alfa',
                favorito: item.favorite ? item.favorite : false
            };

            permissoesItems.push(permissaoItem);
        }

        return permissoesItems;

    }

}
