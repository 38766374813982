import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Usuario} from '@models/usuario.model';
import {UsuarioService} from '@services/usuario.service';
import {VisibilidadeService} from '@services/visibilidade.service';
import {PerfilService} from '@services/perfil.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {environment} from '../../../../environments/environment';
import * as fileSaver from 'file-saver-es';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-visibilidades',
    templateUrl: './visibilidades.component.html',
    styleUrls: ['./visibilidades.component.scss']
})
export class VisibilidadesComponent extends AbstractListTable<Usuario> implements OnInit, TabHandlerInterface {

    readonly registerLink = '/usuarios/cadastrar';

    profileOptions: { label: string; value: string }[] = [];

    visibilidadeOptions: { label: string; value: string }[] = [];

    token;

    cadastrando = false;

    formFiltrar: FormStack;
    formExportar: FormStack;
    status = false;
    qtdFiltrosAtivos = 0;

    screenPermissoes = {
        cadastrar: '89793e00884d',
        remover: '89794058884d'
    };

    loadingExportar = false;

    constructor(
        private fb: UntypedFormBuilder,
        private usuarioService: UsuarioService,
        private toastService: ToastrService,
        private visibilidadeService: VisibilidadeService,
        private perfilService: PerfilService,
        private modalService: NzModalService,
        private tabService: TabService) {
        super(usuarioService, Usuario, toastService);

        this.getPermissoesPerfil();

        this.formCadastrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                descricao: [null, [Validators.required]],
            })
        };

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                descricao: [null, null],
            })
        };

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };
    }

    ngOnInit() {

        // this.buildExportUrl();
        // this.getPerfis();

    }

    buildExportUrl() {
        // this.token = this.authenticationService.currentTokenValue;
        // this.exportUrl = `${environment.apiUrl}/user/excel-export?token=${this.token}`;
    }

    async getPerfis() {

        return this.perfilService.listToSelect().subscribe((result) => {
            this.profileOptions = result.map((option: any) => ({
                label: option.descricao,
                value: option.id
            }));
        });

    }

    async retornaVisibilidades() {

        return this.visibilidadeService.retornaVisibilidades().subscribe((retorno) => {
        });

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    confirmaCadastro() {

        this.loading = true;

        if (this.formCadastrar.formGroup.valid) {

            this.visibilidadeService.cadastrarVisibilidade(this.formCadastrar.formGroup.value).subscribe(
                () => {

                    this.formCadastrar.formGroup.reset();
                    this.toastrService.success('Visibilidade cadastrada com sucesso!');
                    this.updateTable();
                    this.modalCadastrar(false);
                    this.loading = true;
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.loading = false;

                }
            );


        } else {

            this.cadastrando = false;

        }

    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.calculaBadgeFiltros();

        const url = environment.apiUrl + '/administracao/visibilidades';

        this.loading = true;

        this.abstractService.listToTable(params, search, url).subscribe((response) => {

            this.items = [];
            this.items = response?.data || response;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loading = false;

        });

    }

    modalFiltrar(visible: boolean) {
        this.formFiltrar.modalVisible = visible;
    }

    modalExportar(visible: boolean): void {
        this.formExportar.modalVisible = visible;
    }

    btnResetSearch() {

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    showConfirmRemover(id: string): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover a Visibilidade?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.remover(id)
        });

    }

    remover(id: string): void {

        this.loading = true;
        this.visibilidadeService.remover(id).subscribe(
            () => {
                this.toastrService.success('Visivilidade removida com sucesso!');
                this.queryTable(this.currentParams, this.currentSearch);
                this.loading = false;
            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.loading = false;

            }
        );
    }

    confirmaFiltrar(): void {

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (const [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    calculaBadgeFiltros(): void {
        let qtd = 0;

        if (this.formFiltrar.formGroup.value.descricao) {
            qtd += 1;
        }
        this.qtdFiltrosAtivos = qtd;
    }

    confirmaExportar(): void {


        if (this.formExportar.formGroup.valid) {
            this.loadingExportar = true;
            const tipo = this.formExportar.formGroup.value.tipo;

            this.visibilidadeService.exportExcel(this.currentParams.filter, this.currentSearch, tipo).subscribe((res) => {

                if (this.formExportar.formGroup.value.tipo) {

                    const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

                    const name = 'Visibilidades';

                    fileSaver.saveAs(blob, name + '.' + this.formExportar.formGroup.value.tipo);

                    this.formExportar.formGroup.reset();

                    this.formExportar.modalVisible = false;
                    this.loadingExportar = false;
                }

            }, error => {
                this.toastService.error(error.error.message);
                this.loadingExportar = false;
            });
        } else {
            this.loadingExportar = false;
            Object.values(this.formExportar.formGroup.controls).forEach(control => {

                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });
        }
    }

}
