import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'telefone'})
export class TelefonePipe implements PipeTransform {

    transform(value: string): string {

        if (!value) {
            return null;
        }

        return value.replace(/(\d{0})(\d{2})(\d{5})(\d{4})/g, '\$1(\$2) \$3-\$4');

    }

}
