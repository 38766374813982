import ActionModel from '@models/action.model';
import { ActionTypes } from '@actions/actions-type';

export const data = {};

export function Reducer(state = { data }, action: ActionModel) {
    switch (action.type) {
        case ActionTypes.Add:
            Object.preventExtensions(state);
            state.data[action.name] = action.payload;
            return state;

        case ActionTypes.Remove:
            state.data = data;
            return state;

        case ActionTypes.Clear:
            state.data[action.name] = {};
            return state;

        case ActionTypes.Get:
            return state;

        default:
            return state;
    }

}

export const formState = (state) => state;
