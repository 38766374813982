import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgZorroModule} from '../../../shared/ng-zorro.module';
import {NgxMaskModule} from 'ngx-mask';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {ContaDetalheComponent} from './conta-detalhe.component';
import {UiModule} from '../../ui.module';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzAffixModule} from 'ng-zorro-antd/affix';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {NzSpaceModule} from 'ng-zorro-antd/space';
import {NzImageModule} from 'ng-zorro-antd/image';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {ImageCropperModule} from 'ngx-image-cropper';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';

@NgModule({
    declarations: [
        ContaDetalheComponent
    ],
    imports: [
        CommonModule,
        NgZorroModule,
        NgxMaskModule,
        ReactiveFormsModule,
        NzRadioModule,
        UiModule,
        NzBreadCrumbModule,
        NzTabsModule,
        NzListModule,
        NzPageHeaderModule,
        NzAffixModule,
        NzTypographyModule,
        NzDatePickerModule,
        FormsModule,
        NzSwitchModule,
        NzSkeletonModule,
        NzTagModule,
        NzSpaceModule,
        NzImageModule,
        NzUploadModule,
        ImageCropperModule,
        NzCheckboxModule
    ]
})
export class ContaDetalheModule {
}
