import { Component, OnInit } from '@angular/core';
import { TabService } from '@services/tab.service';
import { Tab } from '@models/tab.model';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { Clear } from '@actions/actions-tab';
import { setStorageTab } from '@reducers/tab.reducer';

@Component({
    selector: 'Tabset',
    templateUrl: './tabset.component.html',
    styleUrls: ['./tabset.component.scss']
})
export class TabsetComponent implements OnInit {
    tabs = new Array<Tab>();
    selectedTab: number;
    tabs$: Observable<Tab[]>;

    constructor(private tabService: TabService, private store: Store) {
        // @ts-ignore
        this.tabs$ = this.store.pipe(select('tabs'));
    }

    ngOnInit() {
        this.tabs$.subscribe(tabs => {
            this.tabs = tabs;
        });
        this.getCurrentTabIndex();
    }

    getCurrentTabIndex() {
        this.tabService.getTabIndex.subscribe(index => {
            this.selectedTab = index;
        });
    }

    // @ts-ignore
    getTabs = () => this.store.pipe(select('tabs'));

    removeTab = (event) => this.tabService.removeTab(event.index);

    selectTab(event) {
        this.tabs.forEach(tab => {
            tab.active = tab.id === event.index;
        });
        setStorageTab(this.tabs);
        this.tabService.setCurrentTabIndex = event.index;
        if (this.tabs.length > 0) {
            this.setUrl(this.tabs[event.index].url);
        }
    }

    setUrl(url) {
        window.history.replaceState({}, '', url);
    }

    closeAll() {
        this.store.dispatch(Clear());
    }

    reloadTab(tab) {
        this.tabService.reloadTab(tab);
    }

    openInNew(tab: any) {
        this.tabService.openInNew(tab);
    }

    removeTabById(tabId) {
        this.tabService.removeTabById(tabId);
    }

    removeOthersTabs(id: number) {
        this.tabService.removeOthersTabs(id);
    }

    removeAllTabs() {
        this.tabService.removeAllTabs();
    }
}
