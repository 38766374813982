import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../core/auth/authentication.service';

@Component({
    selector: 'external-page',
    templateUrl: './external-page.component.html',
    styleUrls: ['./external-page.component.scss'],
})

export class ExternalPageComponent implements OnInit {

    @Input() data: any;
    embeddedUrlBase = environment.embeddedUrlBase;
    safeExternalLink: SafeResourceUrl;
    redirectLink: string;
    loading = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private sanitizer: DomSanitizer,
        private authService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.urlHandler();
    }

    urlHandler() {
        const currentToken = this.authService.currentTokenValue;
        let fullUrl = `${ this.embeddedUrlBase }?token=${ currentToken.token }&redirect=${ this.data.link }`;
        if (this.data.hasOwnProperty('extra') && this.data.extra != null) {
            const extra = `&extra=${ this.data.extra }`;
            fullUrl = fullUrl + extra;

        }
        this.safeExternalLink = this.getSafeExternalLink(fullUrl);
    }

    getSafeExternalLink(externalLink: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(externalLink);
    }

    loadEvent(event) {
        this.loading = false;
    }
}
