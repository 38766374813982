<div class="container-login">
    <img src="" alt="" class="image"
         [ngStyle]="{'background-image': 'url(/assets/pattern.png)', 'background-size' : 'cover'}"/>
    <div class="container-form">
        <img alt="" src="../../../../assets/login.png" style="" width="25%"/>
        <form (ngSubmit)="onSubmit()" [formGroup]="formPasswordRecovey" class="login-form" nz-form >
            <nz-form-item *ngIf="mensagem == 'senhaExpirada'">
                <nz-alert
                        nzType="warning"
                        nzMessage="Sua senha expirou!"
                        [nzDescription]="'Olá, ' + usuarioNome + '. Por motivos de segurança, a sua senha atual não é mais válida. Insira ' +
                     'uma nova senha nos campos abaixo para concluir o login.'"
                        nzShowIcon
                ></nz-alert>
            </nz-form-item>
            <nz-form-item *ngIf="usuarioLogin">
                <nz-form-control>
                    <nz-input-group class="login-input" nzPrefixIcon="user">
                        <input formControlName="login" nz-input
                               type="text">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-control [nzErrorTip]="errorPassword">
                    <nz-input-group class="login-input" [nzSuffix]="suffixTemplatePassword" nzPrefixIcon="lock">
                        <input [type]="passwordVisible ? 'text' : 'password'"
                               autocomplete="off"
                               formControlName="password"
                               id="password"
                               nz-input
                               placeholder="Senha"
                               nz-popover
                               autofocus
                               nzPopoverPlacement="right"
                               [nzPopoverVisible]="popoverVisibleSenha"
                               [nzPopoverTitle]="validadePasswordTitle"
                               [nzPopoverContent]="validadePasswordContent"
                               (ngModelChange)="verificaSenha($event, true)"
                               (focusout)="validaCampoSenha($event, true)">
                    </nz-input-group>
                    <ng-template #suffixTemplatePassword>
                        <em (click)="passwordVisible = !passwordVisible"
                            [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                            nz-icon></em>
                    </ng-template>
                    <ng-template #errorPassword let-control>
                        <ng-container *ngIf="control.hasError('incorrect')">Senha não contém os requisitos de segurança</ng-container>
                        <ng-container *ngIf="control.hasError('required')">Campo Obrigatório</ng-container>
                    </ng-template>
                </nz-form-control>
                <ng-template #showPassword>
                    <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                          (click)="passwordVisible = !passwordVisible" style="cursor: pointer;"></span>
                </ng-template>
                <ng-template #validadePasswordTitle>
                    <h5 nz-typography style="margin: 0;">
                        <i class="fa-solid fa-key" style="margin-right: 3px;"></i> Segurança da Senha
                    </h5>
                </ng-template>
                <ng-template #validadePasswordContent>
                    <div [ngStyle]="passwordValidate.length && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.length"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.length" style="margin-right: 3px;"></span>
                        <span>A senha deve conter entre 8 e 30 caracteres.</span>
                    </div>
                    <div [ngStyle]="passwordValidate.lowercase && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.lowercase"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.lowercase"
                              style="margin-right: 3px;"></span>
                        <span>A senha deve conter ao menos uma letra minúscula.</span>
                    </div>
                    <div [ngStyle]="passwordValidate.uppercase && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.uppercase"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.uppercase"
                              style="margin-right: 3px;"></span>
                        <span>A senha deve conter ao menos uma letra maiúscula.</span>
                    </div>
                    <div [ngStyle]="passwordValidate.number && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.number"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.number" style="margin-right: 3px;"></span>
                        <span>A senha deve conter ao menos um número.</span>
                    </div>
                </ng-template>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control [nzErrorTip]="errorConfirmPassword">
                    <nz-input-group class="login-input" [nzSuffix]="suffixTemplateConfirmation" nzPrefixIcon="lock">
                        <input [type]="confirmPasswordVisible ? 'text' : 'password'" autocomplete="off"
                               formControlName="confirmPassword"
                               id="confirm-password"
                               nz-input placeholder="Confirmar senha">
                    </nz-input-group>
                    <ng-template #suffixTemplateConfirmation>
                        <em (click)="confirmPasswordVisible = !confirmPasswordVisible"
                            [nzType]="confirmPasswordVisible ? 'eye-invisible' : 'eye'"
                            nz-icon></em>
                    </ng-template>
                    <ng-template #errorConfirmPassword let-control>
                        <ng-container *ngIf="control.hasError('mustMatch')">
                            As senhas não conferem!
                        </ng-container>
                        <ng-container *ngIf="control.hasError('required')">
                            É necessário confirmar a senha!
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <button [nzType]="'primary'" class="login-form-button login-form-margin" nz-button
                    type="submit" [disabled]="!formPasswordRecovey.valid" [nzLoading]="loading">Alterar senha
            </button>
        </form>
    </div>
</div>
