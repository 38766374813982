<nz-page-header class="site-page-header" nzTitle="Detalhe do Plano"
                nzSubtitle="Informações cadastrais do plano"></nz-page-header>
<nz-content>
    <ng-template #indicatorTemplate>
        <i class="loading-icon" nz-icon nzType="loading"></i>
    </ng-template>
    <nz-spin [nzSpinning]="carregando" [nzSize]="'large'" nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
    <div nz-row [nzGutter]="16" *ngIf="!carregando">
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <nz-card [nzTitle]="plano?.descricao" [nzExtra]="extraInfCadastrais">
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Criado em">
                            <nz-list-item-meta-title>
                                {{plano?.created_at ? (plano.created_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Última alteração">
                            <nz-list-item-meta-title>
                                {{plano?.updated_at ? (plano.updated_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </nz-card>
            <ng-template #extraInfCadastrais>
                <a nz-button nzType="link" (click)="editar(formDadosCadastrais)">Alterar</a>
            </ng-template>

        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18">

            <nz-card>

                <nz-card-tab>
                    <nz-tabset
                            [nzSize]="'large'"
                            [nzTabBarExtraContent]="extraPlanos"
                            [(nzSelectedIndex)]="tabNumber"
                            (nzSelectChange)="changeTabs($event)">
                        <nz-tab nzTitle="Permissões de Acesso"></nz-tab>
                        <nz-tab nzTitle="Contas"></nz-tab>
                        <nz-tab nzTitle="Log de alterações"></nz-tab>
                    </nz-tabset>
                </nz-card-tab>


                <nz-skeleton *ngIf="tabNumber == 0 && loadings.permissoes"></nz-skeleton>
                <nz-tree #treePlano
                         [nzData]="permissoesTree"
                         [nzShowExpand]="true"
                         [nzExpandAll]="expandAll"

                         *ngIf="tabNumber == 0"
                         [nzTreeTemplate]="nzTreeTemplate"
                         nzCheckable
                         [ngStyle]="loadings.permissoes && {display: 'none'}"
                         nzMultiple>
                </nz-tree>
                <ng-template #nzTreeTemplate let-node let-origin="origin">
                          <span id="{{ node.key }}" title="{{ origin.tipo === 'menu' ? origin.url : origin.description }}">

                              <span>{{ node.title }}</span>
                              <i class="fas fa-key"
                                 style="margin-left: 10px; font-size: 10px;"
                                 *ngIf="origin.tipo === 'permissão'"
                              ></i>
                              <span *ngIf="origin.tipo === 'menu' && origin.beta && !origin.alfa"
                                    [ngClass]="'beta-tag'"
                                    class="beta-tag">beta</span>
                              <span *ngIf="origin.tipo === 'menu' && origin.alfa"
                                    [ngClass]="'alfa-tag'"
                                    class="beta-tag">alfa</span>
                          </span>
                </ng-template>


                <nz-table #basicTable
                          nzSize="small"
                          [nzData]="contas"
                          [nzFrontPagination]="false"
                          [nzLoading]="loading"
                          [nzTotal]="pagination.contas?.total"
                          [nzPageSize]="pagination.contas?.per_page"
                          [nzPageIndex]="pagination.contas?.current_page"
                          *ngIf="tabNumber == 1"
                          [nzShowTotal]="rangeTemplateEmpresas"
                          class="fonte-doze table-small"
                          (nzQueryParams)="queryTable($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="identificador" [nzSortFn]="true">Identificador</th>
                        <th nzColumnKey="nome" [nzSortFn]="true">Nome</th>
                        <th nzColumnKey="tipo" [nzSortFn]="true">Tipo</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let conta of basicTable.data">
                        <td nzLeft>

                            <a nz-button nzType="link" (click)="openTab('/conta/detalhe', conta.id, {id: conta.id})">
                                {{conta?.nome}}
                            </a>
                        </td>
                        <td nzLeft>{{conta?.identificador ? conta?.identificador : '-'}}</td>
                        <td>{{conta?.tipo}}</td>
                    </tr>
                    </tbody>
                </nz-table>
                <ng-template #rangeTemplateEmpresas let-range="range" let-total>
                    {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                </ng-template>


                <nz-table #basicTable
                          nzSize="small"
                          [nzData]="logsDeAlteracoes"
                          [nzFrontPagination]="false"
                          [nzLoading]="loadingLog"
                          *ngIf="tabNumber == 2"
                          class="fonte-doze table-small">
                    <thead>
                    <tr>
                        <th style="text-align: center" nzColumnKey="updated_at">Data</th>
                        <th style="text-align: center" nzColumnKey="updated_at">Hora</th>
                        <th style="text-align: center" nzColumnKey="user_name">Usuário</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let log of basicTable.data">
                        <td style="text-align: center">{{log.updated_at ? (log.updated_at | date:'dd/MM/YY') : '-' }}</td>
                        <td style="text-align: center">{{log.updated_at ? (log.updated_at | date:'HH:mm:ss') : '-' }}</td>
                        <td style="text-align: center">
                            <a nz-button nzType="link"
                               (click)="openTab('/administracao/usuario/detalhe', log.id, {id: log.id})">{{log?.user_name}}</a>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>

                <ng-template #rangeTemplate let-range="range" let-total>
                    {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                </ng-template>

            </nz-card>
            <ng-template #extraPlanos>
                <div class="nav-item d-flex" *ngIf="tabNumber == 0">
                    <button nz-button style="margin-right: 10px"
                            (click)="expandAllHandler(true)">
                        <em class="fas fa-angle-double-down"></em>
                    </button>
                    <button nz-button style="margin-right: 15px"
                            (click)="expandAllHandler(false)">
                        <em class="fas fa-angle-double-up"></em>
                    </button>

                    <button nz-button nzType="primary"
                            (click)="gravarPermissoes()" [nzLoading]="loadings.gravandoPermissoes">
                        Gravar Alterações
                    </button>
                </div>
                <div class="nav-item d-flex" *ngIf="tabNumber == 1">
                    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                        <input type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
                    </nz-input-group>
                    <ng-template #suffixIconButton>
                        <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                            <i nz-icon nzType="search"></i></button>
                    </ng-template>
                    <button nz-button nzType="default" style="margin-right: 10px;"
                            (click)="btnResetSearch();">
                        Limpar
                    </button>
                </div>


            </ng-template>
        </div>
    </div>
</nz-content>

<nz-modal [(nzVisible)]="formDadosCadastrais.modalVisible" [nzTitle]="'Alterar Descrição'" [nzClosable]="true"
          (nzOnCancel)="fechar(formDadosCadastrais)">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formDadosCadastrais.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Descrição</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="descricao" size="60"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formDadosCadastrais)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formDadosCadastrais)">Confirmar
        </button>
    </div>
</nz-modal>
