<nz-page-header class="site-page-header" nzTitle="Administração" nzSubtitle="Gerenciamento de Perfis de Acesso">
</nz-page-header>
<nz-content>
    <nz-card [nzExtra]="cardExtra" nzTitle="Perfis de Acesso"
             [nzLoading]="loading" [nzBodyStyle]="{'padding': loading ? '15px' : '0px'}"
             [ngClass]="expandedCard ? 'card-fullscreen' : ''">

        <ng-template #cardExtra>
            <div class="nav-item d-flex">

                <div class="nav-item">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalCadastrar(true);"
                            *ngIf="hasPermissao(screenPermissoes.cadastrar, currentPermissoes)">
                        Cadastrar
                    </button>
                </div>

                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>

                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch">
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!expandedCard ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>

        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)">
            <thead>
            <tr>
                <th nzColumnKey="descricao" [nzSortFn]="true">Descrição</th>
                <th nzAlign="center" nzColumnKey="quantidadeMembros">Membros</th>
                <th nzAlign="center" nzColumnKey="created_at" [nzSortFn]="true">Cadastro Data</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let perfil of basicTable.data">
                <td nzLeft>
                    <a nz-button nzType="link"
                       (click)="openTab('/administracao/perfil/detalhe', perfil.id, {id: perfil.id, descricao: perfil.descricao})">
                        {{perfil?.descricao}}
                    </a>
                </td>
                <td nzAlign="center">{{perfil.quantidadeMembros ? (perfil.quantidadeMembros | number) : '-' }}</td>
                <td nzAlign="center">{{perfil.created_at ? (perfil.created_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}</td>
            </tr>
            </tbody>
        </nz-table>
    </nz-card>
</nz-content>


<nz-modal [(nzVisible)]="formCadastrar.modalVisible" [nzTitle]="'Cadastrar Novo Perfil de Acesso'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)">
    <div *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formCadastrar.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>Descrição</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="descricao" placeholder="informe a descrição do perfil"
                           maxlength="60"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaCadastro()">Confirmar</button>
    </div>
</nz-modal>
