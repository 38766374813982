<div class="alain-default__nav-item" (click)="showMegaMenu()">
    <em id="menu-icon" nz-icon nzType="menu"></em>
</div>
<nz-modal
        [nzStyle]="{ top: '20px', width: '95%' }"
        [nzWidth]="2500"
        [(nzVisible)]="isVisibleTop"
        [nzFooter]="null"
        [nzClosable]="true"
        (nzOnCancel)="closeMegaMenu()"
        (nzOnOk)="closeMegaMenu()"
        [nzAutofocus]="null">
    <div *nzModalTitle>
        <div nz-row>
            <div nz-col nzFlex="100px">Menu</div>
            <div nz-col nzFlex="auto" style="text-align: right; margin-right: 30px">
                <nz-space>
                    <nz-switch *ngIf="userAdmin" [(ngModel)]="exibirBeta" nzCheckedChildren="beta"
                               nzUnCheckedChildren="beta" (ngModelChange)="getMenuItens()"></nz-switch>
                    <button nz-button [nzLoading]="carregando" nzType="text" nzSize="small" nzShape="circle"
                            (click)="getMenuItens()"><i *ngIf="!carregando" class="fa fa-refresh"
                                                        aria-hidden="true"></i></button>
                </nz-space>
            </div>
        </div>

    </div>
    <div nz-row *nzModalContent>
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="4">

            <div class="megamenu-favoritos">
                <h5 nz-typography>
                    <em class="fa fa-star"></em> Favoritos
                </h5>
                <ul class="megamenu-itens">
                    <li *ngFor="let favorito of favoritos">
                        <a [routerLink]="[]"
                           (click)="clickFavorito(favorito.id)">{{favorito.descricaoEstendida ? favorito.descricaoEstendida : favorito.descricao}}</a>
                        <em class="fa fa-close" (click)="setMenuItemFavorito($event, favorito.id, false)"
                            title="remover favorito"></em>
                    </li>
                </ul>
            </div>
            <div class="megamenu-recentes">
                <h5 nz-typography>
                    <em class="fa fa-clock"></em> Recentes
                </h5>
                <ul class="megamenu-itens">
                    <li *ngFor="let recente of recentes">
                        <a [routerLink]="[]"
                           (click)="clickRecente(recente.id)">{{recente.descricaoEstendida ? recente.descricaoEstendida : recente.descricao}}</a>
                        <em class="fa fa-close" (click)="setMenuItemRececente($event, recente.id, false)"></em>
                    </li>
                </ul>
            </div>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="20">
            <div class="megamenu-pesquisar" nz-row nzGutter="8">
                <div nz-col nzFlex="auto">
                    <nz-input-group [nzSuffix]="suffixIconSearch" id="input-search-menu">
                        <input id="autoFocus" (keyup)="validaPesquisa()" [(ngModel)]="searchValue" autocomplete="off"
                               nz-input placeholder="Pesquisar" title="procurar item no menu" type="text"/>
                    </nz-input-group>
                    <ng-template #suffixIconSearch>
                        <em style="cursor:pointer;" *ngIf="searchValue" (click)="searchValue = null" nz-icon
                            nzType="close"></em>
                    </ng-template>
                </div>
                <div nz-col nzFlex="70px">
                    <button (click)="expandAllHandler(!expand)" nz-button>
                        <em class="fas fa-angle-double-down"
                            [ngClass]="!expand ? 'fas fa-angle-double-down' : 'fas fa-angle-double-up'"></em>
                    </button>
                </div>
            </div>
            <div class="megamenu-arvore" nz-row nzGutter="8" style="margin-top: 15px">
                <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6" *ngFor="let menu of menus">
                    <h5 nz-typography>
                        <em class="{{ menu.icon }}"></em> {{ menu.title }}
                    </h5>

                    <nz-tree #treeMegaMenu
                             [nzData]="menu?.children"
                             (nzClick)="nzEvent($event)"
                             [nzExpandAll]="expandAll"
                             [nzSearchValue]="searchValue"
                             [nzTreeTemplate]="nzTreeTemplate"
                             nzBlockNode="true"


                    ></nz-tree>
                    <ng-template #nzTreeTemplate let-node let-origin="origin">
                          <span id="{{ node.key }}" title="{{ origin.url }}">
                              <a [routerLink]="[]" (click)="setMenuItemFavorito($event, node.key, !origin.favorito)"
                                 *ngIf="node.children.length == 0" class="megamenu-fav-star"
                                 [ngClass]="{'megamenu-fav-on' : origin.favorito }" title="marcar como favorito">
                                  <i class="fa"
                                     [ngClass]="{'fa-star' : origin.favorito, 'fa-star-o' : !origin.favorito }"></i>&nbsp;
                              </a>
                              <span [ngClass]="[(node.title).toUpperCase().includes((this.searchValue?.trim()).toUpperCase()) ? 'destaque' : '']">
                                  {{ node.title }}
                              </span>
                              <span *ngIf="origin.beta" class="beta-tag"> beta! </span>
                          </span>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</nz-modal>
