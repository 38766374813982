import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/auth/auth.guard';
import {HomeComponent} from './modules/home/pages/home.component';
import {PasswordRecoveryComponent} from './modules/login/pages/password-recovery.component';
import {AuthenticationService} from './core/auth/authentication.service';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('./modules/login/login.module')
            .then(m => m.LoginModule),
    },
    {
        path: 'change-password',
        component: PasswordRecoveryComponent,
    },
    {
        path: '**',
        redirectTo: 'home'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
    providers: [
        AuthenticationService
    ]
})

export class AppRoutingModule {
}
