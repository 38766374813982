<nz-page-header class="site-page-header" nzTitle="Monitoramento" nzSubtitle="Últimos logins de usuários nas contas ">
</nz-page-header>
<nz-content>
    <nz-card [nzExtra]="cardExtra" nzTitle="Usuários - Contas"
             [nzLoading]="loadings.tabela" [nzBodyStyle]="{'padding': loadings.tabela ? '15px' : '0px'}"
             [ngClass]="expandedCard ? 'card-fullscreen' : ''">
        <ng-template #cardExtra>
            <div class="nav-item d-flex">


                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>

                <button class="centralized-icon" [nzDropdownMenu]="menuAcoes"
                        [nzType]="'default'" nz-button nz-dropdown style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar Planilha</li>
                    </ul>
                </nz-dropdown-menu>

                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button nzType="default"
                        style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                        (click)="updateTable();" title="Atualizar">
                    <i nz-icon nzType="reload"></i>
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!expandedCard ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>

        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  [nzShowTotal]="rangeTemplate"
                  (nzQueryParams)="queryTable($event)"
                  class="fonte-doze table-small">
            <thead>
            <tr>
                <th [nzShowSort]="false"></th>
                <th nzColumnKey="conta_nome" [nzSortFn]="true" nzLeft>Conta</th>
                <th nzColumnKey="nome" [nzSortFn]="true" nzAlign="center">Usuário</th>
                <th nzColumnKey="login_data" [nzSortFn]="true" [nzSortOrder]="'descend'" nzAlign="center">Último login
                    no dia
                </th>
                <th [nzShowSort]="false"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let usuario of basicTable.data; let i = index">
                <td nzLeft>{{i + 1}}</td>
                <td nzLeft>
                    {{usuario?.conta_nome}}
                    <br>
                    <small>({{usuario?.conta_identificador}})</small>
                </td>
                <td nzAlign="center">
                    <span>
                        {{usuario?.nome}}
                        <br><small>({{usuario?.email}})</small>
                    </span>
                    <button nz-button nzType="link" style="position: absolute; top: 10px; right: 100px;"
                            [nzTooltipTitle]="tooltipUsuario" nzTooltipPlacement="right" nz-tooltip
                    >
                        <i class="fa-solid fa-info"></i>
                    </button>
                    <ng-template #tooltipUsuario>
                        <strong>Email:</strong> {{usuario?.email}}<br>
                        <strong>Perfil:</strong> {{usuario?.perfil}}<br>
                        <strong>Admin:</strong> {{usuario?.admin ? 'Sim' : 'Não'}}<br>
                        <strong>Origem:</strong> {{usuario?.origem}}<br>
                        <strong>Criado em:</strong> {{usuario?.created_at | date: 'dd/MM/yyyy HH:mm'}}<br>
                    </ng-template>
                </td>
                <td nzAlign="center">

                    <span>{{usuario?.login_data ? (usuario?.login_data | date:'dd/MM/yyyy') : 'Nunca'}}
                        <br><small *ngIf="usuario?.login_data">às {{usuario?.login_data | date:'HH:mm'}} </small>
                    </span>
                    <button *ngIf="usuario?.login_data" nz-button nzType="link"
                            style="position: absolute; top: 10px; right: 100px;"
                            [nzTooltipTitle]="tooltip" nzTooltipPlacement="right" nz-tooltip
                    >
                        <i class="fa-solid fa-info"></i>
                    </button>
                    <ng-template #tooltip>
                        <strong>Ambiente:</strong> {{usuario?.login_ambiente}}<br>
                        <strong>Tipo:</strong> {{usuario?.login_tipo}}<br>
                        <strong>MFA:</strong> {{usuario?.dois_fatores ? 'Sim' : 'Não'}}<br>
                    </ng-template>
                </td>
                <td nzAlign="center">
                    <button *ngIf="usuario?.login_data" nz-button nzType="link" [nz-tooltip]="'Últimos Logins'"
                            (click)="onClickHistoricoPeriodo(true, usuario)">
                        <i class="fa fa-history" aria-hidden="true"></i>
                    </button>
                </td>
            </tr>
            </tbody>
            <ng-template #rangeTemplate let-range="range" let-total>
                {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
            </ng-template>
        </nz-table>
    </nz-card>

</nz-content>


<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>

        <nz-skeleton *ngIf="loadings.combo"></nz-skeleton>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%"
              *ngIf="!loadings.combo">

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Período:</nz-form-label>
                <div nz-col [nzSpan]="14">
                    <div nz-row>
                        <nz-form-control nz-col [nzSpan]="11"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrar.formGroup.value.ultimoLoginDe">
                            <nz-date-picker formControlName="ultimoLoginDe" nzFormat="dd/MM/yyyy"
                                            nzPlaceHolder="De:" style="width: 100%;"
                            ></nz-date-picker>
                        </nz-form-control>
                        <nz-form-control nz-col [nzSpan]="11" nzOffset="2" class="text-right"
                                         nzValidateStatus="success"
                                         [nzHasFeedback]="formFiltrar.formGroup.value.ultimoLoginAte">
                            <nz-date-picker formControlName="ultimoLoginAte" nzFormat="dd/MM/yyyy"
                                            nzPlaceHolder="Até:" style="width: 100%;"></nz-date-picker>
                        </nz-form-control>
                    </div>
                </div>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Conta:</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.identificador">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="identificador">
                        <nz-option *ngFor="let opt of comboIdentificadores"
                                   [nzLabel]="opt.label" [nzValue]="opt.value" nzCustomContent>
                            {{opt.label}}
                            <br><small>({{opt.value}})</small>
                        </nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Já realizou login:</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.sem_login">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="sem_login">
                        <nz-option [nzLabel]="'Mostrar também os usuários que nunca efetuaram login'"
                                   [nzValue]="'tambem'"></nz-option>
                        <nz-option [nzLabel]="'Mostrar somente os usuários que nunca efetuaram login'"
                                   [nzValue]="'somente'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Usuários console:</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.usuario_console">
                    <nz-select nzShowSearch nzAllowClear
                               [nzPlaceHolder]="'Selecione'" formControlName="usuario_console">
                        <nz-option [nzLabel]="'Mostrar também os usuários console'" [nzValue]="'tambem'"></nz-option>
                        <nz-option [nzLabel]="'Mostrar somente os usuários console'" [nzValue]="'somente'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>


        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="visibles.modalHistorico"
          [nzTitle]="extraTituloModalHistorico" [nzClosable]="true"
          (nzOnCancel)="onClickHistoricoPeriodo(false)"
          [nzWidth]="948"
>
    <ng-template #extraTituloModalHistorico>
        <nz-space nzSize="middle" *ngIf="loadings.modalHistorico"
        >
            <nz-skeleton-element
                    *nzSpaceItem
                    nzType="input"
                    style="width:250px"
            ></nz-skeleton-element>
        </nz-space>
        <span *ngIf="!loadings.modalHistorico">Últimos Logins de <strong>{{labels.usuario_login_nome}}</strong></span>

    </ng-template>
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.modalHistorico"></nz-skeleton>

        <nz-table #basicTable [nzData]="arrayHistoricoUsuario"
                  [nzShowPagination]="false"
                  [nzFrontPagination]="false"
                  *ngIf="!loadings.modalHistorico"
                  [nzScroll]="{ y: '440px' }"
                  class="table-small" style="width: 100%;">
            <thead>
            <tr>
                <th>Conta</th>
                <th nzAlign="center">Horário</th>
                <th nzAlign="center">Dados</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of basicTable.data">
                <td>
                    {{data?.conta_nome}}
                    <br>
                    <small>({{data?.conta_identificador}})</small>
                </td>
                <td nzAlign="center">
                    <strong>às {{data.horario | date: 'HH:mm'}}</strong>
                    <br>{{data.horario | date: 'dd/MM/yyyy'}}</td>
                <td nzAlign="center">
                    <strong>Ambiente: </strong> {{data.login_ambiente}}
                    <br><strong>Tipo: </strong> {{data.login_tipo}}
                    <br><strong>MFA: </strong> {{data.login_mfa}}
                </td>
            </tr>
            </tbody>
        </nz-table>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="onClickHistoricoPeriodo(false);">Fechar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Exportar'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formExportar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.exportar" (click)="confirmaExportar()">Exportar
        </button>
    </div>
</nz-modal>
