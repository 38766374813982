import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Conta} from '@models/conta.model';
import {AbstractService} from './abstract.service';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ContaService extends AbstractService<Conta> {

    constructor(http: HttpClient) {
        super(http, '/contas', Conta);
    }

    cadastrarConta(dados: any): Observable<any> {

        dados.identificador = dados.identificador.toLowerCase();

        return this.http.post(`${environment.apiUrl}/conta`, dados);

    }

    exportExcel(filtros: any, search: string = null, tipo: string): any {

        filtros.tipoExportacao = tipo;

        const queryStr = this.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/contas/exportar${queryStr}`,
            {responseType: 'blob'});

    }

    alterarClassificacaoMassa(contas: any, classificacao: string): Observable<any> {

        return this.http.put(`${environment.apiUrl}/contas/alterarClassificacaoMassa`, {contas, classificacao});

    }

    retornaCreditosCaptcha(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/captcha/balance`);
    }

    comboVisibilidades(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/visibilidades/select`);
    }

    comboPlanos(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/planos/select`);
    }

}
