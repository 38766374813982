import {Directive} from '@angular/core';
import {Pagination} from '@models/pagination.model';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Utils} from '../../../shared/utils';
import {UntypedFormGroup} from '@angular/forms';
import {formataDatas} from '../../../shared/components-helper';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Directive()
export abstract class AbstractListTable<E> {

    items: E[] | any;
    checkedItems = new Set<string>();
    indeterminate = false;
    checked = false;
    pagination: Pagination;
    loading = true;
    currentParams: NzTableQueryParams;
    currentSearch: string;

    pageSize = 50;
    pageIndex = 1;

    cadastrando = false;

    formCadastrar: FormStack;
    formFiltrar: FormStack;
    formExportar: FormStack;

    qtdFiltrosAtivos = 0;

    expandedCard = false;

    currentPermissoes: any[] = [];

    protected constructor(
        public abstractService,
        public object,
        public toastrService
    ) {

        this.pagination = new Pagination();
    }

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigarório',
            email: 'Email inválido',
            cnpj: 'CNPJ inválido',
            uf: 'UF inválido',
            cep: 'CEP inválido',
        }
    };

    updateTable() {

        this.queryTable(this.currentParams, this.currentSearch);
    }

    cleanFilterParams(filter): string {
        Object.entries(filter).forEach(([key, value]) => {
            if (Utils.isEmpty(value)) {
                delete filter[key];
            }
        });
        return filter;
    }


    getGeneralSearch(generalSearch: string) {
        if (!Utils.isEmpty(generalSearch)) {

            this.currentSearch = generalSearch;
            this.updateTable();

        }
    }

    resetSearch() {
        this.queryTable({
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],

        });
    }

    // Metodos de checagem
    updateCheckedSet(id: string, checked: boolean): void {

        if (checked) {

            this.checkedItems.add(id);

        } else {

            this.checkedItems.delete(id);

        }
    }

    onItemChecked(id: string, checked: boolean): void {

        this.updateCheckedSet(id, checked);

    }

    onAllChecked(checked: boolean): void {

        this.items.filter(({disabled}) => !disabled).forEach(({id}) => this.updateCheckedSet(id, checked));

    }

    refreshCheckedStatus() {

        const listOfEnabledData = this.items.filter(({disabled}) => !disabled);
        this.checked = listOfEnabledData.every(({id}) => this.checkedItems.has(id));
        this.indeterminate = listOfEnabledData.some(({id}) => this.checkedItems.has(id)) && !this.checked;

    }

    resetCheckedSet() {

        this.checkedItems = new Set<string>();

    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;

        this.currentParams.filter = [];

        const filtros = this.formFiltrar.formGroup.value;

        for (let [chave, valor] of Object.entries(filtros)) {
            if (valor) {
                const val: any = valor;
                if (typeof val.getDate === 'function') {
                    valor = formataDatas(valor, 'AAAA-MM-DD');
                }
                this.currentParams.filter.push({key: chave, value: valor});
            }
        }

        this.countActiveFilters();

        this.loading = true;
        this.abstractService.listToTable(params, search).subscribe((response) => {

            this.items = [];
            this.items = response?.data || response;

            this.pagination = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.refreshCheckedStatus();

            this.loading = false;
        }, error => {
            this.loading = false;
            this.toastrService.error(error.error.message);
        });

    }

    countActiveFilters(): void {
        this.qtdFiltrosAtivos = 0;
        if (typeof this.formFiltrar !== 'undefined') {
            for (const [chave, valor] of Object.entries(this.formFiltrar.formGroup.value)) {
                if (valor) {
                    this.qtdFiltrosAtivos += 1;
                }
            }
        }
    }

    btnResetSearch() {

        this.currentSearch = '';
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.formFiltrar.formGroup.reset();

        this.queryTable(this.currentParams, this.currentSearch);

    }

    modalExportar(visible: boolean): void {
        this.formExportar.modalVisible = visible;
    }

    modalFiltrar(visible: boolean) {
        this.formFiltrar.modalVisible = visible;
    }

    modalCadastrar(visible) {
        this.formCadastrar.modalVisible = visible;
    }

    clickEvent() {
        this.expandedCard = !this.expandedCard;
    }

    confirmaFiltrar(): void {

        this.currentParams.pageIndex = 1;

        this.queryTable(this.currentParams, this.currentSearch);

        this.modalFiltrar(false);

    }

    hasPermissao(permissaoId: string, permissoes: any[]): boolean {

        return permissoes.indexOf(permissaoId) > -1;

    }

    getPermissoesPerfil(): void {
        this.abstractService.getPermissoesPerfil().subscribe((res) => {
            if (res.permissoes) {
                this.currentPermissoes = [];
                this.currentPermissoes = res.permissoes;
            }
        }, () => {
            this.toastrService.error('Problemas ao definir permissões');
        });
    }

    onSearchKeydown(event: any, value: string) {
        if (event.key === 'Enter') {
            this.currentSearch = value;
            this.queryTable(this.currentParams, this.currentSearch);
        }
    }


}
