import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractService} from '@services/abstract.service';
import {Conta} from '@models/conta.model';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})

export class ContasLimitesService extends AbstractService<Conta> {

  constructor(http: HttpClient) {
    super(http, '/monitoramento', {});
  }

  getContas(filtros?: any): Observable<any> {

    const queryStr = this.montaQueryString(filtros);

    return this.http.get(`${this.baseUrl}/relatorio/conta/tabela${queryStr}`);

  }


  atualizarUtilizados(): Observable<any> {

    return this.http.get(`${environment.apiUrl}/monitoramento/relatorio/conta/sumarizar`);

  }

  retornarSelectsModulos(): Observable<any> {

    return this.http.get(`${environment.apiUrl}/filtros/modulos/select`);

  }

  retornarSelectsContas(): Observable<any> {

    return this.http.get(`${environment.apiUrl}/filtros/contas/select`);

  }

}

