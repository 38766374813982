import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthenticationService} from '../auth/authentication.service';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {TabService} from '@services/tab.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService, private toastr: ToastrService,
                private router: Router, private tabservice: TabService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request)
            .pipe(
                catchError(response => {


                    if (response.status === 401) {
                        this.authenticationService.logout();
                    } else if (response.status === 500) {
                        this.toastr.error('Problemas ao obter dados do servidor!');
                        return [];
                    }

                    /*else if (response.status !== 422 && response.status !== 404) {
                        error.code = response.status;
                        this.tabservice.addTab(new Tab('ErrorComponent', 'Error ' + error.code,
                            '/error', UrlTypeEnum.nativo, { code: `${ error.code }` }));
                    }*/
                    this.handleErrors(response.error.errors);
                    return throwError(response);
                }));
    }

    handleErrors(errors: any): void {
        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                this.toastr.error(errors[key]);
            }
        }
    }
}
