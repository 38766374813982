<div class="nav-container">
    <div class="nav-item">
        <button (click)="register($event)" nz-button nzType="primary">Cadastrar
        </button>
    </div>

    <ng-content select="[menuOpcoes]"></ng-content>
    <div class="nav-item">
        <nz-input-group [nzAddOnAfter]="suffixIconButton" nzSearch>
            <input #generalSearch [(ngModel)]="generalFilter" nz-input placeholder="Busca" type="text"/>
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button (click)="search()" class="centralized-icon" nz-button nzSearch nzType="primary">
                <em nz-icon nzType="search"></em>
            </button>
        </ng-template>
    </div>
    <button (click)="reset()" nz-button nzType="default">Limpar</button>
    <ng-content select="[filtro]"></ng-content>
</div>
