<nz-page-header class="site-page-header" nzTitle="Detalhe do Módulo"
                nzSubtitle="Informações cadastrais do módulo"></nz-page-header>
<nz-content>
    <ng-template #indicatorTemplate>
        <i class="loading-icon" nz-icon nzType="loading"></i>
    </ng-template>
    <nz-spin [nzSpinning]="carregando" [nzSize]="'large'" nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
    <div nz-row [nzGutter]="16" *ngIf="!carregando">
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <nz-card [nzTitle]="modulo?.descricao" [nzExtra]="extraInfCadastrais">
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Identificador">
                            <nz-list-item-meta-title>
                                {{modulo?.identificador}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Criado em">
                            <nz-list-item-meta-title>
                                {{modulo?.created_at ? (modulo.created_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Última alteração">
                            <nz-list-item-meta-title>
                                {{modulo?.updated_at ? (modulo.updated_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </nz-card>
            <ng-template #extraInfCadastrais>
                <a nz-button nzType="link" (click)="editar(formDadosCadastrais)">Alterar</a>
            </ng-template>

        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18">

            <nz-card>

                <nz-card-tab>
                    <nz-tabset
                            [nzSize]="'large'"
                            [nzTabBarExtraContent]="extraPermissoes"
                            [(nzSelectedIndex)]="tabNumber"
                            (nzSelectChange)="changeTabs($event)">
                        <nz-tab nzTitle="Contas"></nz-tab>
                    </nz-tabset>
                </nz-card-tab>

                <nz-table #basicTable
                          nzSize="small"
                          [nzData]="contas"
                          [nzFrontPagination]="false"
                          [nzLoading]="loading"
                          [nzTotal]="pagination.contas?.total"
                          [nzPageSize]="pagination.contas?.per_page"
                          [nzPageIndex]="pagination.contas?.current_page"
                          *ngIf="tabNumber == 0"
                          [nzShowTotal]="rangeTemplateEmpresas"
                          class="fonte-doze table-small"
                          (nzQueryParams)="queryTable($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="identificador" [nzSortFn]="true">Identificador</th>
                        <th nzColumnKey="nome" [nzSortFn]="true">Nome</th>
                        <th nzColumnKey="tipo" [nzSortFn]="true">Tipo</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let conta of basicTable.data">
                        <td nzLeft>

                            <a nz-button nzType="link" (click)="openTab('/conta/detalhe', conta.id, {id: conta.id})">
                                {{conta?.nome}}
                            </a>
                        </td>
                        <td nzLeft>{{conta?.identificador ? conta?.identificador : '-'}}</td>
                        <td>{{conta?.tipo}}</td>
                    </tr>
                    </tbody>
                </nz-table>
                <ng-template #rangeTemplateEmpresas let-range="range" let-total>
                    {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                </ng-template>

            </nz-card>
            <ng-template #extraPermissoes>
                <div class="nav-item d-flex">

                    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                        <input type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
                    </nz-input-group>
                    <ng-template #suffixIconButton>
                        <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                            <i nz-icon nzType="search"></i></button>
                    </ng-template>
                    <button nz-button nzType="default" style="margin-right: 10px;"
                            (click)="btnResetSearch();">
                        Limpar
                    </button>
                </div>


            </ng-template>
        </div>
    </div>
</nz-content>

<nz-modal [(nzVisible)]="formDadosCadastrais.modalVisible" [nzTitle]="'Alterar'" [nzClosable]="true"
          (nzOnCancel)="fechar(formDadosCadastrais)">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formDadosCadastrais.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Identificador</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="identificador" size="60">
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Descrição</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="descricao" size="60">
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Unidade</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="unidade" size="60">
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="8">Critério</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input type="text" formControlName="criterio" size="60">
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formDadosCadastrais)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formDadosCadastrais)">Confirmar
        </button>
    </div>
</nz-modal>
