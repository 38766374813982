import {componentsConfig} from './constants/componentsConfig.const';

export function getComponentConfigByName(componentName: string) {
    for (const key in componentsConfig) {
        if (componentsConfig[key].name === componentName) {
            return componentsConfig[key];
        }
    }
    return null;
}

export function urlHasParams(component: any) {
    return component.url.includes('/:id');
}

export function buildUrl(component: any, params?: string): string {
    return urlHasParams(component) ? component.url.replace(':id', params) : component.url;
}


export function findComponentByUrl(url: string) {
    for (const key in componentsConfig) {
        const baseUrl = componentsConfig[key].url.replace('/:id', '');
        if (baseUrl && url === baseUrl) {
            return componentsConfig[key];
        }
    }
    return null;
}

export function formataDatas(date: any, formato: string = 'AAAA-MM-DD HH:MM:SS'): string {
    switch (formato.toUpperCase()) {
        case 'AAAA-MM-DD HH:MM:SS': {
            return date.toISOString().slice(0, 19).replace('T', ' ');
        }
        case 'AAAA-MM-DD': {
            return date.toISOString().slice(0, 10);
        }
    }

    return null;

}
