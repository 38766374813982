import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TabService } from '@services/tab.service';

interface Error {
    code: string;
    message: string;
}

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

    @Input() data;
    readonly errors = [{ code: '404', message: 'Desculpe, não econtramos a página solicitada.' }, {
        code: '403',
        message: 'Desculpe, mas você não tem permissão para acessar esta página'
    }, { code: '500', message: 'Ops.. Ocorreu um erro inesperado em nosso servidor, pedimos desculpas.' },];
    error: Error;

    constructor(private activatedRoute: ActivatedRoute, private router: Router, private tabService: TabService) {
        this.error = { code: '404', message: 'Desculpe, não econtramos a página solicitada. ' };
    }

    ngOnInit(): void {
        if (this.data && this.data.code) {
            this.error = this.errors.find(item => item.code === this.data.code);
        }
    }

    goBack() {
        this.tabService.closeCurrentTab();
    }
}
