import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractService} from '@services/abstract.service';
import {Perfil} from '@models/perfil.model';
import {environment} from '../../../../../environments/environment';

@Injectable({providedIn: 'root'})


export class ModuloDetalheService extends AbstractService<Perfil> {

    constructor(http: HttpClient) {
        super(http, '/administracao/modulos', {});
    }

    retornaModulo(id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/administracao/modulos/detalhe/${id}`);

    }

    atualizar(id: string, campos: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/administracao/modulos/detalhe/${id}`, campos);

    }

}
