import { Injectable } from '@angular/core';
import { TabService } from '@services/tab.service';
import { Tab } from '@models/tab.model';
import { select, Store } from '@ngrx/store';
import { Utils } from '../../shared/utils';
import { findComponentByUrl, urlHasParams } from '../../shared/components-helper';

@Injectable({
    providedIn: 'root'
})
export class UrlHelperService {
    private tabs: Tab[];

    constructor(private tabService: TabService, private store: Store) {
        // @ts-ignore
        this.store.pipe(select('tabs')).subscribe((tabs: Tab[]) => {
            this.tabs = tabs;
        });
    }

    checkUrl(url: string): string {
        if (!this.exists(url)) {
            const component = findComponentByUrl(url);
            if (component && url !== '/') {
                const data = urlHasParams(component) ? { id: this.extractId(url) } : {};
                const newTab = new Tab(component.name, component.title, url, component.urlType, data);
                this.tabService.addTab(newTab);
                return null;
            }
        } else {
            this.tabService.setCurrentTabIndex = this.tabs.findIndex((tab) => tab.url === url);
        }
        return;
    }

    extractId(url: string) {
        const splittedUrl = url.split('/');
        return splittedUrl[splittedUrl.length - 1];
    }


    exists(url: string) {
        let exists = true;
        const matchUrl = this.tabs.find(tab => tab.url === url);
        if (Utils.isEmpty(matchUrl) && url !== '/home' && url !== '/') {
            exists = false;
            const componentFound = findComponentByUrl(url);
            if (componentFound && componentFound.name !== 'ErrorComponent') {
                const splittedUrl = url.split('/');
                const splittedTabUrl = componentFound.url.split('/');
                if (urlHasParams(componentFound)) {
                    if (splittedUrl[splittedUrl.length - 1] === splittedTabUrl[splittedTabUrl.length - 1]) {
                        exists = true;
                    }
                }
            }
        }
        return exists;
    }
}
