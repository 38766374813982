<nz-page-header class="site-page-header" nzTitle="Administração" nzSubtitle="Créditos"></nz-page-header>
<nz-content>
    <nz-card class="fonte-doze" [nzExtra]="navBar" nzTitle="Créditos"
             [nzBodyStyle]="loading ? {'padding':'15px'} : {'padding':'0px'}"
             [nzLoading]="loading"
             [ngClass]="expandedCard ? 'card-fullscreen' : ''">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <button nz-button nzType="primary"
                        style="margin-right: 10px;"
                        (click)="modalCadastrar(true);">
                    Cadastrar
                </button>
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!expandedCard ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  [nzData]="items"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  class="fonte-doze table-small"
                  [nzShowTotal]="rangeTemplate">
            <thead>
            <tr>
                <th nzColumnKey="contexto" [nzSortFn]="true" nzAlign="center">Contexto</th>
                <th nzColumnKey="descricao" [nzSortFn]="true" nzAlign="center">Descrição</th>
                <th nzColumnKey="saldo" [nzSortFn]="true" nzAlign="center">Saldo</th>
                <th nzColumnKey="contas" [nzSortFn]="true" nzAlign="center">Contas</th>
                <th nzColumnKey="created_at" [nzSortFn]="true" nzAlign="center">Cadastro Data</th>
                <th [ngStyle]="{'width' : '80px'}"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTable.data">
                <td nzAlign="center">
                    <a nz-button nzType="link" style="word-break: break-word">{{item.contexto}}</a>
                </td>
                <td nzAlign="center">{{item.descricao}}</td>
                <td nzAlign="center" style="word-break: break-word">{{item.saldo}}</td>
                <td nzAlign="center">{{item.contas}}</td>
                <td nzAlign="center">{{item.created_at | date:'dd/MM/YY - HH:mm:ss'}}</td>
                <td nzAlign="right" style="white-space: nowrap;">
                    <button nz-button
                            nzType="link"
                            (click)="modalEditar(true, item.id)">
                        Editar
                    </button>
                    <nz-divider nzType="vertical"></nz-divider>
                    <button nz-button
                            (click)="showConfirmRemover(item.id)"
                            nzType="link" [nzLoading]="loadings.removendo[item.id]">
                        Remover
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplate let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </nz-card>
</nz-content>

<nz-modal [(nzVisible)]="formCadastrar.modalVisible" [nzTitle]="'Cadastrar Crédito'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formCadastrar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="4" nzRequired>Contexto</nz-form-label>
                <nz-form-control [nzSpan]="16">
                    <input nz-input type="text" formControlName="contexto" placeholder="Contexto">
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="4" nzRequired>Descrição</nz-form-label>
                <nz-form-control [nzSpan]="16">
                    <input nz-input type="text" formControlName="descricao" placeholder="Descricao">
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="4" nzRequired>Saldo</nz-form-label>
                <nz-form-control [nzSpan]="16">
                    <input nz-input type="number" min="1" step="1" formControlName="saldo" placeholder="Saldo">
                </nz-form-control>
            </nz-form-item>
</form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.cadastrando" (click)="confirmaCadastrar()">Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Descrição</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.descricao !== '' && formFiltrar.formGroup.value.descricao !== null">
                    <input nz-input type="text" formControlName="descricao" placeholder="Descricao"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formEditar.modalVisible" [nzTitle]="'Editar'" [nzClosable]="true"
          (nzOnCancel)="modalEditar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <nz-skeleton *ngIf="loadings.detalhe"></nz-skeleton>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formEditar.formGroup"
              style="width: 100%;" *ngIf="!loadings.detalhe">
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Descrição</nz-form-label>
                <nz-form-control [nzSpan]="14" [nzErrorTip]="'Campo obrigatório'">
                    <input nz-input type="text" formControlName="descricao" placeholder="Descricao"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5" nzRequired>Saldo</nz-form-label>
                <nz-form-control [nzSpan]="14"  [nzErrorTip]="'Campo obrigatório'">
                    <input nz-input type="number" min="1" step="1" formControlName="saldo" placeholder="Saldo">
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalEditar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.salvando" (click)="confirmaEditar()">Confirmar</button>
    </div>
</nz-modal>

