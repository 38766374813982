import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {HttpClient} from '@angular/common/http';
import {Perfil} from '@models/perfil.model';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class PerfilService extends AbstractService<Perfil> {

    constructor(http: HttpClient) {
        super(http, '/administracao/perfis', Perfil);
    }

    save(campos): Observable<any> {

        return this.http.post(`${environment.apiUrl}/administracao/perfil`, campos);

    }
}
