<nz-page-header class="site-page-header" nzTitle="Meu Usuário"
                nzSubtitle="Suas informações cadastrais e de segurança no sistema">
</nz-page-header>
<nz-content>
    <ng-template #indicatorTemplate>
        <i class="loading-icon" nz-icon nzType="loading"></i>
    </ng-template>
    <nz-spin [nzSpinning]="carregando" [nzSize]="'large'" nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>

    <div nz-row [nzGutter]="16" *ngIf="!carregando">

        <div nz-col nzXs="24" nzSm="24" nzMd="8" nzLg="6" nzXl="6">
            <nz-affix>
                <nz-card>
                    <div nz-row>
                        <nz-space [nzSize]="24">
                            <img *nzSpaceItem nz-image class="imagem-avatar" [nzSrc]="usuarioLogado.srcAvatar"
                                 alt="{{usuarioLogado.nome}}"
                                 [nzFallback]="fallbackAvatar">
                            <nz-card-meta style="word-break: break-word;" *nzSpaceItem [nzTitle]="usuarioLogado.nome"
                                          [nzDescription]="usuarioLogado.departamento ? usuarioLogado.departamento : usuarioLogado.email">
                            </nz-card-meta>
                        </nz-space>
                    </div>
                    <nz-divider></nz-divider>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Último login">
                                <nz-list-item-meta-title>
                                    {{usuarioLogado.ultimoLoginData ? (usuarioLogado.ultimoLoginData | date:'dd/MM/YY - HH:mm:ss') : 'nunca realizado' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Criado em">
                                <nz-list-item-meta-title>
                                    {{usuarioLogado.created_at ? (usuarioLogado.created_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                    <nz-list>
                        <nz-list-item>
                            <nz-list-item-meta nzDescription="Última alteração">
                                <nz-list-item-meta-title>
                                    {{usuarioLogado.updated_at ? (usuarioLogado.updated_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                                </nz-list-item-meta-title>
                            </nz-list-item-meta>
                        </nz-list-item>
                    </nz-list>
                </nz-card>
            </nz-affix>
            <br/>
            <ng-template #avatarTemplate>
                <nz-avatar [nzText]="(usuarioLogado.primeiroNome | slice:0:1) + (usuarioLogado.sobreNome | slice:0:1)"
                           nzSize="large"></nz-avatar>
            </ng-template>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="16" nzLg="18" nzXl="18">

            <nz-card id="informacoes-pessoais" nzTitle="Informações Pessoais" [nzExtra]="extraInfPessoais">
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Nome">
                            <nz-list-item-meta-title>
                                {{usuarioLogado.nome}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="CPF">
                            <nz-list-item-meta-title>
                                {{usuarioLogado.cpf ? (usuarioLogado.cpf | cpfCnpj) : '-'}}
                                <span *ngIf="loginCpf" nz-tooltip
                                      nzTooltipTitle="O CPF é utilizado como login do usuário"><i
                                        class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Data de Nascimento">
                            <nz-list-item-meta-title>
                                {{usuarioLogado.nascimentoData ? (usuarioLogado.nascimentoData  | date:'dd/MM/YYYY') : "-"}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Departamento">
                            <nz-list-item-meta-title>
                                {{usuarioLogado.departamento ? usuarioLogado.departamento : "-"}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </nz-card>

            <nz-card id="configuracoesGerais" nzTitle="Configurações Gerais">
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Avatar">
                            <nz-list-item-meta-title>
                                <img nz-image class="imagem-avatar-grid" [nzSrc]="usuarioLogado.srcAvatar"
                                     [nzFallback]="fallbackAvatar" alt="{{usuarioLogado.nome}}">
                                {{usuarioLogado.srcAvatar ? usuarioLogado.id + '.png' : 'Nenhum avatar associado ao usuário'}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="modalAlterarAvatar(true)"
                                        [nzLoading]="loadings.alterandoAvatar">Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>
                </nz-list>
            </nz-card>


            <ng-template #extraInfPessoais>
                <button nz-button nzType="primary" (click)="editar(formDadosPessoais)">Alterar</button>
            </ng-template>
            <br/>
            <nz-card id="contato" nzTitle="Informações de Contato">
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Email">
                            <nz-list-item-meta-title>
                                {{usuarioLogado.email}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="editar(formEmail)">Alterar</button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Telefone">
                            <nz-list-item-meta-title>
                                {{usuarioLogado.telefone | telefone}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="editar(formTelefone)">Alterar</button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>
                </nz-list>
            </nz-card>
            <br/>
            <nz-card id="seguranca" nzTitle="Segurança">
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Login">
                            <nz-list-item-meta-title>
                                {{usuarioLogado.login}}
                                <span *ngIf="loginCpf" nz-tooltip
                                      nzTooltipTitle="O CPF deverá ser utilizado como login do usuário"><i
                                        class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="editar(formLogin)">Alterar</button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Senha">
                            <nz-list-item-meta-title>
                                Última alteração em {{usuarioLogado.senhaAlteracaoData | date:'dd/MM/YY - HH:mm:ss'}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="editar(formSenha)">Alterar</button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Token">
                            <nz-list-item-meta-title>
                                {{usuarioLogado.token ? usuarioLogado.token : '-'}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button [nzLoading]="loadings.gerarToken" nz-button nzType="primary"
                                        (click)="gerarToken()">Alterar
                                </button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Tempo de sessão">
                            <nz-list-item-meta-title>
                                {{usuarioLogado.tempoSessao}} horas
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                        <ul nz-list-item-actions>
                            <nz-list-item-action>
                                <button nz-button nzType="primary" (click)="editar(formTempoSessao)">Alterar</button>
                            </nz-list-item-action>
                        </ul>
                    </nz-list-item>
                </nz-list>
            </nz-card>

        </div>
    </div>
</nz-content>

<nz-modal [(nzVisible)]="formDadosPessoais.modalVisible" [nzTitle]="'Alterar Dados Pessoais'" [nzClosable]="true"
          (nzOnCancel)="fechar(formDadosPessoais)">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formDadosPessoais.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzFor="nome">Nome/Sobrenome</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <div nz-row [nzGutter]="8">
                        <div nz-col [nzSpan]="12">
                            <input nz-input type="text" formControlName="primeiroNome" placeholder="Nome"/>
                        </div>
                        <div nz-col [nzSpan]="12">
                            <input nz-input type="text" formControlName="sobreNome" placeholder="Sobrenome"/>
                        </div>
                    </div>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>CPF</nz-form-label>
                <nz-form-control [nzSpan]="15" nzErrorTip="CPF Obrigatório">
                    <input nz-input type="text" formControlName="cpf" mask="000.000.000-00" size="14"/>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Data de Nascimento</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-date-picker formControlName="nascimentoData" nzFormat="dd/MM/yyyy"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="8">Departamento</nz-form-label>
                <nz-form-control [nzSpan]="15" nzErrorTip="Seu Departamento">
                    <input nz-input type="text" formControlName="departamento" size="60"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formDadosPessoais)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formDadosPessoais)">Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formEmail.modalVisible" [nzTitle]="'Alterar Email'" [nzClosable]="true"
          (nzOnCancel)="fechar(formEmail)">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formEmail.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzRequired>Email</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <input nz-input formControlName="email" placeholder="informe o seu Email" type="email" size="60"
                           maxlength="60"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formEmail)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formEmail)">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formTelefone.modalVisible" [nzTitle]="'Alterar Telefone'" [nzClosable]="true"
          (nzOnCancel)="fechar(formTelefone)">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formTelefone.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="6">Telefone</nz-form-label>
                <nz-form-control [nzSpan]="15" nzErrorTip="Informe seu Telefone">
                    <input nz-input type="text" formControlName="telefone" mask="(00) 00000-0000" size="60"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formTelefone)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formTelefone)">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formLogin.modalVisible" [nzTitle]="'Alterar Login'" [nzClosable]="true"
          (nzOnCancel)="fechar(formLogin)">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formLogin.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="6" nzRequired
                               nzTooltipTitle="O CPF deverá ser utilizado como login do usuário">Login
                </nz-form-label>
                <nz-form-control [nzSpan]="16">
                    <input nz-input formControlName="login" placeholder="Login do usuário" autocomplete="off" size="60"
                           maxlength="20"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formLogin)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formLogin)">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formSenha.modalVisible" [nzTitle]="'Alterar Senha'" [nzClosable]="true"
          (nzOnCancel)="fechar(formSenha)">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formSenha.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>Senha</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-input-group [nzSuffix]="showPassword">
                        <input nz-input
                               [type]="passwordVisible ? 'text' : 'password'"
                               formControlName="senha"
                               placeholder="Senha"
                               size="60"
                               maxlength="60"
                               nz-popover
                               nzPopoverTrigger="focus"
                               nzPopoverPlacement="right"
                               [nzPopoverTitle]="validadePasswordTitle"
                               [nzPopoverContent]="validadePasswordContent"
                               (ngModelChange)="verificaSenha($event, true)"/>
                    </nz-input-group>
                </nz-form-control>
                <ng-template #showPassword>
                    <span nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                          (click)="passwordVisible = !passwordVisible" style="cursor: pointer;"></span>
                </ng-template>
                <ng-template #validadePasswordTitle>
                    <h5 nz-typography style="margin: 0;">
                        <i class="fa-solid fa-key" style="margin-right: 3px;"></i> Segurança da Senha
                    </h5>
                </ng-template>
                <ng-template #validadePasswordContent>
                    <div [ngStyle]="passwordValidate.length && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.length"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.length" style="margin-right: 3px;"></span>
                        <span>A senha deve conter entre 8 e 30 caracteres.</span>
                    </div>
                    <div [ngStyle]="passwordValidate.lowercase && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.lowercase"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.lowercase"
                              style="margin-right: 3px;"></span>
                        <span>A senha deve conter ao menos uma letra minúscula.</span>
                    </div>
                    <div [ngStyle]="passwordValidate.uppercase && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.uppercase"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.uppercase"
                              style="margin-right: 3px;"></span>
                        <span>A senha deve conter ao menos uma letra maiúscula.</span>
                    </div>
                    <div [ngStyle]="passwordValidate.number && {'color': 'green'}">
                        <span nz-icon nzType="close" nzTheme="outline" *ngIf="!passwordValidate.number"
                              style="margin-right: 3px;"></span>
                        <span nz-icon nzType="check" *ngIf="passwordValidate.number" style="margin-right: 3px;"></span>
                        <span>A senha deve conter ao menos um número.</span>
                    </div>
                </ng-template>
            </nz-form-item>
            <nz-form-item [ngClass]="[!this.passwordValidate.confirm ? 'ant-form-item-with-help' : '']">
                <nz-form-label [nzSpan]="7" nzRequired>Confirma</nz-form-label>
                <nz-form-control [nzSpan]="15">
                    <nz-input-group [nzSuffix]="showPasswordConfirm">
                        <input nz-input
                               [type]="passwordConfirmVisible ? 'text' : 'password'"
                               formControlName="confirmaSenha"
                               placeholder="Confirme a senha"
                               (ngModelChange)="verificaConfirmSenha($event)"/>
                    </nz-input-group>
                    <div *ngIf="!this.passwordValidate.confirm"
                         class="ant-form-item-explain ant-form-item-explain-error ng-star-inserted">
                        <div class="ng-trigger ng-trigger-helpMotion">As senhas não coincidem</div>
                    </div>
                </nz-form-control>
                <ng-template #showPasswordConfirm>
                    <span nz-icon [nzType]="passwordConfirmVisible ? 'eye-invisible' : 'eye'"
                          (click)="passwordConfirmVisible = !passwordConfirmVisible" style="cursor: pointer;"></span>
                </ng-template>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formSenha)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formSenha)">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalAlterarAvatarVisible" [nzTitle]="'Alterar Avatar'"
          [nzClosable]="true"
          (nzOnCancel)="modalAlterarAvatar(false)" [nzWidth]="648">

    <div nz-row *nzModalContent style="text-align: center;">

        <nz-form-label [nzSpan]="24">Selecione uma imagem (.jpeg/.png) para upload.</nz-form-label>

        <nz-form-item>
            <nz-form-control [nzSpan]="24">
                <input type="file" (change)="fileChangeEventAvatar($event)" accept=".png, .jpg, .jpeg">
            </nz-form-control>
        </nz-form-item>
        <image-cropper
                [imageChangedEvent]="imageChangedEventAvatar"
                [maintainAspectRatio]="true"
                [aspectRatio]="4 / 4"
                format="png"
                (imageCropped)="imageCroppedAvatar($event)"
                [containWithinAspectRatio]="true"
                [roundCropper]="true"
                [resizeToWidth]="150"
                style="width: 60%; margin-left: 20%;"
        ></image-cropper>

    </div>
    <div *nzModalFooter>
        <button class="new-markup-bnt" nz-button nzType="link"
                (click)="showConfirmRemoverAvatar()">
            <em nz-icon nzType="close-circle" style="color: red"></em><span style="color: red">Remover avatar</span>
        </button>
        <button nz-button nzType="link" (click)="modalAlterarAvatar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.alterandoAvatar" (click)="consfirmarAlterarAvatar()">
            Confirmar
        </button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formTempoSessao.modalVisible" [nzTitle]="'Alterar Tempo de Sessão'" [nzClosable]="true"
          (nzOnCancel)="fechar(formTempoSessao)">
    <div *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formTempoSessao.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="8" nzRequired>Tempo de sessão</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-input-number formControlName="tempoSessao" [nzMin]="1" [nzMax]="99" [nzStep]="1"></nz-input-number>

                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formTempoSessao)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formTempoSessao)">Confirmar</button>
    </div>
</nz-modal>
