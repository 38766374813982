<nz-page-header class="site-page-header" nzTitle="Detalhe do Aviso"
                nzSubtitle="Informações cadastrais do aviso"></nz-page-header>
<nz-content>
    <ng-template #indicatorTemplate>
        <i class="loading-icon" nz-icon nzType="loading"></i>
    </ng-template>
    <nz-spin [nzSpinning]="carregando" [nzSize]="'large'" nzSimple [nzIndicator]="indicatorTemplate"></nz-spin>
    <div nz-row [nzGutter]="16" *ngIf="!carregando">
        <div nz-col nzXs="24" nzSm="24" nzMd="6" nzLg="6" nzXl="6">
            <nz-card [nzTitle]="aviso?.titulo" [nzExtra]="extraInfCadastrais">

                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Criado em">
                            <nz-list-item-meta-title>
                                {{aviso?.created_at ? (aviso.created_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Última alteração">
                            <nz-list-item-meta-title>
                                {{aviso?.updated_at ? (aviso.updated_at | date:'dd/MM/YY - HH:mm:ss') : '-' }}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                </nz-list>
            </nz-card>
            <ng-template #extraInfCadastrais>

            </ng-template>

        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="18" nzLg="18" nzXl="18">

            <nz-card id="informacoes-pessoais" nzTitle="Informações do Aviso" [nzExtra]="extraInfPessoais">
                <nz-list>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Tipo">
                            <nz-list-item-meta-title>
                                {{aviso?.tipo}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Status">
                            <nz-list-item-meta-title>
                                {{aviso?.ativo ? 'Ativo' : 'Inativo'}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Período">
                            <nz-list-item-meta-title>
                                De: {{aviso.dataInicio | date:'dd/MM/YY - HH:mm:ss'}}
                                Até: {{aviso.dataFim | date:'dd/MM/YY - HH:mm:ss'}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>
                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Título">
                            <nz-list-item-meta-title>
                                {{aviso?.titulo}}
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>

                    <nz-list-item>
                        <nz-list-item-meta nzDescription="Mensagem">
                            <nz-list-item-meta-title>
                                <div [innerHTML]="aviso?.mensagem"></div>
                            </nz-list-item-meta-title>
                        </nz-list-item-meta>
                    </nz-list-item>

                </nz-list>

                <ng-template #extraInfPessoais>

                    <button nz-button nzType="link" style="margin-right: 15px;"
                            (click)="preview(aviso?.titulo, aviso?.mensagem, aviso?.tipo)">
                        <i nz-icon nzType="eye" nzTheme="outline"></i>Preview
                    </button>

                    <button nz-button
                            nzType="primary"
                            (click)="editar()">
                        Alterar
                    </button>
                </ng-template>

            </nz-card>


            <nz-card id="contas" nzTitle="Contas" [nzExtra]="extraContas">

                <nz-table #basicTable
                          nzSize="small"
                          [nzData]="contas"
                          [nzFrontPagination]="false"
                          [nzLoading]="loading"
                          [nzTotal]="pagination.contas?.total"
                          [nzPageSize]="pagination.contas?.per_page"
                          [nzPageIndex]="pagination.contas?.current_page"
                          [nzShowTotal]="rangeTemplateEmpresas"
                          class="fonte-doze table-small"
                          (nzQueryParams)="queryTable($event)">
                    <thead>
                    <tr>
                        <th nzColumnKey="identificador" [nzSortFn]="true">Nome</th>
                        <th nzColumnKey="nome" [nzSortFn]="true">Identificador</th>
                        <th nzColumnKey="tipo" [nzSortFn]="true">Tipo</th>
                        <th [ngStyle]="{'width' : '80px'}"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let conta of basicTable.data">
                        <td nzLeft>

                            <a nz-button nzType="link" (click)="openTab('/conta/detalhe', conta.id, {id: conta.id})">
                                {{conta?.nome}}
                            </a>
                        </td>
                        <td nzLeft>{{conta?.identificador ? conta?.identificador : '-'}}</td>
                        <td>{{conta?.tipo}}</td>
                        <td>
                            <button nz-button nzType="link"
                                    (click)="showConfirmRemover(conta?.identificador)">
                                Remover
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </nz-table>
                <ng-template #rangeTemplateEmpresas let-range="range" let-total>
                    {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                </ng-template>

            </nz-card>
            <ng-template #extraContas>
                <div class="nav-item d-flex">
                    <button nz-button style="margin-right: 10px;"
                            nzType="primary"
                            (click)="adicionarConta()">
                        Adicionar
                    </button>

                    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                        <input type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch">
                    </nz-input-group>
                    <ng-template #suffixIconButton>
                        <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                            <i nz-icon nzType="search"></i></button>
                    </ng-template>
                    <button nz-button nzType="default" style="margin-right: 10px;"
                            (click)="btnResetSearch();">
                        Limpar
                    </button>
                </div>


            </ng-template>
        </div>
    </div>
</nz-content>

<nz-modal [(nzVisible)]="formDadosCadastrais.modalVisible" [nzTitle]="'Alterar Descrição'" [nzClosable]="true"
          (nzOnCancel)="fechar(formDadosCadastrais)" [nzWidth]="800">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formDadosCadastrais.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="3" nzRequired>Tipo</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <nz-select formControlName="tipo" [nzPlaceHolder]="'Selecione'">
                        <nz-option [nzLabel]="'Sucesso'" [nzValue]="'success'"></nz-option>
                        <nz-option [nzLabel]="'Informação'" [nzValue]="'info'"></nz-option>
                        <nz-option [nzLabel]="'Aviso'" [nzValue]="'warning'"></nz-option>
                        <nz-option [nzLabel]="'Erro'" [nzValue]="'Erro'"></nz-option>
                    </nz-select>
                </nz-form-control>
                <nz-form-label [nzSpan]="2" [nzOffset]="2" nzRequired>Status</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <nz-select formControlName="ativo" [nzPlaceHolder]="'Selecione'" nzAllowClear>
                        <nz-option [nzLabel]="'Ativo'" [nzValue]="1"></nz-option>
                        <nz-option [nzLabel]="'Inativo'" [nzValue]="0"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="3" nzRequired>Data Início</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <nz-date-picker formControlName="dataInicio" nzFormat="dd/MM/yyyy HH:mm:ss"
                                    nzShowTime
                                    nzPlaceHolder="De:"
                    ></nz-date-picker>
                </nz-form-control>
                <nz-form-label [nzSpan]="4" [nzOffset]="0" nzRequired>Data Fim</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <nz-date-picker formControlName="dataFim" nzFormat="dd/MM/yyyy HH:mm:ss"
                                    nzShowTime
                                    nzPlaceHolder="Até:"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="3" nzRequired>Título</nz-form-label>
                <nz-form-control [nzSpan]="18">
                    <input nz-input formControlName="titulo" placeholder="Informe o título" type="text">
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="3" nzRequired>Mensagem</nz-form-label>
                <nz-form-control [nzSpan]="18">
                    <textarea formControlName="mensagem" nz-input rows="15" placeholder="Informe a mensagem"></textarea>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button  class="new-markup-bnt" nz-button nzType="link"
        (click)="preview(formDadosCadastrais.formGroup.value.titulo,formDadosCadastrais.formGroup.value.mensagem, formDadosCadastrais.formGroup.value.tipo)"
        >
            <i nz-icon nzType="eye" nzTheme="outline"></i>Preview
        </button>
        <button nz-button nzType="link" (click)="fechar(formDadosCadastrais)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmar(formDadosCadastrais)">Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formAdicionarConta.modalVisible" [nzTitle]="'Adicionar Contas'" [nzClosable]="true"
          (nzOnCancel)="fechar(formAdicionarConta)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzAutoTips]="autoTips" [nzNoColon]="true" [formGroup]="formAdicionarConta.formGroup"
              style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="3" nzRequired>Contas</nz-form-label>
                <nz-form-control [nzSpan]="18">
                    <nz-select formControlName="contas" [nzPlaceHolder]="'Selecione'" nzMode="multiple">
                        <nz-option *ngFor="let option of comboContas" [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formAdicionarConta)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="gravando" (click)="confirmarAddContas()">Confirmar
        </button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="modalPreviewVisible" [nzTitle]="'Preview do aviso'" [nzClosable]="true"
          (nzOnCancel)="modalPreview(false)" [nzWidth]="800">
    <div nz-row *nzModalContent>
        <div style="width: 100%;" [innerHTML]="avisoPreview?.mensagem" class="alert-{{avisoPreview?.tipo}}"></div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalPreview(false)">Fechar</button>

    </div>
</nz-modal>
