<nz-page-header class="site-page-header" nzTitle="Administração" nzSubtitle="Planos"></nz-page-header>
<nz-content>
    <nz-card [nzExtra]="navBar" nzTitle="Planos"
             [ngClass]="expandedCard ? 'card-fullscreen' : ''"
             [nzLoading]="loading" [nzBodyStyle]="{'padding': loading ? '15px' : '0px'}">
        <ng-template #navBar>
            <div class="nav-item d-flex">
                <button nz-button nzType="primary"
                        style="margin-right: 10px;"
                        (click)="modalCadastrar(true);"
                        *ngIf="hasPermissao(screenPermissoes.cadastrar, currentPermissoes)">
                    Cadastrar
                </button>
                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button class="centralized-icon" [nzDropdownMenu]="menuAcoes"
                        [nzType]="'default'" nz-button nz-dropdown style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar Planilha</li>
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!expandedCard ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>
        <nz-alert nzType="info" nzShowIcon [nzMessage]="checkedMessage" style="margin: 15px !important;"
                  *ngIf="checkedItems.size > 0">
            <ng-template #checkedMessage>
                <div nz-row nzAlign="middle" nzGutter="24">
                    <div nz-col nzSpan="24">
                        <strong class="text-primary"></strong>
                        {{ checkedItems.size }} Registros Selecionados
                    </div>
                </div>
            </ng-template>
        </nz-alert>
        <nz-table #basicTable
                  [nzData]="items"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  (nzQueryParams)="queryTable($event)"
                  [nzFrontPagination]="false"
                  class="fonte-doze table-small"
                  [nzShowTotal]="rangeTemplate">
            <thead>
            <tr>
                <th nzColumnKey="descricao" [nzSortFn]="true" nzAlign="left">Descrição</th>
                <th nzColumnKey="contas" [nzSortFn]="true" nzAlign="center">Contas</th>
                <th nzColumnKey="created_at" [nzSortFn]="true" nzAlign="center">Cadastro Data</th>
                <th [ngStyle]="{'width' : '80px'}"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of basicTable.data">
                <td>
                    <a nz-button nzType="link"
                       (click)="openTab('/administracao/planos/plano-detalhe', item.id, {id: item.id})">{{item?.descricao}}</a>
                </td>
                <td nzAlign="center">{{item?.contas}}</td>
                <td nzAlign="center">{{item?.created_at | date:'dd/MM/YY - HH:mm:ss'}}</td>
                <td nzAlign="right" style="white-space: nowrap;">
                    <button nz-button
                            nzType="link"
                            (click)="openTab('/administracao/planos/plano-detalhe', item.id, {id: item.id})">
                        Editar
                    </button>
                    <nz-divider nzType="vertical"></nz-divider>
                    <button nz-button
                            (click)="showConfirmRemover(item.id)"
                            nzType="link"
                            *ngIf="hasPermissao(screenPermissoes.remover, currentPermissoes)">
                        Remover
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplate let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </nz-card>
</nz-content>

<nz-modal [(nzVisible)]="formCadastrar.modalVisible" [nzTitle]="'Cadastrar Novo Plano'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formCadastrar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="4" nzRequired>Descrição</nz-form-label>
                <nz-form-control [nzSpan]="16">
                    <input nz-input type="text" formControlName="descricao" placeholder="Descricao">
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalCadastrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaCadastro()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrar.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Descrição</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrar.formGroup.value.descricao !== '' && formFiltrar.formGroup.value.descricao !== null">
                    <input nz-input type="text" formControlName="descricao" placeholder="Descricao"/>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaFiltrar()">Confirmar</button>
    </div>
</nz-modal>
<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Exportar'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [nzAutoTips]="autoTips" [formGroup]="formExportar.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadingExportar" (click)="confirmaExportar()">Exportar</button>
    </div>
</nz-modal>
