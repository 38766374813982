import {Injectable} from '@angular/core';
import {AbstractService} from '@services/abstract.service';
import Visibilidade from '@models/visibilidade.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CreditosService extends AbstractService<Visibilidade> {

    constructor(http: HttpClient) {
        super(http, '/administracao/creditos', Visibilidade);
    }

    remover(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/administracao/creditos/detalhe/${id}`);

    }

    get(id: string | null): Observable<any> {

        return this.http.get(`${environment.apiUrl}/administracao/creditos/detalhe/${id}`);

    }

    editar(id: string, dados: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/administracao/creditos/detalhe/${id}`, dados);

    }

    cadastrar(dados: any): Observable<any> {

        return this.http.post(`${environment.apiUrl}/administracao/creditos`, dados);

    }

    exportExcel(filtros: any, search: string = null, tipo: string): any {

        filtros.tipoExportacao = tipo;

        const queryStr = this.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/contas/exportar${queryStr}`,
            {responseType: 'blob'});

    }

}
