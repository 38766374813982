import { Component, DoCheck } from '@angular/core';
import { AuthenticationService } from './core/auth/authentication.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { TabService } from '@services/tab.service';
import { select, Store } from '@ngrx/store';
import { NavigationStart, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { UrlHelperService } from '@services/url-helper.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styles: [`
        :host {
            display: flex;
            height: 100%;
            width: 100%;
        }
    `
    ]
})
export class AppComponent implements DoCheck {
    isLogged = false;
    private appName = environment.applicationName;

    constructor(private authService: AuthenticationService, private titleService: Title,
                private tabService: TabService, private store: Store, private router: Router,
                private urlHelperService: UrlHelperService) {
        this.setTitle(this.appName);
        this.listenUrl();
    }

    listenUrl() {
        this.router.events.pipe(first()).subscribe((navigation: NavigationStart) => {
            const index = this.urlHelperService.checkUrl(navigation.url);
            this.isLogged = this.authService.isLogged();
        });

    }

    // @ts-ignore
    getTabs = () => this.store.pipe(select('tabs'));

    ngDoCheck() {
        this.isLogged = this.authService.isLogged();
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }
}

