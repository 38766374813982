<nz-layout>
    <app-header *ngIf="isLogged"></app-header>
    <nz-content *ngIf="isLogged" [class.main-content]="isLogged" id="main-content">
        <section *ngFor="let tab of getTabs() | async" [class.app-tab-disabled]="!tab.active" style="height: 100%;">
            <app-tab-content [tab]="tab" style="height: 100%;"></app-tab-content>
        </section>
    </nz-content>
    <router-outlet></router-outlet>
<!--    <router-outlet name="home"></router-outlet>-->
</nz-layout>
