<nz-page-header class="site-page-header" nzTitle="Relatórios"
                nzSubtitle="Limites x Utilizados">
</nz-page-header>

<nz-content>

    <nz-row nzGutter="24">
        <nz-col nzSpan="24">
            <nz-card [nzLoading]="loadingDados" [nzBodyStyle]="{'padding': loadingDados ? '15px' : '0px'}"
                    [nzTitle]="title"
                    [nzExtra]="navBar"
                    [ngClass]="[!loadingDados ? 'ant-card-table' : '', statusCard ? 'card-fullscreen' : '']">

                <nz-table #tableOne
                          [nzLoading]="loadingDados"
                          (nzQueryParams)="listByTableContas($event)"
                          [nzData]="dados"
                          [nzSize]="'small'"
                          [nzFrontPagination]="false"
                          [nzShowPagination]="true"
                          [nzTotal]="pagination?.total"
                          [nzPageSize]="pagination?.per_page"
                          [nzPageIndex]="pagination?.current_page"
                          [nzShowTotal]="rangeTemplate"
                          class="table-small"
                          nzBordered>
                    <thead>
                    <tr>
                        <th colSpan="2">Conta</th>
                        <th colSpan="2">Empresas</th>
                        <th colSpan="2">Usuários</th>
                        <th colSpan="2"></th>
                    </tr>
                    <tr>
                        <th #column1 style="text-align: left;">Identificador</th>
                        <th #column3 style="text-align: left;">Classificação</th>
                        <th #column4 style="text-align: center;">Limite</th>
                        <th #column5 style="text-align: center;">Cadastradas</th>
                        <th #column6 style="text-align: center;">Limite</th>
                        <th #column7 style="text-align: center;">Cadastradas</th>
                        <th #column2 style="width: 32px"></th>
                        <th #column8 style="text-align: center; width: 55%; min-width: 600px">Módulos</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let conta of tableOne.data">
                        <tr>
                            <td style="text-align: left; max-width: 180px" [ngStyle]="{'word-break': 'breack-word'}">
                                <div style="display: flex; gap: 5px; justify-content: left; align-items: center">
                                      <h5 style="display: inline-block; color: #1e9ff2; cursor: pointer; margin-bottom: -5px; font-size: 1.2em"
                                          nz-button nzType="link"
                                          (click)="openTab('/conta/detalhe', conta.conta_id, {id: conta.conta_id, nome: conta.nome})">{{conta?.identificador}}</h5>
                                     <span style="color: #fff; background-color: #f50; padding: 0 3px; font-weight: bold; font-size: 10px" *ngIf="!conta?.ativo">
                                        Inativa
                                    </span>
                                </div>
                                <small style="margin-left: 2px">({{conta.nome}})</small>
                            </td>
                            <td nzAlign="center">{{conta.classificacao ? (conta.classificacao | titlecase) : '-'}}</td>
                            <td nzAlign="center">{{conta.empresasLimite ? conta.empresasLimite : '0'}}</td>
                            <td nzAlign="center">{{conta.empresasCadastradas ? conta.empresasCadastradas : '0'}}</td>
                            <td nzAlign="center">{{conta.usuariosLimite ? conta.usuariosLimite : '0' }}</td>
                            <td nzAlign="center">{{conta.usuariosCadastrados ? conta.usuariosCadastrados : '0'}}</td>
                            <td nzWidth="32px" style="color: #1e9ff2; width: 34px;"
                                [nzExpand]="expandSetDados.has(conta.identificador)"
                                (nzExpandChange)="onExpandChangeDados(conta.identificador, $event)"
                            ></td>
                            <td colspan="8" nzAlign="center">
                                {{conta.modulos.length ? conta.modulos.length : 0}}
                            </td>
                        </tr>
                        <ng-container *ngIf="conta.modulos.length > 0">
                            <tr class="sub-table" [nzExpand]="expandSetDados.has(conta.identificador)">
                                <th [ngStyle]="{
                                    'width': column1Width + column3Width + column4Width + column5Width + column6Width + column7Width + column2Width +45+ 'px',
                                    position: 'relative',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                }"></th>
                                <th [ngStyle]="{
                                    'width': (column8Width/4) + 'px',
                                    position: 'relative',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'left'
                                }">Módulo
                                </th>
                                <th [ngStyle]="{
                                    'width': (column8Width/8) + 'px',
                                    position: 'relative',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                }">Unidade
                                </th>
                                <th [ngStyle]="{
                                    'width': (column8Width/8) + 'px',
                                    position: 'relative',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                }">Limite
                                </th>
                                <th [ngStyle]="{
                                    'width': (column8Width/8) + 'px',
                                    position: 'relative',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                }">Processado
                                </th>
                                <th [ngStyle]="{
                                    'width': (column8Width/4) + 'px',
                                    position: 'relative',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                }">Processamento Data
                                </th>
                                <th [ngStyle]="{
                                    'width': (column8Width/8) + 'px',
                                    position: 'relative',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                }">Utilizado
                                </th>
                            </tr>
                            <tr class="sub-table" [nzExpand]="expandSetDados.has(conta.identificador)"
                                *ngFor="let m of conta.modulos">


                                <td [ngStyle]="{
                                    'width': column1Width + column3Width + column4Width + column5Width + column6Width + column7Width + column2Width +35+ 'px',
                                    position: 'relative',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                }"></td>


                                <td [ngStyle]="{
                                    'width': (column8Width/4) + 'px',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'left',
                                    'padding-right':'5px'
                                }">{{m.nome}}
                                </td>


                                <td [ngStyle]="{
                                    'width': (column8Width/8) + 'px',
                                    position: 'relative',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                }">
                                    {{m.unidade}}
                                </td>


                                <td [ngStyle]="{
                                    'width': (column8Width/8) + 'px',
                                    position: 'relative',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                }">
                                    {{m.limite ? m.limite : '-'}}
                                </td>


                                <td [ngStyle]="{
                                    'width': (column8Width/8) + 'px',
                                    position: 'relative',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                }">
                                    {{m.processado ? (m.processado | number: '1.0-0') : '-'}}
                                </td>


                                <td [ngStyle]="{
                                    'width': (column8Width/4) + 'px',
                                    position: 'relative',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                }">
                                    {{m.dataProcessamento ? (m.dataProcessamento  | date: 'dd/MM/YYYY') : '-'}}
                                </td>


                                <td [ngStyle]="{
                                    'width': (column8Width/8) + 'px',
                                    position: 'relative',
                                    'overflow-wrap': 'break-word',
                                    'text-align': 'center'
                                }">
                                    <div [class]="m.utilizadoPorcentagem > 99 ? 'colorRed' : ''"
                                         [nz-tooltip]="m.criterio">
                                        {{m.utilizadoPorcentagem ? m.utilizadoPorcentagem + '%' : '-'}}
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container
                                *ngIf="conta.modulos.length === 0 || conta.modulos === undefined">
                            <tr [nzExpand]="false"
                                style="text-align: center;">
                                Sem Dados
                            </tr>
                        </ng-container>
                    </ng-container>
                    </tbody>
                </nz-table>
                <ng-template #rangeTemplate let-range="range" let-total>
                    {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
                </ng-template>
            </nz-card>
        </nz-col>
    </nz-row>
</nz-content>

<ng-template #title>
    Limites x Utilizados
    <span nz-icon nzType="info-circle"
          nzTheme="outline"
          nzTooltipTitle="Data último processamento:  {{dataUltimoProcessamento | date:'dd/MM/YY - HH:mm:ss'}}"
          nzTooltipPlacement="right"
          style="color: #E4A11B; padding-left: 10px;font-weight: bold;cursor: pointer"
          nz-button nz-tooltip>
        </span>
</ng-template>

<ng-template #navBar>
    <div class="d-flex align-items-center">
        <button disabled="{{loadingDados}}" style="margin-right: 10px" (click)="exibirTudo(!expandAll)" nz-button>
            <em *ngIf="expandAll" class="fas fa-angle-double-up"></em>
            <em *ngIf="!expandAll" class="fas fa-angle-double-down"></em>
        </button>
        <nz-badge [nzCount]="qtdFiltrosAtivos" style="margin-right: 10px;"
                  [nzStyle]="{ backgroundColor: '#52c41a' }">
            <button disabled="{{loadingDados}}" nz-button nzType="primary" (click)="abrirModal(formFiltros)">
                <i nz-icon nzType="search"></i>
                Filtros
            </button>
        </nz-badge>
        <button nzLoading="{{opcoesLoading}}" class="centralized-icon" [nzDropdownMenu]="menuAcoes"
                [nzType]="'default'" nz-button nz-dropdown style="margin-right: 10px;">
            Opções
            <em nz-icon nzType="down"></em>
        </button>
        <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item nz-button (click)="confirmAtualizarUtilizados();">Atualizar Utilizados</li>
            </ul>
        </nz-dropdown-menu>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
            <input type="text" nz-input placeholder="Buscar"
                   (keydown.enter)="buscar()"
                   [(ngModel)]="currentSearch"/>
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button disabled="{{loadingDados}}" nz-button nzType="primary" nzSearch
                    (click)="buscar();"><i
                    nz-icon nzType="search"></i></button>
        </ng-template>
        <button disabled="{{loadingDados}}" nz-button nzType="default"
                style="margin-right: 10px;" (click)="limparFiltros()">
            Limpar
        </button>
        <button nz-button nzType="default"
                style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                (click)="getContas(this.currentParams.filter)" title="Atualizar">
            <i nz-icon nzType="reload"></i>
        </button>
        <button nz-button [nzType]="'default'" (click)="clickEvent()"
                style="padding-left: 8px; padding-right: 8px;">
            <i class="fas" [ngClass]="statusCard ? 'fa-compress' : 'fa-expand'"></i>
        </button>
    </div>
</ng-template>

<nz-modal [(nzVisible)]="formFiltros.modalVisible"
          [nzTitle]="'Filtros avançados'" [nzClosable]="true"
          (nzOnCancel)="fechar(formFiltros)"
          [nzWidth]="720">
    <nz-row *nzModalContent>
        <nz-col [nzSpan]="24">
            <form nz-form [nzNoColon]="false" [formGroup]="formFiltros.formGroup">
                <nz-form-item>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Conta</nz-form-label>
                    <nz-form-control [nzSpan]="14" size="60" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.conta">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   nzMode="multiple"
                                   [(ngModel)]="formFiltros.formGroup.value.conta"
                                   formControlName="conta">
                            <nz-option *ngFor="let opt of selectContas"
                                       [nzLabel]="opt.label"
                                       [nzValue]="opt.value"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Tipo</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.tipo">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="tipo">
                            <nz-option
                                    [nzLabel]="'Contabilidade'" [nzValue]="'Contabilidade'">
                            </nz-option>
                            <nz-option
                                    [nzLabel]="'Empresarial'" [nzValue]="'Empresarial'">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Inativo</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.inativo">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="inativo">
                            <nz-option
                                    [nzLabel]="'Mostrar somente inativos'" [nzValue]="'somente'">
                            </nz-option>
                            <nz-option
                                    [nzLabel]="'Monstrar também inativos'" [nzValue]="'tambem'">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Classificação</nz-form-label>
                    <nz-form-control [nzSpan]="14" size="60" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.classificacao">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   [(ngModel)]="formFiltros.formGroup.value.classificacao"
                                   formControlName="classificacao">
                            <nz-option *ngFor="let opt of selectClassificacao"
                                       [nzLabel]="opt.label"
                                       [nzValue]="opt.value"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Plano</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.plano_id">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="plano_id">
                            <nz-option nzLabel="Sem plano" nzValue="semPlano"></nz-option>
                            <nz-option *ngFor="let opt of comboPlanos"
                                       [nzLabel]="opt.label" [nzValue]="opt.value">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Módulo</nz-form-label>
                    <nz-form-control [nzSpan]="14" size="60" nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.modulo">
                        <nz-select nzShowSearch nzAllowClear
                                   nzPlaceHolder="Selecione"
                                   [(ngModel)]="formFiltros.formGroup.value.modulo"
                                   formControlName="modulo">
                            <nz-option *ngFor="let opt of selectModulos"
                                       [nzLabel]="opt.label" [nzValue]="opt.value"
                            ></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label [nzSpan]="6">Visibilidade</nz-form-label>
                    <nz-form-control [nzSpan]="14"
                                     nzValidateStatus="success"
                                     [nzHasFeedback]="formFiltros.formGroup.value.visibilidade_id">
                        <nz-select nzShowSearch nzAllowClear
                                   [nzPlaceHolder]="'Selecione'" formControlName="visibilidade_id">
                            <nz-option nzLabel="Sem visibilidade" nzValue="semVisibilidade"></nz-option>
                            <nz-option *ngFor="let opt of comboVisibilidades"
                                       [nzLabel]="opt.label" [nzValue]="opt.value">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-col>
    </nz-row>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="fechar(formFiltros)">Cancelar</button>
        <button nz-button nzType="primary" (click)="filtrar()">Filtrar</button>
    </div>
</nz-modal>