import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsetComponent } from '@components/tabset/tabset.component';
import { TabService } from '@services/tab.service';
import { ContentContainerDirective } from '@components/tabset/content-container.directive';
import { TabContentComponent } from '@components/tabset/tab-content.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';


@NgModule({
    declarations: [
        TabsetComponent,
        ContentContainerDirective,
        TabContentComponent,
    ],
    imports: [
        CommonModule,
        NzTabsModule,
        NzIconModule,
        NzDropDownModule
    ],
    exports: [TabsetComponent, TabContentComponent],
    providers: [
        TabService
    ]
})
export class TabsetModule {
}
