import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalPageComponent } from './pages/external-page.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';


@NgModule({
    declarations: [ExternalPageComponent],
    imports: [
        CommonModule,
        NzSpinModule,
        NzIconModule
    ],
    exports: [ExternalPageComponent],
    bootstrap: [ExternalPageComponent]
})
export class ExternalPageModule {
}
