import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {TabHandlerInterface} from '../../../shared/interfaces/tab-handler.interface';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Perfil} from '@models/perfil.model';
import {PerfilService} from '@services/perfil.service';
import {UntypedFormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-perfils',
    templateUrl: './perfis.component.html',
    styleUrls: ['./perfis.component.scss']
})
export class PerfisComponent extends AbstractListTable<Perfil> implements OnInit, TabHandlerInterface {

    modalCadastrarVisible = false;

    cadastrando = false;

    screenPermissoes = {
        cadastrar: '89793aa4884d'
    };

    constructor(
        private fb: UntypedFormBuilder,
        private perfilService: PerfilService,
        private toastService: ToastrService,
        private tabService: TabService) {
        super(perfilService, Perfil, toastService);


        this.getPermissoesPerfil();

        this.formCadastrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                descricao: [null, [Validators.required]],
            })
        };

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                descricao: [null, [Validators.required]],
            })
        };

    }

    ngOnInit() {

    }

    handleCancel() {
    }


    openTab(componentName: string, queryParams?: string, data?: any) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const title = data.descricao + ' - Detalhe do perfil';
        const newTab = new Tab(component.name, title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    confirmaCadastro() {

        this.cadastrando = true;

        const dadosPerfil = {};

        for (const key in this.formCadastrar.formGroup.controls) {

            if (key) {

                const campo = this.formCadastrar.formGroup.get(key);

                campo.markAsDirty();
                campo.updateValueAndValidity();

                dadosPerfil[key] = campo.value;

            }

        }

        if (this.formCadastrar.formGroup.valid) {

            this.perfilService.save(this.formCadastrar.formGroup.value).subscribe(
                (response) => {

                    this.toastrService.success('Dados atualizados com sucesso!');
                    this.updateTable();
                    this.modalCadastrar(false);
                    this.openTab('/administracao/perfil/detalhe/', response.id, {id: response.id});
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.cadastrando = false;

                }
            );


        } else {

            this.cadastrando = false;

        }

    }


}
