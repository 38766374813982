export abstract class CrudModel {

    constructor(obj?: any) {

    }

    massUpdate(ids: string[], fields) {

        return {
            ids,
            fields,
        };

    }

}
