import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AbstractService} from '@services/abstract.service';
import {Perfil} from '@models/perfil.model';
import {environment} from '../../../../../environments/environment';
import {formatDate} from '@angular/common';

@Injectable({providedIn: 'root'})


export class AvisoDetalheService extends AbstractService<Perfil> {

    constructor(http: HttpClient) {
        super(http, '/administracao/avisos', {});
    }

    retornaAviso(id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/administracao/avisos/detalhe/${id}`);

    }

    atualizar(id: string, campos: any): Observable<any> {

        if (campos.dataInicio) {
            campos.dataInicio = formatDate(campos.dataInicio, 'YYYY-MM-dd HH:mm:ss', 'pt_BR');
        }
        if (campos.dataFim) {
            campos.dataFim = formatDate(campos.dataFim, 'YYYY-MM-dd HH:mm:ss', 'pt_BR');
        }

        return this.http.put(`${environment.apiUrl}/administracao/avisos/detalhe/${id}`, campos);

    }


    removerConta(idAviso: string, idConta: string): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/administracao/avisos/detalhe/${idAviso}/contas/${idConta}`);

    }

    addContas(idAviso: string, contas: any): Observable<any> {

        return this.http.put(`${environment.apiUrl}/administracao/avisos/detalhe/${idAviso}/contas`, contas);

    }

    getComboContas(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/contas/select`);
    }

}
