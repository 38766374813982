import {Component, Input, OnInit, ViewChildren} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PlanoDetalheService} from './plano-detalhe.service';
import {Perfil} from '@models/perfil.model';
import {ToastrService} from 'ngx-toastr';
import {DataService} from '@services/data.service';
import {Subscription} from 'rxjs';
import {NzTreeNode} from 'ng-zorro-antd/tree';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Usuario} from '@models/usuario.model';
import {UsuarioService} from '@services/usuario.service';
import {TabHandlerInterface} from '../../../../shared/interfaces/tab-handler.interface';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Pagination} from '@models/pagination.model';
import {buildUrl, findComponentByUrl} from '../../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';

interface RecursivoParams {
    nodes: NzTreeNode[];
    stack: string[];
}

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-visibilidade-detalhe',
    templateUrl: './plano-detalhe.component.html',
    styleUrls: ['./plano-detalhe.component.scss']
})

export class PlanoDetalheComponent extends AbstractListTable<any> implements OnInit {


    readonly registerLink = '/usuarios/cadastrar';

    @Input() data;
    @Input() expandAll = false;
    @ViewChildren('treePlano') treePlano;

    autoTips: Record<string, Record<string, string>> = {
        default: {
            required: 'Campo obrigarório',
        }
    };

    plano: any;
    checkedKeys = [];
    userAdmin = false;
    currentUser: Subscription;

    formDadosCadastrais: FormStack;
    formPermissoes: FormStack;

    carregando = false;
    gravando = false;
    loginCpf = true;
    permissoesTree: NzTreeNode[];

    tabNumber = 0;

    qtdFiltrosAtivos = 0;

    usuarios: any;
    contas: any;

    loadingLog = false;

    pagination: any = {
        usuarios: Pagination,
        contas: Pagination
    };

    loadings = {
        permissoes: false,
        gravandoPermissoes: false
    };

    logsDeAlteracoes = [];

    constructor(
        private dataService: DataService,
        private usuarioService: UsuarioService,
        private toastService: ToastrService,
        private fb: UntypedFormBuilder,
        private planoDetalheService: PlanoDetalheService,
        public toastrService: ToastrService,
        private tabService: TabService
    ) {
        super(usuarioService, Usuario, toastService);

        this.formDadosCadastrais = {
            modalVisible: false,
            formGroup: this.fb.group({
                descricao: [null, Validators.required],
            })
        };

    }

    ngOnInit(): void {

        this.carregar(this.data.id);
        this.getPermissoesTree();
    }

    getPermissoesTree() {

        this.permissoesTree = [];

        this.loadings.permissoes = true;
        this.planoDetalheService.getTree(this.data.id).subscribe(res => {


            this.permissoesTree = this.planoDetalheService.transformPermissoesItem(res, 0, false);

            setTimeout(() => {
                this.setSelectedItens(this.plano.permissoes);
                this.loadings.permissoes = false;
            }, 2000);

        });
    }


    getSelectedItens() {

        const recursivo = ({nodes, stack}: RecursivoParams) => {

            for (const node of nodes) {

                if (node.children && node.children.length > 0) {

                    stack = recursivo({nodes: node.children, stack});

                }

                if ((node.isChecked || node.isHalfChecked) && stack.indexOf(node.key) === -1) {

                    stack.push(node.key);
                }


            }

            return stack;

        };

        let selected = [];

        this.treePlano.forEach(tree => {

            selected = recursivo({nodes: tree.getTreeNodes(), stack: selected});

        });

        return selected;

    }

    // setSelectedItens(keys: string[]) {
    //
    //     const recursivo = (nodes: NzTreeNode[], hasChecked = 0) => {
    //
    //         for (const node of nodes) {
    //
    //             let count = 0;
    //
    //             if (node.children && node.children.length > 0) {
    //
    //                 count = recursivo(node.children);
    //
    //             } else if (keys?.indexOf(node.key) > -1) {
    //                 count++;
    //
    //             }
    //
    //             node.isChecked = count > 0;
    //             hasChecked += count;
    //
    //
    //         }
    //
    //         return hasChecked;
    //
    //     };
    //
    //     this.treePlano.forEach(tree => {
    //
    //         recursivo(tree.getTreeNodes());
    //
    //     });
    //
    //
    // }

    setSelectedItens(keys: string[]) {

        const checkN0 = (nodes: NzTreeNode[], hasChecked = 0) => {

            for (const node of nodes) {

                let count = 0;

                if (node.children && node.children.length > 0) {

                    count = checkN1(node.children);

                } else if (keys?.indexOf(node.key) > -1) {
                    count++;

                }

                hasChecked += count;
                if (node.children.length > 0) {
                    node.isChecked = count === node.children.length;
                    node.isHalfChecked = count > 0 && count !== node.children.length;
                } else {
                    node.isChecked = count > 0;
                }
            }

            return hasChecked;

        };
        const checkN1 = (nodes: NzTreeNode[], hasChecked = 0) => {

            for (const node of nodes) {

                let count = 0;

                if (node.children && node.children.length > 0) {

                    count = checkN2(node.children);

                } else if (keys?.indexOf(node.key) > -1) {
                    count++;

                }

                hasChecked += count;
                if (node.children.length > 0) {
                    node.isChecked = count === node.children.length;
                    node.isHalfChecked = count > 0 && count !== node.children.length;
                } else {
                    node.isChecked = count > 0;
                }
            }

            return hasChecked;

        };
        const checkN2 = (nodes: NzTreeNode[], hasChecked = 0) => {

            for (const node of nodes) {

                let count = 0;

                if (node.children && node.children.length > 0) {

                    count = checkN3(node.children);

                } else if (keys?.indexOf(node.key) > -1) {
                    count++;

                }

                hasChecked += count;

                if (node.children.length > 0) {
                    node.isChecked = count === node.children.length;
                    node.isHalfChecked = count > 0 && count !== node.children.length;
                } else {
                    node.isChecked = count > 0;
                }

            }

            return hasChecked;

        };

        const checkN3 = (nodes: NzTreeNode[], hasChecked = 0) => {

            for (const node of nodes) {

                let count = 0;

                if (node.children && node.children.length > 0) {
                    // Por hora, só até nível 3
                } else if (keys?.indexOf(node.key) > -1) {
                    count++;

                }

                hasChecked += count;

                if (node.children.length > 0) {
                    node.isChecked = count === node.children.length;
                    node.isHalfChecked = count > 0 && count !== node.children.length;
                } else {
                    node.isChecked = count > 0;
                }

            }

            return hasChecked;

        };

        this.treePlano.forEach(tree => {

            checkN0(tree.getTreeNodes());

        });


    }

    async carregar(id, quiet = false) {

        if (!quiet) {
            this.carregando = true;
        }
        this.planoDetalheService.retornaPlano(id).subscribe((response) => {
            this.plano = response;
            this.carregando = false;
            this.tabNumber = 0;
        });

    }

    editar(formulario: FormStack): void {

        for (const name in formulario.formGroup.controls) {

            if (name) {

                formulario.formGroup.get(name).setValue(this.plano[name]);

            }
        }

        formulario.modalVisible = true;

    }

    fechar(formulario: FormStack): void {

        formulario.modalVisible = false;
        this.gravando = false;

    }

    confirmar(formulario: FormStack) {

        this.gravando = true;

        if (formulario.formGroup.valid) {

            this.gravar(this.data.id, formulario.formGroup.value);

        } else {

            this.gravando = false;

        }

    }

    gravar(id, dados) {

        this.planoDetalheService.atualizar(id, dados).subscribe(
            () => {
                this.fechar(this.formDadosCadastrais);
                this.toastrService.success('Dados atualizados com sucesso!');
                this.gravando = false;
                this.carregar(this.data.id, true);
            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.gravando = false;

            }
        );

    }

    changeTabs(event: any = null) {
        // this.currentParams.pageIndex = 1;

        switch (this.tabNumber) {
            case 0: {
                this.queryTable(this.currentParams, this.currentSearch);
                break;
            }
            case 1: {
                this.queryTable(this.currentParams, this.currentSearch);
                break;
            }
            case 2: {
                this.getLogAlteracoes(this.currentParams);
                break;
            }
        }
    }

    getLogAlteracoes(params: NzTableQueryParams): void {

        this.currentParams = params;

        this.loadingLog = true;


        this.usuarioService.logDeAlteracao('plano', this.plano.id).subscribe((response) => {

            this.logsDeAlteracoes = response;

            this.loadingLog = false;
        }, err => {
            this.loadingLog = false;
            this.toastrService.error(err.error.message);
        });

    }


    queryTableContas(): void {

        this.loading = true;

        const url = this.planoDetalheService.baseUrl + '/detalhe/' + this.data.id + '/contas';


        this.abstractService.listToTable(this.currentParams, this.currentSearch, url).subscribe((response) => {

            this.contas = [];
            this.contas = response?.data || response;

            this.pagination.contas = new Pagination(
                response?.per_page || 50,
                response?.current_page || 1,
                response?.last_page || 1,
                response?.total || 50);

            this.loading = false;
        });

    }

    queryTable(params: NzTableQueryParams, search: string = null): void {

        this.currentParams = params;
        this.currentSearch = search;

        switch (this.tabNumber) {
            case 0: {
                this.getPermissoesTree();
                break;
            }

            case 1: {
                if (typeof this.currentParams === 'object') {
                    this.queryTableContas();
                }
                break;
            }
        }
    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }


    btnResetSearch() {

        this.currentSearch = null;
        this.currentParams = {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sort: [],
            filter: [],
        };

        this.queryTable(this.currentParams, this.currentSearch);

    }

    gravarPermissoes() {


        this.loadings.gravandoPermissoes = true;

        this.planoDetalheService.salvarPermissoes(this.plano.id, this.getSelectedItens()).subscribe((res) => {
                this.carregar(this.data.id, true);
                this.getPermissoesTree();
                this.loadings.gravandoPermissoes = false;
                this.toastrService.success('Dados atualizados com sucesso!');
                this.gravando = false;
            },
            (response) => {

                this.toastrService.error(response.error.message);
                this.gravando = false;

            }
        );


    }

    expandAllHandler(expanded: boolean) {

        this.loadings.permissoes = true;
        const expandTreeNodes = (node: NzTreeNode) => {

            node.isExpanded = expanded;

            if (node.children) {

                node.children.forEach(expandTreeNodes);

            }
        };


        this.treePlano.forEach((tree) => {
            tree.getTreeNodes().forEach(expandTreeNodes);
        });

        setTimeout(() => {
            this.loadings.permissoes = false;
        }, 500);


    }

}

