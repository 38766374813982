<ng-container>
    <div class="principal">
        <!--        <div class="lds-ripple">-->
        <!--            <div></div>-->
        <!--            <div></div>-->
        <!--        </div>-->
        <div id="mt-loader-1">
            <div class="mt-preloaderr mt-loader-front">
                <div class="mt-loader">
                    <div class="mt-loader-inner">
                        <img src="#"/>
                        <img src="#"/>
                        <img src="#"/>
                        <div class="mt-loader-extra">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
