import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AbstractListTable} from '@components/abstract/AbstractListTable';
import {Usuario} from '@models/usuario.model';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {CreditosService} from '@services/creditos.service';
import {ToastrService} from 'ngx-toastr';
import {NzModalService} from 'ng-zorro-antd/modal';
import * as fileSaver from 'file-saver-es';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-creditos',
    templateUrl: './creditos.component.html',
    styleUrls: ['./creditos.component.scss']
})
export class CreditosComponent extends AbstractListTable<Usuario> implements OnInit {

    formFiltrar: FormStack;
    formEditar: FormStack;
    formExportar: FormStack;
    loadings: { cadastrando: boolean, salvando: boolean, detalhe: boolean, removendo: any[] } = {
        cadastrando: false,
        salvando: false,
        detalhe: false,
        removendo: []
    };
    creditoEditando: { id: string | null } = {id: null};

    constructor(
        private fb: UntypedFormBuilder,
        private tabService: TabService,
        private creditosService: CreditosService,
        private toastService: ToastrService,
        private modalService: NzModalService,
        private cdr: ChangeDetectorRef
    ) {
        super(creditosService, Usuario, toastService);

        this.formCadastrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                contexto: [null, [Validators.required]],
                descricao: [null, [Validators.required]],
                saldo: [null, [Validators.required]],
            })
        };

        this.formFiltrar = {
            modalVisible: false,
            formGroup: this.fb.group({
                descricao: [null, null]
            })
        };

        this.formEditar = {
            modalVisible: false,
            formGroup: this.fb.group({
                descricao: [null, Validators.required],
                saldo: [null, Validators.required],
            })
        };

        this.formExportar = {
            modalVisible: false,
            formGroup: this.fb.group({
                tipo: [null, Validators.required],
            })
        };
    }

    ngOnInit() {

    }

    openTab(componentName: string, queryParams?: string, data?: {}) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        const newTab = new Tab(component.name, component.title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    showConfirmRemover(id: string): void {

        this.modalService.confirm({
            nzTitle: 'Deseja remover o crédito?',
            nzOkText: 'Remover',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.remover(id)
        });

    }

    remover(id: string): void {

        this.loadings.removendo[id] = true;
        this.creditosService.remover(id).subscribe(
            () => {
                this.toastrService.success('Credito removido com sucesso!');
                this.queryTable(this.currentParams, this.currentSearch);
                this.loadings.removendo[id] = false;
            },
            (response) => {
                this.toastrService.error(response.error.message);
                this.loadings.removendo[id] = false;

            }
        );
    }

    confirmaExportar(): void {


        const tipo = this.formExportar.formGroup.value.tipo;

        this.creditosService.exportExcel(this.currentParams.filter, this.currentSearch, tipo).subscribe((res) => {

            if (this.formExportar.formGroup.value.tipo) {

                const blob = new Blob([res], {type: 'text/json; charset=utf-8'});

                const name = 'Créditos';

                fileSaver.saveAs(blob, name + '.' + this.formExportar.formGroup.value.tipo);

                this.formExportar.formGroup.reset();

                this.formExportar.modalVisible = false;
            }

        });
    }

    confirmaCadastrar() {

        if (this.formCadastrar.formGroup.valid) {

            this.loadings.cadastrando = true;

            this.creditosService.cadastrar(this.formCadastrar.formGroup.value).subscribe(
                () => {

                    this.formCadastrar.formGroup.reset();
                    this.toastrService.success('Crédito cadastrado com sucesso!');
                    this.queryTable(this.currentParams, this.currentSearch);
                    this.modalCadastrar(false);
                    this.loadings.cadastrando = false;
                    this.cdr.detectChanges();
                },
                (response) => {

                    this.toastrService.error(response.error.message);
                    this.loadings.cadastrando = false;

                }
            );


        } else {

            this.cadastrando = false;

        }

    }

    async modalEditar(visible: boolean, id: string | null = null) {

        this.formEditar.modalVisible = visible;
        this.creditoEditando.id = id;
        if (visible) {
            this.loadings.detalhe = true;
            const retorno = await this.creditosService.get(id).toPromise();
            this.formEditar.formGroup.get('descricao').setValue(retorno.descricao);
            this.formEditar.formGroup.get('saldo').setValue(Number(retorno.saldo));
            this.loadings.detalhe = false;
        }


    }

    confirmaEditar(): void {

        if (this.formEditar.formGroup.valid) {
            this.loadings.salvando = true;


            this.creditosService.editar(this.creditoEditando.id, this.formEditar.formGroup.value).subscribe(res => {
                this.loadings.salvando = false;
                this.formEditar.formGroup.reset();
                this.toastService.success('Crédito alterado com sucesso.');
                this.queryTable(this.currentParams, this.currentSearch);
                this.modalEditar(false);
            }, (res) => {
                this.loadings.salvando = false;
                this.toastService.error('Erro ao editar crédito.');
            });


        } else {
            Object.values(this.formEditar.formGroup.controls).forEach(control => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({onlySelf: true});
                }
            });

        }


    }

}
