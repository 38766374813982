import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Usuario} from '@models/usuario.model';
import {AbstractService} from './abstract.service';
import {Observable} from 'rxjs';
import {formatDate} from '@angular/common';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class UsuarioService extends AbstractService<Usuario> {

    constructor(http: HttpClient) {
        super(http, '/administracao/usuarios', Usuario);
    }

    exportExcel(filtros: any, search: string = null, tipo: string): any {

        filtros.tipoExportacao = tipo;

        const queryStr = this.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/administracao/usuarios/exportar${queryStr}`,
            {responseType: 'blob'});

    }

    save(campos): Observable<any> {

        if (campos.primeiroNome) {

            campos.nome = campos.primeiroNome + (campos.sobreNome ? ' ' + campos.sobreNome : '');
            delete campos.primeiroNome;
            delete campos.sobreNome;

        }

        if (campos.nascimentoData) {

            campos.nascimentoData = formatDate(campos.nascimentoData, 'YYYY-MM-dd', 'pt_BR');

        }

        return this.http.post(`${environment.apiUrl}/administracao/usuario`, campos);

    }

    comboPerfis(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/perfis/select`);
    }

    comboVisibilidades(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/visibilidades/select`);
    }

    getComboContas(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/contas/select`);
    }

    loadContasVisibilidade(id): Observable<any> {
        return this.http.get(`${environment.apiUrl}/contas/contasVisibilidade/${id}`);
    }

    alterarStatusEmMassa(usuarios: any[], ativo: string): any {


        return this.http.post(`${environment.apiUrl}/administracao/usuarios/alterarStatusMassa`, {usuarios, ativo});

    }

    logDeAlteracao(tabela: string, key: string): any {


        return this.http.get(`${environment.apiUrl}/logs/${tabela}?primaryKey=${key}`);

    }


}
