import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {AbstractService} from '@services/abstract.service';
import {formatDate} from '@angular/common';
import {UsuarioLogado} from '@models/usuarioLogado.model';

@Injectable({providedIn: 'root'})

export class UsuarioLogadoService extends AbstractService<UsuarioLogado> {

    constructor(http: HttpClient) {
        super(http, '/usuario-logado', {});
    }

    get(): Observable<any> {

        return this.http.get(`${environment.apiUrl}/usuario-logado/detalhe`);

    }

    save(campos): Observable<any> {

        if (campos.primeiroNome) {

            campos.nome = campos.primeiroNome + (campos.sobreNome ? ' ' + campos.sobreNome : '');
            delete campos.primeiroNome;
            delete campos.sobreNome;

        }

        if (campos.nascimentoData) {

            campos.nascimentoData = formatDate(campos.nascimentoData, 'YYYY-MM-dd', 'pt_BR');

        }

        return this.http.put(`${environment.apiUrl}/usuario-logado`, campos);

    }

    getAvatar(id: string): Observable<any> {

        return this.http.get(`${environment.apiUrl}/administracao/usuario/detalhe/${id}/avatar`);

    }

}
