<nz-page-header class="site-page-header" nzTitle="Monitoramento"
                nzSubtitle="Monitoramento de status de baixas automáticas: Obrigações, DECs e CNDs">
</nz-page-header>

<nz-content>

    <nz-card [ngClass]="expandedCard ? 'card-fullscreen' : ''"
             [nzLoading]="loadings.obrigacoes || loadings.domicilios || loadings.certidoes"
             [nzBodyStyle]="{'padding': loadings.obrigacoes || loadings.domicilios || loadings.certidoes ? '15px' : '0px'}">

        <nz-card-tab>
            <nz-tabset
                    [nzSize]="'large'"
                    [nzTabBarExtraContent]="extraTabs"
                    [(nzSelectedIndex)]="tabNumber">
                <nz-tab nzTitle="Obrigações"></nz-tab>
                <nz-tab nzTitle="Domicílios Eletrônicos"></nz-tab>
                <nz-tab nzTitle="Certidões"></nz-tab>
            </nz-tabset>
        </nz-card-tab>

        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="dados.obrigacoes"
                  [nzFrontPagination]="false"
                  [nzTotal]="paginations.obrigacoes?.total"
                  [nzPageSize]="paginations.obrigacoes?.per_page"
                  [nzPageIndex]="paginations.obrigacoes?.current_page"
                  *ngIf="tabNumber == 0"
                  [nzShowTotal]="rangeTemplateObrigacoes"
                  nzBordered
                  class="table-small fonte-doze"
                  (nzQueryParams)="getObrigacoes($event, currentsSearches.obrigacoes)">
            <thead>
            <tr>
                <th nzColumnKey="descricao" [nzSortFn]="true">Descrição</th>
                <th nzColumnKey="tipo" [nzSortFn]="true" nzAlign="center">Tipo</th>
                <th nzColumnKey="orgao" [nzSortFn]="true" class="border-right" nzAlign="center">Orgão</th>
                <th nzColumnKey="temDownload" [nzSortFn]="true" class="border-right" nzAlign="center">Tem Download?</th>
                <th nzColumnKey="tipoScalling" [nzSortFn]="true" class="border-right" nzAlign="center">Tipo Scalling /
                    Projeto
                </th>
                <th [nzSortFn]="true" nzColumnKey="emManutencao" nzAlign="center">Manutenção</th>
                <th [nzSortFn]="true" nzColumnKey="ligado" nzAlign="center">Situação</th>
                <th></th>
            </tr>
            </thead>

            <tbody>
            <tr *ngFor="let obrigacao of basicTable.data">
                <td>
                    <p>
                        <small>{{obrigacao.id}}</small><br>
                        <span style="font-size: 14px;">{{obrigacao.descricao}}</span>
                        <nz-tag [nzColor]="'#faad14'" *ngIf="obrigacao.emManutencao"
                                style="margin-left: 10px; font-size: 10px">
                            Em Manutenção
                        </nz-tag>
                    </p>
                </td>
                <td nzAlign="center">{{strTipoObrigacao[obrigacao.tipo]}}</td>
                <td class="border-right" nzAlign="center">{{obrigacao.orgao}}</td>
                <td class="border-right" nzAlign="center">{{obrigacao.temDownload == 1 ? 'Sim' : 'Não'}}</td>
                <td class="border-right" nzAlign="center">{{obrigacao.tipoScalling ? obrigacao.tipoScalling : '-'}}
                    / {{obrigacao.projeto ? obrigacao.projeto : '-'}}
                </td>

                <td nzAlign="center">
                    <span *ngIf="obrigacao.emManutencao != 1">-</span>
                    <div *ngIf="obrigacao.emManutencao == 1">
                        <small *ngIf="obrigacao.manutencaoIdTarefa">(Tarefa {{obrigacao.manutencaoIdTarefa}})</small>
                        <p *ngIf="obrigacao.manutencaoNomeUsuario" class="p-no-border"><small>Por </small>
                            <b>{{obrigacao.manutencaoNomeUsuario}}</b></p>
                        <p *ngIf="obrigacao.manutencaoDataInicio" class="p-no-border"><small>em </small>
                            <b>{{obrigacao.manutencaoDataInicio | date: 'dd/MM/YY'}}
                                às {{obrigacao.manutencaoDataInicio | date: 'HH:mm'}} </b></p>
                        <a *ngIf="obrigacao.manutencaoMotivo" nz-button nzType="link"
                           [nz-tooltip]="obrigacao.manutencaoMotivo"><i class="fa-solid fa-circle-info"></i></a>
                    </div>
                </td>
                <td nzAlign="center">
                    <div style="color: #52c41a" *ngIf="obrigacao.ligado == 1">
                        <i class="fa-solid fa-plug"></i>
                        <br><span>Ligada</span>
                    </div>
                    <div style="color: #c23b4c" *ngIf="obrigacao.ligado != 1">
                        <i class="fa-solid fa-plug-circle-xmark"></i>
                        <br><span>Desligada</span>
                        <p *ngIf="obrigacao.desligadoNomeUsuario" class="p-no-border"><small>Por </small>
                            <b>{{obrigacao.desligadoNomeUsuario}}</b></p>
                        <p *ngIf="obrigacao.desligadoData"><small>em </small>
                            <b>{{obrigacao.desligadoData | date: 'dd/MM/YY'}}
                                às {{obrigacao.desligadoData | date: 'HH:mm'}}</b></p>
                    </div>
                </td>


                <td style="width: 50px;">
                    <button nz-button
                            (click)="onModalAlterarVisible(formEditarObrigacoes, true, obrigacao.id);"
                            [nz-tooltip]="'Alterar status'"
                            nzType="link">
                        Alterar
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplateObrigacoes let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>

        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="dados.domicilios"
                  [nzFrontPagination]="false"
                  [nzTotal]="paginations.domicilios?.total"
                  [nzPageSize]="paginations.domicilios?.per_page"
                  [nzPageIndex]="paginations.domicilios?.current_page"
                  *ngIf="tabNumber == 1"
                  [nzShowTotal]="rangeTemplateDomicilios"
                  nzBordered
                  class="table-small fonte-doze"
                  (nzQueryParams)="getDomicilios($event, currentsSearches.domicilios)">
            <thead>
            <tr>
                <th nzColumnKey="descricao" [nzSortFn]="true">Descrição</th>
                <th nzColumnKey="orgao" [nzSortFn]="true" class="border-right" nzAlign="center">Orgão</th>
                <th nzColumnKey="tipoScalling" [nzSortFn]="true" class="border-right" nzAlign="center">Tipo Scalling /
                    Projeto
                </th>
                <th [nzSortFn]="true" nzColumnKey="emManutencao" nzAlign="center">Manutenção</th>
                <th [nzSortFn]="true" nzColumnKey="ligado" nzAlign="center">Situação</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let domicilio of basicTable.data">
                <td>
                    <p>
                        <small>{{domicilio.id}}</small><br>
                        <span style="font-size: 14px;">{{domicilio.descricao}}</span>
                        <nz-tag [nzColor]="'#faad14'" *ngIf="domicilio.emManutencao"
                                style="margin-left: 10px; font-size: 10px">
                            Em Manutenção
                        </nz-tag>
                    </p>
                </td>
                <td class="border-right" nzAlign="center">{{strOrgaoDomicilio[domicilio.orgao]}}</td>
                <td class="border-right" nzAlign="center">{{domicilio.tipoScalling ? domicilio.tipoScalling : '-'}}
                    / {{domicilio.projeto ? domicilio.projeto : '-'}}
                </td>

                <td nzAlign="center">
                    <span *ngIf="domicilio.emManutencao != 1">-</span>
                    <div *ngIf="domicilio.emManutencao == 1">
                        <small *ngIf="domicilio.manutencaoIdTarefa">(Tarefa {{domicilio.manutencaoIdTarefa}})</small>
                        <p *ngIf="domicilio.manutencaoNomeUsuario" class="p-no-border"><small>Por </small>
                            <b>{{domicilio.manutencaoNomeUsuario}}</b></p>
                        <p *ngIf="domicilio.manutencaoDataInicio" class="p-no-border"><small>em </small>
                            <b>{{domicilio.manutencaoDataInicio | date: 'dd/MM/YY'}}
                                às {{domicilio.manutencaoDataInicio | date: 'HH:mm'}} </b></p>
                        <a *ngIf="domicilio.manutencaoMotivo" nz-button nzType="link"
                           [nz-tooltip]="domicilio.manutencaoMotivo"><i class="fa-solid fa-circle-info"></i></a>
                    </div>
                </td>
                <td nzAlign="center">
                    <div style="color: #52c41a" *ngIf="domicilio.ligado == 1">
                        <i class="fa-solid fa-plug"></i>
                        <br><span>Ligada</span>
                    </div>
                    <div style="color: #c23b4c" *ngIf="domicilio.ligado != 1">
                        <i class="fa-solid fa-plug-circle-xmark"></i>
                        <br><span>Desligada</span>
                        <p *ngIf="domicilio.desligadoNomeUsuario" class="p-no-border"><small>Por </small>
                            <b>{{domicilio.desligadoNomeUsuario}}</b></p>
                        <p *ngIf="domicilio.desligadoData"><small>em </small>
                            <b>{{domicilio.desligadoData | date: 'dd/MM/YY'}}
                                às {{domicilio.desligadoData | date: 'HH:mm'}}</b></p>
                    </div>
                </td>


                <td style="width: 50px;">
                    <button nz-button
                            (click)="onModalAlterarVisible(formEditarDomiciliios, true, domicilio.id);"
                            [nz-tooltip]="'Alterar status'"
                            nzType="link">
                        Alterar
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplateDomicilios let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>

        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="dados.certidoes"
                  [nzFrontPagination]="false"
                  [nzTotal]="paginations.certidoes?.total"
                  [nzPageSize]="paginations.certidoes?.per_page"
                  [nzPageIndex]="paginations.certidoes?.current_page"
                  *ngIf="tabNumber == 2"
                  [nzShowTotal]="rangeTemplateCertidoes"
                  nzBordered
                  class="table-small fonte-doze"
                  (nzQueryParams)="getCertidoes($event, currentsSearches.certidoes)">
            <thead>
            <tr>
                <th nzColumnKey="descricao" [nzSortFn]="true">Descrição</th>
                <th nzColumnKey="orgao" [nzSortFn]="true" class="border-right" nzAlign="center">Orgão</th>
                <th nzColumnKey="tipoScalling" [nzSortFn]="true" class="border-right" nzAlign="center">Tipo Scalling /
                    Projeto
                </th>
                <th [nzSortFn]="true" nzColumnKey="emManutencao" nzAlign="center">Manutenção</th>
                <th [nzSortFn]="true" nzColumnKey="ligado" nzAlign="center">Situação</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let certidao of basicTable.data">
                <td>
                    <p>
                        <small>{{certidao.id}}</small><br>
                        <span style="font-size: 14px;">{{certidao.descricao}}</span>
                        <nz-tag [nzColor]="'#faad14'" *ngIf="certidao.emManutencao"
                                style="margin-left: 10px; font-size: 10px">
                            Em Manutenção
                        </nz-tag>
                    </p>
                </td>
                <td class="border-right" nzAlign="center">{{strOrgaoCeridao[certidao.orgao]}}</td>
                <td class="border-right" nzAlign="center">{{certidao.tipoScalling ? certidao.tipoScalling : '-'}}
                    / {{certidao.projeto ? certidao.projeto : '-'}}

                </td>
                <td nzAlign="center">
                    <span *ngIf="certidao.emManutencao != 1">-</span>
                    <div *ngIf="certidao.emManutencao == 1">
                        <small *ngIf="certidao.manutencaoIdTarefa">(Tarefa {{certidao.manutencaoIdTarefa}})</small>
                        <p *ngIf="certidao.manutencaoNomeUsuario" class="p-no-border"><small>Por </small>
                            <b>{{certidao.manutencaoNomeUsuario}}</b></p>
                        <p *ngIf="certidao.manutencaoDataInicio" class="p-no-border"><small>em </small>
                            <b>{{certidao.manutencaoDataInicio | date: 'dd/MM/Y'}}
                                às {{certidao.manutencaoDataInicio | date: 'HH:mm'}} </b></p>
                        <a *ngIf="certidao.manutencaoMotivo" nz-button nzType="link"
                           [nz-tooltip]="certidao.manutencaoMotivo"><i class="fa-solid fa-circle-info"></i></a>
                    </div>
                </td>
                <td nzAlign="center">
                    <div style="color: #52c41a" *ngIf="certidao.ligado == 1">
                        <i class="fa-solid fa-plug"></i>
                        <br><span>Ligada</span>
                    </div>
                    <div style="color: #c23b4c" *ngIf="certidao.ligado != 1">
                        <i class="fa-solid fa-plug-circle-xmark"></i>
                        <br><span>Desligada</span>
                        <p *ngIf="certidao.desligadoNomeUsuario" class="p-no-border"><small>Por </small>
                            <b>{{certidao.desligadoNomeUsuario}}</b></p>
                        <p *ngIf="certidao.desligadoData"><small>em </small>
                            <b>{{certidao.desligadoData | date: 'dd/MM/YY'}}
                                às {{certidao.desligadoData | date: 'HH:mm'}}</b></p>
                    </div>
                </td>
                <td style="width: 50px;">
                    <button nz-button
                            (click)="onModalAlterarVisible(formEditarCertidoes, true, certidao.id);"
                            [nz-tooltip]="'Alterar status'"
                            nzType="link">
                        Alterar
                    </button>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <ng-template #rangeTemplateCertidoes let-range="range" let-total>
            {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
        </ng-template>
    </nz-card>


    <ng-template #extraTabs>
        <div style="display: flex;">
            <nz-badge *ngIf="tabNumber == 0"
                      [nzCount]="qtdsFiltros.obrigacoes"
                      [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                <button nz-button nzType="primary"
                        style="margin-right: 10px;"
                        (click)="onModalFiltrarVisible(formFiltrarObrigacoes, true);">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>
            <nz-badge *ngIf="tabNumber == 1"
                      [nzCount]="qtdsFiltros.domicilios"
                      [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                <button nz-button nzType="primary"
                        style="margin-right: 10px;"
                        (click)="onModalFiltrarVisible(formFiltrarDomicilios, true);">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>
            <nz-badge *ngIf="tabNumber == 2"
                      [nzCount]="qtdsFiltros.certidoes"
                      [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                <button nz-button nzType="primary"
                        style="margin-right: 10px;"
                        (click)="onModalFiltrarVisible(formFiltrarCertidoes, true);">
                    <i nz-icon nzType="search"></i>
                    Filtros
                </button>
            </nz-badge>
            <nz-input-group *ngIf="tabNumber == 0"
                            nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                <input type="text" nz-input placeholder="Buscar" [(ngModel)]="currentsSearches.obrigacoes">
            </nz-input-group>
            <ng-template #suffixIconButton>
                <button nz-button nzType="primary" nzSearch
                        (click)="getObrigacoes(currentsParams.obrigacoes, currentsSearches.obrigacoes);">
                    <i nz-icon nzType="search"></i></button>
            </ng-template>

            <nz-input-group *ngIf="tabNumber == 1"
                            nzSearch [nzAddOnAfter]="suffixIconButtonDomicilio" style="margin-right: 10px;">
                <input type="text" nz-input placeholder="Buscar" [(ngModel)]="currentsSearches.domicilios">
            </nz-input-group>
            <ng-template #suffixIconButtonDomicilio>
                <button nz-button nzType="primary" nzSearch
                        (click)="getDomicilios(currentsParams.domicilios, currentsSearches.domicilios);">
                    <i nz-icon nzType="search"></i></button>
            </ng-template>

            <nz-input-group *ngIf="tabNumber == 2"
                            nzSearch [nzAddOnAfter]="suffixIconButtonCertidao" style="margin-right: 10px;">
                <input type="text" nz-input placeholder="Buscar" [(ngModel)]="currentsSearches.certidoes">
            </nz-input-group>
            <ng-template #suffixIconButtonCertidao>
                <button nz-button nzType="primary" nzSearch
                        (click)="getCertidoes(currentsParams.certidoes, currentsSearches.certidoes);">
                    <i nz-icon nzType="search"></i></button>
            </ng-template>
            <button nz-button nzType="default" style="margin-right: 10px;"
                    (click)="btnResetSearch();">
                Limpar
            </button>
            <button nz-button nzType="default"
                    style="margin-right: 10px; padding-left: 8px; padding-right: 8px;"
                    (click)="updateTable();" title="Atualizar">
                <i nz-icon nzType="reload"></i>
            </button>
            <button nz-button [nzType]="'default'" (click)="clickEvent()"
                    style="padding-left: 8px; padding-right: 8px;">
                <i class="fas" [ngClass]="!status ? 'fa-expand' : 'fa-compress'"></i>
            </button>
        </div>
    </ng-template>
</nz-content>


<nz-modal [(nzVisible)]="formFiltrarObrigacoes.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="onModalFiltrarVisible(formFiltrarObrigacoes, false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrarObrigacoes.formGroup"
              style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Tipo</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarObrigacoes.formGroup.value.tipo !== '' && formFiltrarObrigacoes.formGroup.value.tipo !== null">
                    <nz-select [nzPlaceHolder]="'Selecione um tipo'" formControlName="tipo" nzAllowClear>
                        <nz-option [nzLabel]="'Acessória'" [nzValue]="'A'"></nz-option>
                        <nz-option [nzLabel]="'Principal'" [nzValue]="'P'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Orgão</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarObrigacoes.formGroup.value.orgao !== '' && formFiltrarObrigacoes.formGroup.value.orgao !== null">
                    <nz-select [nzPlaceHolder]="'Selecione um orgão'" formControlName="orgao" nzAllowClear>
                        <nz-option [nzLabel]="'Federal'" [nzValue]="'Federal'"></nz-option>
                        <nz-option [nzLabel]="'Estadual'" [nzValue]="'Estadual'"></nz-option>
                        <nz-option [nzLabel]="'Previdenciário'" [nzValue]="'Previdenciário'"></nz-option>
                        <nz-option [nzLabel]="'Simples Nacional'" [nzValue]="'Simples Nacional'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Em manutenção</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarObrigacoes.formGroup.value.emManutencao">

                    <nz-select [nzPlaceHolder]="'Selecione'"
                               nzAllowClear
                               formControlName="emManutencao">
                        <nz-option [nzLabel]="'SIM'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'NÃO'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Tem Download</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarObrigacoes.formGroup.value.temDownload">

                    <nz-select [nzPlaceHolder]="'Selecione'"
                               nzAllowClear
                               formControlName="temDownload">
                        <nz-option [nzLabel]="'SIM'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'NÃO'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="onModalFiltrarVisible(formFiltrarObrigacoes, false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="onConfirmarFiltrar('obrigacoes')">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrarDomicilios.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="onModalFiltrarVisible(formFiltrarDomicilios, false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrarDomicilios.formGroup"
              style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Orgão</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarDomicilios.formGroup.value.orgao !== '' && formFiltrarDomicilios.formGroup.value.orgao !== null">
                    <nz-select [nzPlaceHolder]="'Selecione um orgão'" formControlName="orgao" nzAllowClear>
                        <nz-option [nzLabel]="'Federal'" [nzValue]="'federal'"></nz-option>
                        <nz-option [nzLabel]="'Estadual'" [nzValue]="'estadual'"></nz-option>
                        <nz-option [nzLabel]="'Municipal'" [nzValue]="'municipal'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>


            <nz-form-item>
                <nz-form-label [nzSpan]="5">Em manutenção</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarDomicilios.formGroup.value.emManutencao">

                    <nz-select [nzPlaceHolder]="'Selecione'"
                               nzAllowClear
                               formControlName="emManutencao">
                        <nz-option [nzLabel]="'SIM'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'NÃO'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Situação</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarDomicilios.formGroup.value.ligado">

                    <nz-select [nzPlaceHolder]="'Selecione'"
                               nzAllowClear
                               formControlName="ligado">
                        <nz-option [nzLabel]="'Ligada'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Desligada'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="onModalFiltrarVisible(formFiltrarDomicilios, false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="onConfirmarFiltrar('domicilios')">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formFiltrarCertidoes.modalVisible" [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="onModalFiltrarVisible(formFiltrarCertidoes, false)" [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formFiltrarCertidoes.formGroup"
              style="width: 100%;">

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Orgão</nz-form-label>
                <nz-form-control [nzSpan]="14"
                                 nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarCertidoes.formGroup.value.orgao !== '' && formFiltrarCertidoes.formGroup.value.orgao !== null">
                    <nz-select [nzPlaceHolder]="'Selecione um orgão'" formControlName="orgao" nzAllowClear>
                        <nz-option [nzLabel]="'Federal'" [nzValue]="'F'"></nz-option>
                        <nz-option [nzLabel]="'Estadual'" [nzValue]="'E'"></nz-option>
                        <nz-option [nzLabel]="'Municipal'" [nzValue]="'M'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>


            <nz-form-item>
                <nz-form-label [nzSpan]="5">Em manutenção</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarCertidoes.formGroup.value.emManutencao">

                    <nz-select [nzPlaceHolder]="'Selecione'"
                               nzAllowClear
                               formControlName="emManutencao">
                        <nz-option [nzLabel]="'SIM'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'NÃO'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Situação</nz-form-label>
                <nz-form-control [nzSpan]="14" nzValidateStatus="success"
                                 [nzHasFeedback]="formFiltrarCertidoes.formGroup.value.ligado">

                    <nz-select [nzPlaceHolder]="'Selecione'"
                               nzAllowClear
                               formControlName="ligado">
                        <nz-option [nzLabel]="'Ligada'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Desligada'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="onModalFiltrarVisible(formFiltrarCertidoes, false)">Cancelar</button>
        <button nz-button nzType="primary" (click)="onConfirmarFiltrar('certidoes')">
            Confirmar
        </button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formEditarObrigacoes.modalVisible" [nzTitle]="'Editar Obrigação'" [nzClosable]="true"
          (nzOnCancel)="onModalAlterarVisible(formEditarObrigacoes, false)" [nzWidth]="648">
    <div nz-row *nzModalContent>

        <nz-skeleton *ngIf="loadings.modalEditar"></nz-skeleton>

        <div nz-col nzSpan="24">
            <nz-result *ngIf="loadings.salvando" [nzIcon]="extraIconSpin"
                       [nzTitle]="'Salvando obrigação...'">
                <div nz-result-extra>
                    Salvando alterações em todas as bases. Isso pode levar alguns minutos...
                </div>
            </nz-result>
            <ng-template #extraIconSpin><span nz-icon [nzType]="'loading'"></span>
            </ng-template>
        </div>



<!--        <form nz-form [formGroup]="formEditarObrigacoes.formGroup"-->
<!--              style="width: 100%;" *ngIf="!loadings.modalEditar && !loadings.salvando"-->
<!--              [nzLayout]="'vertical'">-->

<!--            <nz-form-item>-->
<!--                <nz-form-label [nzSpan]="20" [nzOffset]="2">Situação</nz-form-label>-->
<!--                <nz-form-control [nzSpan]="20" [nzOffset]="2">-->
<!--                    <nz-switch-->
<!--                            class="switch-emManutencao"-->
<!--                            formControlName="emManutencao"-->
<!--                            (ngModelChange)="changeSwitchManutencao($event, formEditarObrigacoes)"-->
<!--                            [nzCheckedChildren]="checkedTemplate"-->
<!--                            [nzUnCheckedChildren]="unCheckedTemplate"-->
<!--                    ></nz-switch>-->
<!--                    <ng-template #checkedTemplate>-->
<!--                        <span nz-icon nzType="setting" nzTheme="outline" style="margin-right: 10px;"></span> Em-->
<!--                        manutenção-->
<!--                    </ng-template>-->
<!--                    <ng-template #unCheckedTemplate>-->
<!--                        <span nz-icon nzType="check" nzTheme="outline" style="margin-right: 10px;"></span> Ativa-->
<!--                    </ng-template>-->

<!--                </nz-form-control>-->
<!--            </nz-form-item>-->

<!--            <nz-form-item *ngIf="formEditarObrigacoes.formGroup.value.emManutencao">-->
<!--                <nz-form-label [nzSpan]="20" [nzOffset]="2">Data de início</nz-form-label>-->
<!--                <nz-form-control [nzSpan]="20" [nzOffset]="2"-->
<!--                                 [nzErrorTip]="'Campo Data Início Obrigatório'">-->
<!--                    <nz-date-picker formControlName="manutencaoDataInicio" nzFormat="dd/MM/yyyy"-->
<!--                                    style="width: 100%"-->
<!--                    ></nz-date-picker>-->

<!--                </nz-form-control>-->
<!--            </nz-form-item>-->

<!--            <nz-form-item *ngIf="formEditarObrigacoes.formGroup.value.emManutencao">-->
<!--                <nz-form-label [nzSpan]="20" [nzOffset]="2">Motivo</nz-form-label>-->
<!--                <nz-form-control [nzSpan]="20" [nzOffset]="2"-->
<!--                                 [nzErrorTip]="'Campo Motivo Obrigatório'">-->
<!--                    <textarea #inputMotivoObrigacao nz-input formControlName="manutencaoMotivo"></textarea>-->
<!--                </nz-form-control>-->
<!--            </nz-form-item>-->

<!--            <nz-form-item *ngIf="formEditarObrigacoes.formGroup.value.emManutencao">-->
<!--                <nz-form-label [nzSpan]="20" [nzOffset]="2">Data de término <small style="margin-left: 10px;">-->
<!--                    (previsão)</small></nz-form-label>-->
<!--                <nz-form-control [nzSpan]="20" [nzOffset]="2"-->
<!--                                 [nzErrorTip]="'Campo Data Término Obrigatório'">-->
<!--                    <nz-date-picker formControlName="manutencaoDataFim" nzFormat="dd/MM/yyyy"-->
<!--                                    style="width: 100%"-->
<!--                    ></nz-date-picker>-->

<!--                </nz-form-control>-->
<!--            </nz-form-item>-->
<!--        </form>-->

        <form nz-form [formGroup]="formEditarObrigacoes.formGroup"
              style="width: 100%;" *ngIf="!loadings.modalEditar && !loadings.salvando"
              [nzLayout]="'vertical'">


            <nz-form-item>
                <nz-form-label [nzSpan]="20" [nzOffset]="2">Situação</nz-form-label>
                <nz-form-control [nzSpan]="20" [nzOffset]="2">
                    <nz-switch
                            class="switch-ligado"
                            formControlName="ligado"
                            (ngModelChange)="changeSwitchLigado($event, formEditarObrigacoes)"
                            [nzCheckedChildren]="checkedTemplateLigadoObrigacao"
                            [nzUnCheckedChildren]="unCheckedTemplateLigadoObrigacao"
                    ></nz-switch>
                    <ng-template #checkedTemplateLigadoObrigacao>
                        <i class="fa-solid fa-plug" style="margin-right: 10px"></i>Ligada
                    </ng-template>
                    <ng-template #unCheckedTemplateLigadoObrigacao>
                        <i class="fa-solid fa-plug-circle-xmark" style="margin-right: 10px;"></i>Desligada
                    </ng-template>

                </nz-form-control>
            </nz-form-item>

            <nz-divider></nz-divider>

            <nz-form-item>
                <nz-form-label [nzSpan]="20" [nzOffset]="2">Manutenção</nz-form-label>
                <nz-form-control [nzSpan]="20" [nzOffset]="2">
                    <nz-switch
                            class="switch-emManutencao"
                            formControlName="emManutencao"
                            (ngModelChange)="changeSwitchManutencao($event,  formEditarObrigacoes)"
                            [nzCheckedChildren]="checkedTemplate"
                            [nzUnCheckedChildren]="unCheckedTemplate"
                    ></nz-switch>
                    <ng-template #checkedTemplate>
                        <span nz-icon nzType="setting" nzTheme="outline" style="margin-right: 10px;"></span> Sim
                    </ng-template>
                    <ng-template #unCheckedTemplate>
                        <span nz-icon nzType="check" nzTheme="outline" style="margin-right: 10px;"></span> Não
                    </ng-template>

                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="formEditarObrigacoes.formGroup.value.emManutencao">
                <nz-form-label [nzSpan]="20" [nzOffset]="2">Motivo</nz-form-label>
                <nz-form-control [nzSpan]="20" [nzOffset]="2"
                                 [nzErrorTip]="'Campo Motivo Obrigatório'">
                    <textarea #inputMotivoObrigacao nz-input formControlName="manutencaoMotivo"></textarea>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="formEditarObrigacoes.formGroup.value.emManutencao">
                <nz-form-label [nzSpan]="20" [nzOffset]="2">Tarefa</nz-form-label>
                <nz-form-control [nzSpan]="20" [nzOffset]="2"
                                 [nzErrorTip]="'Campo Tarefa Obrigatório'">
                    <input type="text" nz-input placeholder="DM-9999" formControlName="manutencaoIdTarefa">
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="onModalAlterarVisible(formEditarObrigacoes, false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.salvando"
                (click)="onConfirmarEditar(formEditarObrigacoes)" [disabled]="!formEditarObrigacoes.formGroup.valid">
            Confirmar
        </button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="formEditarDomiciliios.modalVisible" [nzTitle]="'Editar Domicílio Eletrônico'"
          [nzClosable]="true"
          (nzOnCancel)="onModalAlterarVisible(formEditarDomiciliios, false)" [nzWidth]="648">
    <div nz-row *nzModalContent>

        <nz-skeleton *ngIf="loadings.modalEditar"></nz-skeleton>

        <div nz-col nzSpan="24">
            <nz-result *ngIf="loadings.salvando" [nzIcon]="extraIconSpin"
                       [nzTitle]="'Salvando domicílio eletrônico...'">
                <div nz-result-extra>
                    Salvando alterações em todas as bases. Isso pode levar alguns minutos...
                </div>
            </nz-result>
            <ng-template #extraIconSpin><span nz-icon [nzType]="'loading'"></span>
            </ng-template>
        </div>


        <form nz-form [formGroup]="formEditarDomiciliios.formGroup"
              style="width: 100%;" *ngIf="!loadings.modalEditar && !loadings.salvando"
              [nzLayout]="'vertical'">

            <nz-form-item>
                <nz-form-label [nzSpan]="20" [nzOffset]="2">Situação</nz-form-label>
                <nz-form-control [nzSpan]="20" [nzOffset]="2">
                    <nz-switch
                            class="switch-ligado"
                            formControlName="ligado"
                            (ngModelChange)="changeSwitchLigado($event, formEditarDomiciliios)"
                            [nzCheckedChildren]="checkedTemplateLigado"
                            [nzUnCheckedChildren]="unCheckedTemplateLigado"
                    ></nz-switch>
                    <ng-template #checkedTemplateLigado>
                        <i class="fa-solid fa-plug" style="margin-right: 10px"></i>Ligada
                    </ng-template>
                    <ng-template #unCheckedTemplateLigado>
                        <i class="fa-solid fa-plug-circle-xmark" style="margin-right: 10px;"></i>Desligada
                    </ng-template>

                </nz-form-control>
            </nz-form-item>

            <nz-divider></nz-divider>

            <nz-form-item>
                <nz-form-label [nzSpan]="20" [nzOffset]="2">Manutenção</nz-form-label>
                <nz-form-control [nzSpan]="20" [nzOffset]="2">
                    <nz-switch
                            class="switch-emManutencao"
                            formControlName="emManutencao"
                            (ngModelChange)="changeSwitchManutencao($event,  formEditarDomiciliios)"
                            [nzCheckedChildren]="checkedTemplate"
                            [nzUnCheckedChildren]="unCheckedTemplate"
                    ></nz-switch>
                    <ng-template #checkedTemplate>
                        <span nz-icon nzType="setting" nzTheme="outline" style="margin-right: 10px;"></span> Sim
                    </ng-template>
                    <ng-template #unCheckedTemplate>
                        <span nz-icon nzType="check" nzTheme="outline" style="margin-right: 10px;"></span> Não
                    </ng-template>

                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="formEditarDomiciliios.formGroup.value.emManutencao">
                <nz-form-label [nzSpan]="20" [nzOffset]="2">Motivo</nz-form-label>
                <nz-form-control [nzSpan]="20" [nzOffset]="2"
                                 [nzErrorTip]="'Campo Motivo Obrigatório'">
                    <textarea #inputMotivoDomicilio nz-input formControlName="manutencaoMotivo"></textarea>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="formEditarDomiciliios.formGroup.value.emManutencao">
                <nz-form-label [nzSpan]="20" [nzOffset]="2">Tarefa</nz-form-label>
                <nz-form-control [nzSpan]="20" [nzOffset]="2"
                                 [nzErrorTip]="'Campo Tarefa Obrigatório'">
                    <input type="text" nz-input placeholder="DM-9999" formControlName="manutencaoIdTarefa">
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="onModalAlterarVisible(formEditarDomiciliios, false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.salvando"
                (click)="onConfirmarEditar(formEditarDomiciliios)" [disabled]="!formEditarDomiciliios.formGroup.valid">
            Confirmar
        </button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="formEditarCertidoes.modalVisible" [nzTitle]="'Editar Certidão'" [nzClosable]="true"
          (nzOnCancel)="onModalAlterarVisible(formEditarCertidoes, false)" [nzWidth]="648">
    <div nz-row *nzModalContent>

        <nz-skeleton *ngIf="loadings.modalEditar"></nz-skeleton>

        <div nz-col nzSpan="24">
            <nz-result *ngIf="loadings.salvando" [nzIcon]="extraIconSpin"
                       [nzTitle]="'Salvando certidão...'">
                <div nz-result-extra>
                    Salvando alterações em todas as bases. Isso pode levar alguns minutos...
                </div>
            </nz-result>
            <ng-template #extraIconSpin><span nz-icon [nzType]="'loading'"></span>
            </ng-template>
        </div>


        <form nz-form [formGroup]="formEditarCertidoes.formGroup"
              style="width: 100%;" *ngIf="!loadings.modalEditar && !loadings.salvando"
              [nzLayout]="'vertical'">


            <nz-form-item>
                <nz-form-label [nzSpan]="20" [nzOffset]="2">Situação</nz-form-label>
                <nz-form-control [nzSpan]="20" [nzOffset]="2">
                    <nz-switch
                            class="switch-ligado"
                            formControlName="ligado"
                            (ngModelChange)="changeSwitchLigado($event, formEditarCertidoes)"
                            [nzCheckedChildren]="checkedTemplateLigado"
                            [nzUnCheckedChildren]="unCheckedTemplateLigado"
                    ></nz-switch>
                    <ng-template #checkedTemplateLigado>
                        <i class="fa-solid fa-plug" style="margin-right: 10px"></i>Ligada
                    </ng-template>
                    <ng-template #unCheckedTemplateLigado>
                        <i class="fa-solid fa-plug-circle-xmark" style="margin-right: 10px;"></i>Desligada
                    </ng-template>

                </nz-form-control>
            </nz-form-item>


            <nz-divider></nz-divider>

            <nz-form-item>
                <nz-form-label [nzSpan]="20" [nzOffset]="2">Manutenção</nz-form-label>
                <nz-form-control [nzSpan]="20" [nzOffset]="2">
                    <nz-switch
                            class="switch-emManutencao"
                            formControlName="emManutencao"
                            (ngModelChange)="changeSwitchManutencao($event,  formEditarCertidoes)"
                            [nzCheckedChildren]="checkedTemplate"
                            [nzUnCheckedChildren]="unCheckedTemplate"
                    ></nz-switch>
                    <ng-template #checkedTemplate>
                        <span nz-icon nzType="setting" nzTheme="outline" style="margin-right: 10px;"></span> Sim
                    </ng-template>
                    <ng-template #unCheckedTemplate>
                        <span nz-icon nzType="check" nzTheme="outline" style="margin-right: 10px;"></span> Não
                    </ng-template>

                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="formEditarCertidoes.formGroup.value.emManutencao">
                <nz-form-label [nzSpan]="20" [nzOffset]="2">Motivo</nz-form-label>
                <nz-form-control [nzSpan]="20" [nzOffset]="2"
                                 [nzErrorTip]="'Campo Motivo Obrigatório'">
                    <textarea #inputMotivoCertidao nz-input formControlName="manutencaoMotivo"></textarea>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="formEditarCertidoes.formGroup.value.emManutencao">
                <nz-form-label [nzSpan]="20" [nzOffset]="2">Tarefa</nz-form-label>
                <nz-form-control [nzSpan]="20" [nzOffset]="2"
                                 [nzErrorTip]="'Campo Tarefa Obrigatório'">
                    <input type="text" nz-input placeholder="DM-9999" formControlName="manutencaoIdTarefa">
                </nz-form-control>
            </nz-form-item>


        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="onModalAlterarVisible(formEditarCertidoes, false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="loadings.salvando"
                (click)="onConfirmarEditar(formEditarCertidoes)" [disabled]="!formEditarCertidoes.formGroup.valid">
            Confirmar
        </button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="modalErrosVisible" [nzTitle]="'Erros!'" [nzClosable]="false"
          [nzWidth]="648" nzIconType="'error'">
    <div nz-row *nzModalContent>
        <p>Ops. Algumas contas enfrentaram problemas em atualizar o registro de baixa. Contate o suporte para devidas
            ações:</p>


        <ul nz-list [nzDataSource]="erros" nzBordered style="width: 100%" nzSize="small">
            <li nz-list-item *ngFor="let erro of erros" nzNoFlex>
                <ul nz-list-item-actions>
                    <nz-list-item-action>
                        <span nz-icon nzType="info-circle" nzTheme="outline" style="margin-left: 10px;"
                              [nz-tooltip]="erro.erro" [nzTooltipColor]="'volcano'"></span>
                    </nz-list-item-action>
                </ul>
                Conta: <b>{{erro.conta}}</b>
            </li>
        </ul>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="primary"
                (click)="onModalErrosVisible(false)">
            Tudo bem
        </button>
    </div>
</nz-modal>
