import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class DataService {

    private messageSource = new BehaviorSubject(null);
    currentUser = this.messageSource.asObservable();

    constructor() {
    }

    changeMessage(user) {
        this.messageSource.next(user);
    }

}
