import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Response} from '@models/response.model';
import {TableTitles} from '@models/table-titles.model';
import {Utils} from '../../shared/utils';
import BulkChanges from '@models/entity.model';

export abstract class AbstractService<E> {

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            responseType: 'json',
            'X-Requested-With': 'XMLHttpRequest'
        }),
    };

    public baseUrl: string;
    public apiUrl: string;

    protected constructor(protected http: HttpClient, private path: string, protected object) {
        this.baseUrl = `${environment.apiUrl}${path}`;
        this.apiUrl = environment.apiUrl;
    }

    list(page: number = 1): Observable<Response<E>> {
        const baseUrl = `${this.baseUrl}?page=${page}`;
        return this.http.get<Response<E>>(baseUrl);
    }

    get(id: number | string): Observable<E> {
        return this.http.get<E>(`${this.baseUrl}/${id}`);
    }

    getAll(page: number = 1, filter?: any, generalFilter?: string): Promise<Response<E>> {
        let baseUrl = `${this.baseUrl}?page=${page}`;

        let params = new HttpParams();

        if (generalFilter) {
            baseUrl = `${this.baseUrl}/busca/${generalFilter}`;

            // params = params.append('general', generalFilter);
        }

        if (filter) {
            baseUrl = `${this.baseUrl}/filtro`;

            const filtered = Object.entries(filter)
                .filter(([_, value]: [string, any]) => value !== '');

            filtered.forEach(([key, value]) => {
                if (value) {
                    params = params.append(key.toString(), value.toString());
                }
            });
        }

        return this.http.get<Response<E>>(`${baseUrl}`,
            {
                params
            }).toPromise();
    }

    save(object: E, path: string = '', key = 'id'): Observable<E> {

        const url = Utils.isEmpty(path) ? this.baseUrl : `${this.baseUrl}${path}/`;

        if (!Utils.isEmpty(object[key]) || object.hasOwnProperty(key)) {

            return this.http.put<E>(`${url}/${object[key]}`, object, this.httpOptions);

        }

        return this.http.post<E>(url, object, this.httpOptions);

    }

    orderBy(title: TableTitles): Observable<Response<E>> {
        const baseUrl = `${this.baseUrl}?order=${title.field}&by=${title.getOrder()}`;
        return this.http.get<Response<E>>(`${baseUrl}`);
    }

    delete(object: E, key = 'id'): Observable<E> {

        return this.http.delete<E>(`${this.baseUrl}/${object[key]}`, {headers: this.httpOptions.headers});

    }

    bulk(modifierID: string | string[], companies: Set<string>, entity: BulkChanges, router: string): Observable<any> {
        const baseUrl = `${this.baseUrl}/${router}`;

        const ids = [];
        const description = !(modifierID && modifierID.length === 36) ? modifierID : null;

        modifierID = description ? null : modifierID;
        companies.forEach((companyID: string) =>
            ids.push(companyID)
        );

        return this.http.post(`${baseUrl}`, entity.toBulk(modifierID, description, ids), this.httpOptions);
    }


    bulkChange(selectedIds: Set<string> | string[], changeFields, router): Observable<any> {

        const data = {

            ids: Array.from(selectedIds),
            campos: changeFields

        };

        return this.http.put(`${this.baseUrl}/${router}`, data, this.httpOptions);
    }

    bulkAppend(selectedIds: Set<string> | string[], itens, clearBefore = true, router): Observable<any> {

        const data = {

            ids: Array.from(selectedIds),
            itens,
            limpar: clearBefore

        };

        return this.http.put(`${this.baseUrl}/${router}`, data, this.httpOptions);
    }

    listToSelect(): Observable<E[]> {
        return this.http.get<E[]>(`${this.baseUrl}/listar`);
    }

    listToTable(params, search: string = null, baseUrl = null): Observable<any> {

        const {pageSize, pageIndex, sort, filter} = params;
        const currentSort = sort.find(item => item.value !== null);
        const sortField = (currentSort && currentSort.key) || null;
        const sortOrder = (currentSort && currentSort.value) || null;

        const queryParams = [];

        if (pageIndex !== null) {
            queryParams.push(`pagina=${pageIndex}`);
        }

        if (pageSize !== null && typeof pageSize === 'number') {
            queryParams.push(`quantidade=${pageSize}`);
        }

        if (sortField !== null) {
            queryParams.push(`ordenar=${sortField}`);
            queryParams.push(`sentido=${sortOrder}`);
        }

        if (search !== null) {
            queryParams.push(`procurar=${search}`);
        }

        if (params.filter) {
            params.filter.forEach((obj) => {
                if (obj.key && obj.value) {
                    queryParams.push(obj.key + '=' + obj.value);
                }
            });
        }

        const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';

        const prefix = baseUrl ? baseUrl : this.baseUrl;

        return this.http.get(`${prefix}${queryString}`);

    }


    resolve() {
        return this.list();
    }

    montaQueryString(filtros): string {
        const queryParams = [];
        for (const [key, value] of Object.entries(filtros)) {
            if (value) {
                queryParams.push(key + '=' + value);
            }
        }
        return queryParams.length > 0 ? '?' + queryParams.join('&') : '';
    }


    getPermissoesPerfil(): Observable<any> {

        const perfilId = localStorage.getItem('perfil_id');

        return this.http.get(`${environment.apiUrl}/administracao/perfil/${perfilId}/detalhe`);

    }




}

