import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Usuario} from '@models/usuario.model';
import {AbstractService} from './abstract.service';
import {Observable} from 'rxjs';
import {formatDate} from '@angular/common';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class AvisoService extends AbstractService<Usuario> {

    constructor(http: HttpClient) {
        super(http, '/administracao/avisos', Usuario);
    }

    exportExcel(filtros: any, search: string = null, tipo: string): any {

        filtros.tipoExportacao = tipo;

        const queryStr = this.montaQueryString(filtros);

        return this.http.get(`${environment.apiUrl}/contas/exportar${queryStr}`,
            {responseType: 'blob'});

    }

    save(campos): Observable<any> {

        if (campos.dataInicio) {
            campos.dataInicio = formatDate(campos.dataInicio, 'YYYY-MM-dd HH:mm:ss', 'pt_BR');
        }
        if (campos.dataFim) {
            campos.dataFim = formatDate(campos.dataFim, 'YYYY-MM-dd HH:mm:ss', 'pt_BR');
        }

        return this.http.post(`${environment.apiUrl}/administracao/avisos`, campos);

    }

    comboPerfis(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/perfis/select`);
    }

    getComboContas(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/filtros/contas/select`);
    }

    remover(id: any): Observable<any> {

        return this.http.delete(`${environment.apiUrl}/administracao/avisos/${id}`);

    }

    statusEmMassa(ids: any, status: number): Observable<any> {

        const dados = {
            ids,
            ativo: status
        };

        return this.http.put(`${environment.apiUrl}/administracao/avisos/statusEmMassa`, dados);

    }


}
