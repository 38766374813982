import {ChangeDetectorRef, Component, ElementRef, HostListener, NgZone, OnInit, ViewChild} from '@angular/core';
import {ContasLimitesService} from './contas-limites.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {ToastrService} from 'ngx-toastr';
import {Pagination} from '@models/pagination.model';
import {buildUrl, findComponentByUrl} from '../../../shared/components-helper';
import {Tab} from '@models/tab.model';
import {TabService} from '@services/tab.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {ContaService} from '@services/conta.service';

interface FormStack {
    modalVisible: boolean;
    formGroup: UntypedFormGroup;
}

@Component({
    selector: 'app-contas-limites',
    templateUrl: './contas-limites.component.html',
    styleUrls: ['./contas-limites.component.scss']
})
export class ContasLimitesComponent implements OnInit {

    currentParams: NzTableQueryParams = {
        pageIndex: 1,
        pageSize: 1000,
        sort: [{
            key: '',
            value: 'ascend',
        }],
        filter: []
    };
    currentSearch: string;

    pagination: Pagination;

    loadingDados = false;

    statusCard = false;

    dados = [];

    selectClassificacao = [
        {label: 'Prime', value: 'prime'},
        {label: 'Super', value: 'super'},
        {label: 'Excellence', value: 'excellence'}
    ];

    selectModulos = [];
    selectContas = [];

    dataUltimoProcessamento: any;

    expandSetDados = new Set<string>();

    @ViewChild('column1') column1: ElementRef;
    column1Width = 0;

    @ViewChild('column2') column2: ElementRef;
    column2Width = 0;

    @ViewChild('column3') column3: ElementRef;
    column3Width = 0;

    @ViewChild('column4') column4: ElementRef;
    column4Width = 0;

    @ViewChild('column5') column5: ElementRef;
    column5Width = 0;

    @ViewChild('column6') column6: ElementRef;
    column6Width = 0;

    @ViewChild('column7') column7: ElementRef;
    column7Width = 0;

    @ViewChild('column8') column8: ElementRef;
    column8Width = 0;

    expandAll = true;

    qtdFiltrosAtivos = 0;

    formFiltros: FormStack;

    opcoesLoading = false;

    comboPlanos: any = [];
    comboVisibilidades: any = [];

    constructor(
        public contasLimitesService: ContasLimitesService,
        private cdr: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        private zone: NgZone,
        private toastService: ToastrService,
        private tabService: TabService,
        private modalService: NzModalService,
        private contaService: ContaService,
    ) {
        this.pagination = new Pagination();

        this.formFiltros = {
            modalVisible: false,
            formGroup: this.fb.group({
                conta: [null, null],
                classificacao: [null, null],
                modulo: [null, null],
                tipo: [null, null],
                inativo: [null, null],
                plano_id: [null, null],
                visibilidade_id: [null, null]
            })
        };

        this.getContas(this.currentParams.filter);

        this.exibirTudo(false);

        this.contasLimitesService.retornarSelectsModulos().subscribe((retorno: any) => {
            this.selectModulos = retorno;

        });

        this.contasLimitesService.retornarSelectsContas().subscribe((retorno: any) => {
            this.selectContas = retorno;
        });
    }

    @HostListener('window:resize', ['$event'])
    resizeTable(event?) {
        this.toggleParamsCard();
    }

    ngOnInit(): void {
        this.getComboPlanos();
        this.getComboVisibilidades();
    }

    getComboPlanos() {
        this.contaService.comboPlanos().subscribe((resultado) => {
            this.comboPlanos = [];
            this.comboPlanos = resultado;
        }, (res) => {
            this.toastService.error('Erro ao obter planos');
        });
    }

    getComboVisibilidades() {
        this.contaService.comboVisibilidades().subscribe((resultado) => {
            this.comboVisibilidades = [];
            this.comboVisibilidades = resultado;
        }, (res) => {
            this.toastService.error('Erro ao obter visibildiades');
        });
    }

    openTab(componentName: string, queryParams?: string, data?: any) {
        const component = findComponentByUrl(componentName);
        const url = buildUrl(component, queryParams);
        let title: string;
        title = data.nome + ' - Detalhe da Conta';
        const newTab = new Tab(component.name, title, url, component.urlType, data);
        this.tabService.addTab(newTab);
    }

    listByTableContas(params: NzTableQueryParams) {
        let buscar = false;

        if (params.pageIndex === this.pagination.current_page) {
            params.sort.forEach(s => {
                if (s.value) {
                    buscar = true;
                }
            });
        }

        if ((buscar || params.pageIndex === 1 && this.pagination.current_page > 1)
            || params.pageIndex > 1
            && params.pageIndex !== this.pagination.current_page) {
            this.getContas(this.currentParams.filter, params);
            this.pagination.current_page = params.pageIndex;
        }

    }

    getContas(filtros?: any, params = null) {

        if (params){
            const {pageSize, pageIndex, sort} = params;
            const currentSort = sort.find(item => item.value !== null);
            const sortField = (currentSort && currentSort.key) || null;
            const sortOrder = (currentSort && currentSort.value) || null;

            if (pageIndex !== null) {
                filtros.pagina = pageIndex;
            }

            if (pageSize !== null && typeof pageSize === 'number') {
                filtros.quantidade = 50;
            }

            if (sortField !== null) {
                filtros.ordenar = sortField;
                filtros.sentido = sortOrder;
            }
        }


        this.loadingDados = true;

        filtros.procurar = this.currentSearch;

        this.zone.run(() => {
            this.contasLimitesService.getContas(filtros).subscribe((response: any) => {

                this.dados = response.data;

                this.dataUltimoProcessamento = response.data[0]?.ultimaAtualizacao ? response.data[0]?.ultimaAtualizacao : '';

                this.pagination = new Pagination(
                    response?.per_page || 50,
                    response?.current_page || 1,
                    response?.last_page || 1,
                    response?.total || 50);

                this.dados.forEach(d => {
                    this.expandSetDados.add(d.identificador);
                });

                this.exibirTudo(true);

                setTimeout(() => {
                    this.toggleParamsCard();
                    this.cdr.detectChanges();
                    this.loadingDados = false;
                }, 100);

            }, error => {
                this.toastService.error(error.mesage);
                this.loadingDados = false;
            });
        });
    }

    abrirModal(formulario: FormStack): void {
        formulario.modalVisible = true;
    }

    fechar(formulario: FormStack): void {
        formulario.modalVisible = false;
    }

    buscar() {
        this.currentParams.pageIndex = 1;
        this.getContas(this.currentParams.filter);
    }

    filtrar() {

        this.formFiltros.modalVisible = false;
        this.currentParams.filter = this.formFiltros.formGroup.value;
        this.currentParams.pageIndex = 1;

        this.calculaBadgeFiltros();

        this.getContas(this.currentParams.filter);

    }

    toggleParamsCard() {

        setTimeout(() => {
            this.column1Width = this.column1.nativeElement.offsetWidth;
            this.column2Width = this.column2.nativeElement.offsetWidth;
            this.column3Width = this.column3.nativeElement.offsetWidth;
            this.column4Width = this.column4.nativeElement.offsetWidth;
            this.column5Width = this.column5.nativeElement.offsetWidth;
            this.column6Width = this.column6.nativeElement.offsetWidth;
            this.column7Width = this.column7.nativeElement.offsetWidth;
            this.column8Width = this.column8.nativeElement.offsetWidth;

            this.cdr.detectChanges();
        }, 100);

    }

    onExpandChangeDados(identificador: string, checked: boolean): void {

        if (checked) {
            this.expandSetDados.add(identificador);
        } else {
            this.expandSetDados.delete(identificador);
        }

        this.column1Width = this.column1.nativeElement.offsetWidth;
        this.column2Width = this.column2.nativeElement.offsetWidth;
        this.column3Width = this.column3.nativeElement.offsetWidth;
        this.column4Width = this.column4.nativeElement.offsetWidth;
        this.column5Width = this.column5.nativeElement.offsetWidth;
        this.column6Width = this.column6.nativeElement.offsetWidth;

    }

    exibirTudo(exibir: boolean) {
        this.expandAll = exibir;

        if (this.expandAll) {
            this.dados.forEach(d => {
                this.expandSetDados.add(d.identificador);
            });
        } else {

            this.dados.forEach(d => {
                this.expandSetDados.delete(d.identificador);
            });
        }
    }

    confirmAtualizarUtilizados() {
        this.modalService.confirm({
            nzTitle: 'Essa opção irá realizar a atualizaçao dos valores utilizados de todas as contas. Deseja prosseguir?',
            nzOkText: 'Confirmar',
            nzCancelText: 'Cancelar',
            nzOnOk: () => this.atualizarUtilizados()
        });

    }

    atualizarUtilizados() {
        this.opcoesLoading = true;
        this.contasLimitesService.atualizarUtilizados().subscribe((response: any) => {

            this.toastService.success(response.mesage);
            this.getContas(this.currentParams.filter);

            this.opcoesLoading = false;

        }, error => {
            this.toastService.error(error.mesage);
            this.opcoesLoading = false;
        });

    }

    limparFiltros(): void {
        this.formFiltros.formGroup.reset();
        this.currentSearch = null;
        this.currentParams.filter = [];
        this.calculaBadgeFiltros();
        this.getContas(this.currentParams.filter);
    }

    calculaBadgeFiltros(): void {

        let qtd = 0;

        Object.entries(this.formFiltros.formGroup.value).forEach((value) => {

            if (value[0] !== 'ano' && value[0] !== 'sort' && value[0] !== 'page' && value[1]) {
                qtd += 1;
            }

        });

        this.qtdFiltrosAtivos = qtd;

    }

    clickEvent() {
        this.statusCard = !this.statusCard;
    }

}
