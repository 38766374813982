<nz-page-header class="site-page-header" nzTitle="Administração" nzSubtitle="Gerenciamento de Avisos">
</nz-page-header>
<nz-content>
    <nz-card [nzExtra]="cardExtra" nzTitle="Avisos"
             [nzLoading]="loading" [nzBodyStyle]="{'padding': loading ? '15px' : '0px'}"
             [ngClass]="expandedCard ? 'card-fullscreen' : ''">
        <ng-template #cardExtra>
            <div class="nav-item d-flex">

                <div class="nav-item">
                    <button nz-button nzType="primary" style="margin-right: 10px;" (click)="modalCadastrar(true);"
                            *ngIf="hasPermissao(screenPermissoes.cadastrar, currentPermissoes)">
                        Cadastrar
                    </button>
                </div>

                <nz-badge [nzCount]="qtdFiltrosAtivos"
                          [nzStyle]="{ backgroundColor: '#52c41a', 'margin-right' : '10px'}">
                    <button nz-button nzType="primary"
                            style="margin-right: 10px;"
                            (click)="modalFiltrar(true);">
                        <i nz-icon nzType="search"></i>
                        Filtros
                    </button>
                </nz-badge>
                <button class="centralized-icon" [nzDropdownMenu]="menuAcoes"
                        [nzType]="'default'" nz-button nz-dropdown style="margin-right: 10px;">
                    Opções
                    <em nz-icon nzType="down"></em>
                </button>
                <nz-dropdown-menu #menuAcoes="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-submenu nzTitle="Manutenção Coletiva">
                            <ul>
                                <li nz-menu-item nzDisabled>Ações</li>
                                <li nz-menu-divider></li>
                                <li (click)="onAtivarDesativar()" [nzDisabled]="checkedItems?.size === 0"
                                    *ngIf="hasPermissao(screenPermissoes.ativarDesativar, currentPermissoes)"
                                    nz-menu-item>
                                    Ativar/Desativar Selecionados
                                </li>
                            </ul>
                        </li>
<!--                        <li nz-menu-item nz-button (click)="modalExportar(true);">Exportar Planilha</li>-->
                    </ul>
                </nz-dropdown-menu>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" style="margin-right: 10px;">
                    <input type="text" nz-input placeholder="Busca" [(ngModel)]="currentSearch"/>
                </nz-input-group>
                <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" nzSearch (click)="queryTable(currentParams, currentSearch);">
                        <i nz-icon nzType="search"></i></button>
                </ng-template>
                <button nz-button nzType="default" style="margin-right: 10px;"
                        (click)="btnResetSearch();">
                    Limpar
                </button>
                <button nz-button [nzType]="'default'" (click)="clickEvent()"
                        style="padding-left: 8px; padding-right: 8px;">
                    <i class="fas" [ngClass]="!expandedCard ? 'fa-expand' : 'fa-compress'"></i>
                </button>
            </div>
        </ng-template>

        <nz-table #basicTable
                  nzSize="small"
                  [nzData]="items"
                  [nzFrontPagination]="false"
                  [nzTotal]="pagination?.total"
                  [nzPageSize]="pagination?.per_page"
                  [nzPageIndex]="pagination?.current_page"
                  [nzShowTotal]="rangeTemplate"
                  (nzQueryParams)="queryTable($event)"
                  class="fonte-doze table-small">
            <thead>
            <tr>
                <th (nzCheckedChange)="onAllChecked($event)" [nzChecked]="checked" [nzIndeterminate]="indeterminate"
                    id="check-all">
                </th>
                <th nzColumnKey="mensagem" [nzSortFn]="true" nzAlign="center" style="max-width: 400px;">Mensagem</th>
                <th [nzSortFn]="false" [nzShowSort]="false" nzAlign="center">Contas</th>
                <th nzColumnKey="dataInicio" [nzSortFn]="true" nzAlign="center">Data Início</th>
                <th nzColumnKey="dataFim" [nzSortFn]="true" nzAlign="center">Data Fim</th>
                <th nzColumnKey="ativo" [nzSortFn]="true" nzAlign="center">Status</th>
                <th nzColumnKey="created_at" [nzSortFn]="true" nzAlign="center">Criado em</th>
                <th [ngStyle]="{'width' : '80px'}"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let aviso of basicTable.data">
                <td nzLeft (nzCheckedChange)="onItemChecked(aviso.id, $event)"
                    [nzChecked]="checkedItems.has(aviso.id)"
                    [nzDisabled]="aviso.disabled">
                </td>
                <td nzAlign="left" style="min-width: 500px; max-width: 600px;">
                    <div [innerHTML]="aviso?.mensagem" class="alert-{{aviso?.tipo}}"></div>
                </td>
                <td nzAlign="left">
                    <ul style="margin-left: 20px;">
                        <li *ngFor="let conta of aviso?.contas">{{conta}}</li>
                    </ul>
                    <ul style="margin-left: 20px;" *ngIf="!aviso.contas">
                        <li>Todas</li>
                    </ul>

                </td>
                <td nzAlign="center">{{aviso?.dataInicio | date:'dd/MM/YY - HH:mm:ss'}}</td>
                <td nzAlign="center">{{aviso?.dataFim | date:'dd/MM/YY - HH:mm:ss'}}</td>
                <td nzAlign="center">{{aviso?.ativo === 1 ? 'Ativo' : 'Inativo'}}</td>
                <td nzAlign="center">{{aviso?.created_at | date:'dd/MM/YY - HH:mm:ss'}}</td>
                <td nzAlign="right" style="white-space: nowrap;">
                    <button nz-button
                            nzType="link"
                            (click)="openTab('/administracao/avisos/aviso-detalhe', aviso.id, {id: aviso.id})">
                        Editar
                    </button>
                    <nz-divider nzType="vertical"></nz-divider>
                    <button nz-button nzType="link"
                            (click)="showConfirmRemover(aviso.id)">
                        Remover
                    </button>
                </td>
            </tr>
            </tbody>
            <ng-template #rangeTemplate let-range="range" let-total>
                {{ range[0] }}-{{ range[1] }} de {{ total }} Registros
            </ng-template>
        </nz-table>
    </nz-card>

</nz-content>


<nz-modal *ngIf="comboPerfis?.length" [(nzVisible)]="displayProfileModal"
          [nzAutofocus]="null" [nzFooter]="modalFooter" nzTitle="Alterar Perfil de Acesso">
    <ng-template nzModalContent>
        <nz-select [(ngModel)]="selectedProfile"
                   [nzPlaceHolder]="'Selecione'" style="width: 100%;">
            <nz-option *ngFor="let option of comboPerfis" [nzLabel]="option.label"
                       [nzValue]="option.value"></nz-option>
        </nz-select>
    </ng-template>
    <ng-template #modalFooter>
        <button (click)="saveBulkToProfiles()" nz-button nzType="primary">Ok</button>
        <button (click)="handleCancel()" nz-button nzType="default">Cancelar</button>
    </ng-template>
</nz-modal>

<nz-modal *ngIf="visibilidadeOptions?.length" [(nzVisible)]="displayVisibilidadeModal"
          [nzAutofocus]="null" [nzFooter]="modalFooter" nzTitle="Alterar Visibilidades" (nzOnCancel)="handleCancel()">
    <ng-template nzModalContent>
        <nz-select [(ngModel)]="selectedVisibilidades"
                   [nzPlaceHolder]="'Selecione as visibilidades'"
                   nzMode="multiple">
            <nz-option *ngFor="let option of visibilidadeOptions" [nzLabel]="option.label"
                       [nzValue]="option.value"></nz-option>
        </nz-select>
        <label nz-checkbox [(ngModel)]="limparVisibilidades">Limpar as já cadastradas anteriormente</label>
    </ng-template>

    <ng-template #modalFooter>
        <button (click)="addNewVisibilidade()" class="new-markup-bnt" nz-button nzType="link">
            <em nz-icon nzType="plus-circle"></em>Nova visibilidade
        </button>
        <button (click)="vincularVisibilidades()" nz-button nzType="primary">Ok</button>
        <button (click)="handleCancel()" nz-button nzType="default">Cancelar</button>
    </ng-template>
</nz-modal>

<nz-modal (nzOnCancel)="closeNewVisibilidade()" (nzOnOk)="adicionarNovaVisibilidade()"
          [(nzVisible)]="newVisibilidadeModal"
          [nzAutofocus]="null"
          nzTitle="Nova Visibilidade">
    <ng-template nzModalContent>
        <nz-form-item>
            <label for="novaVisibilidade"></label>
            <input [(ngModel)]="newVisibilidade" id="novaVisibilidade" nz-input placeholder="descrição" type="text">
        </nz-form-item>
    </ng-template>
</nz-modal>

<nz-modal [(nzVisible)]="formCadastrar.modalVisible" [nzTitle]="'Cadastrar Novo Aviso'" [nzClosable]="true"
          (nzOnCancel)="modalCadastrar(false)"
          [nzWidth]="720">
    <div nz-row *nzModalContent>
        <form nz-form [nzDisableAutoTips]="true" [nzNoColon]="true" [formGroup]="formCadastrar.formGroup">
            <nz-form-item>
                <nz-form-label [nzSpan]="3"
                               nzTooltipTitle="Em branco: Enviará para todas as contas."
                >Contas
                </nz-form-label>
                <nz-form-control [nzSpan]="18">
                    <nz-select formControlName="contas" [nzPlaceHolder]="'Todas'" nzMode="multiple">
                        <nz-option *ngFor="let option of comboContas" [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>

                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="3" nzRequired>Tipo</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <nz-select formControlName="tipo" [nzPlaceHolder]="'Selecione'">
                        <nz-option [nzLabel]="'Sucesso'" [nzValue]="'success'"></nz-option>
                        <nz-option [nzLabel]="'Informação'" [nzValue]="'info'"></nz-option>
                        <nz-option [nzLabel]="'Aviso'" [nzValue]="'warning'"></nz-option>
                        <nz-option [nzLabel]="'Erro'" [nzValue]="'Erro'"></nz-option>
                    </nz-select>
                </nz-form-control>
                <nz-form-label [nzSpan]="2" [nzOffset]="2" nzRequired>Status</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <nz-select formControlName="ativo" [nzPlaceHolder]="'Selecione'" nzAllowClear>
                        <nz-option [nzLabel]="'Ativo'" [nzValue]="1"></nz-option>
                        <nz-option [nzLabel]="'Inativo'" [nzValue]="0"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="3" nzRequired>Data Início</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <nz-date-picker formControlName="dataInicio" nzFormat="dd/MM/yyyy HH:mm:ss"
                                    nzShowTime
                                    nzPlaceHolder="De:"
                    ></nz-date-picker>
                </nz-form-control>
                <nz-form-label [nzSpan]="2" [nzOffset]="2" nzRequired>Data Fim</nz-form-label>
                <nz-form-control [nzSpan]="7">
                    <nz-date-picker formControlName="dataFim" nzFormat="dd/MM/yyyy HH:mm:ss"
                                    nzShowTime
                                    nzPlaceHolder="Até:"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="3" nzRequired>Título</nz-form-label>
                <nz-form-control [nzSpan]="18">
                    <input nz-input formControlName="titulo" placeholder="Informe o título" type="text">
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="3" nzRequired>Mensagem</nz-form-label>
                <nz-form-control [nzSpan]="18">
                    <textarea formControlName="mensagem" nz-input rows="15" placeholder="Informe a mensagem"></textarea>
                </nz-form-control>
            </nz-form-item>


        </form>
    </div>
    <div *nzModalFooter>
        <button  class="new-markup-bnt" nz-button nzType="link"
                 (click)="preview(formCadastrar.formGroup.value.titulo,formCadastrar.formGroup.value.mensagem, formCadastrar.formGroup.value.tipo)"
        >
            <i nz-icon nzType="eye" nzTheme="outline"></i>Preview
        </button>
        <button nz-button nzType="link" (click)="modalCadastrar(false)">Cancelar</button>
        <button nz-button nzType="primary" [nzLoading]="cadastrando" (click)="confirmaCadastro()">Confirmar</button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="formFiltrar.modalVisible"
          [nzTitle]="'Filtros Avançados'" [nzClosable]="true"
          (nzOnCancel)="modalFiltrar(false)"
          [nzWidth]="648">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formFiltrar.formGroup" style="width: 100%">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Conta</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select formControlName="conta" [nzPlaceHolder]="'Selecione'" nzAllowClear nzShowSearch>
                        <nz-option *ngFor="let option of comboContas" [nzLabel]="option.label"
                                   [nzValue]="option.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Tipo</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select formControlName="tipo" [nzPlaceHolder]="'Selecione'" nzAllowClear>
                        <nz-option [nzLabel]="'Sucesso'" [nzValue]="'success'"></nz-option>
                        <nz-option [nzLabel]="'Informação'" [nzValue]="'info'"></nz-option>
                        <nz-option [nzLabel]="'Aviso'" [nzValue]="'warning'"></nz-option>
                        <nz-option [nzLabel]="'Erro'" [nzValue]="'Erro'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Status</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select formControlName="ativo" [nzPlaceHolder]="'Selecione'" nzAllowClear>
                        <nz-option [nzLabel]="'Ativo'" [nzValue]="'1'"></nz-option>
                        <nz-option [nzLabel]="'Inativo'" [nzValue]="'0'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Data Início</nz-form-label>
                <div nz-col [nzSpan]="14">
                    <div nz-row>
                        <nz-form-control nz-col [nzSpan]="12">
                            <nz-date-picker formControlName="dataInicioDe" nzFormat="dd/MM/yyyy"
                                            nzPlaceHolder="De:"
                            ></nz-date-picker>
                        </nz-form-control>
                        <nz-form-control nz-col [nzSpan]="12" class="text-right">
                            <nz-date-picker formControlName="dataInicioAte" nzFormat="dd/MM/yyyy"
                                            nzPlaceHolder="Até:"></nz-date-picker>
                        </nz-form-control>
                    </div>
                </div>
            </nz-form-item>

            <nz-form-item>
                <nz-form-label [nzSpan]="5">Data Fim</nz-form-label>
                <div nz-col [nzSpan]="14">
                    <div nz-row>
                        <nz-form-control nz-col [nzSpan]="12">
                            <nz-date-picker formControlName="dataFimDe" nzFormat="dd/MM/yyyy"
                                            nzPlaceHolder="De:"
                            ></nz-date-picker>
                        </nz-form-control>
                        <nz-form-control nz-col [nzSpan]="12" class="text-right">
                            <nz-date-picker formControlName="dataFimAte" nzFormat="dd/MM/yyyy"
                                            nzPlaceHolder="Até:"></nz-date-picker>
                        </nz-form-control>
                    </div>
                </div>
            </nz-form-item>

        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalFiltrar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaFiltrar()">Filtrar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="formExportar.modalVisible"
          [nzTitle]="'Exportar'" [nzClosable]="true"
          (nzOnCancel)="modalExportar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>
        <form nz-form [nzNoColon]="true" [formGroup]="formExportar.formGroup" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24">Selecione o formato do arquivo a ser exportado.</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select nzPlaceHolder="Selecione"
                               formControlName="tipo" style="width: 100%;">
                        <nz-option [nzLabel]="'.CSV'" [nzValue]="'csv'"></nz-option>
                        <nz-option [nzLabel]="'.XLSX'" [nzValue]="'xlsx'"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalExportar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaExportar()">Exportar</button>
    </div>
</nz-modal>


<nz-modal [(nzVisible)]="modalAtivarDesativarVisible"
          [nzTitle]="'Status avisos selecionados'" [nzClosable]="true"
          (nzOnCancel)="modalAtivarDesativar(false)"
          [nzWidth]="425">
    <div nz-row *nzModalContent>

            <nz-form-item style="width: 100%;">
                <nz-form-label [nzSpan]="5">Status</nz-form-label>
                <nz-form-control [nzSpan]="14">
                    <nz-select [nzPlaceHolder]="'Selecione'" [(ngModel)]="statusMassa">
                        <nz-option [nzLabel]="'Ativo'" [nzValue]="1"></nz-option>
                        <nz-option [nzLabel]="'Inativo'" [nzValue]="0"></nz-option>
                        <nz-option nzLabel="Ativo" nzValue="1" nzHide></nz-option>

                    </nz-select>
                </nz-form-control>
            </nz-form-item>

    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalAtivarDesativar(false);">Cancelar</button>
        <button nz-button nzType="primary" (click)="confirmaAtivarDesativar()">Confirmar</button>
    </div>
</nz-modal>

<nz-modal [(nzVisible)]="modalPreviewVisible" [nzTitle]="'Preview do aviso'" [nzClosable]="true"
          (nzOnCancel)="modalPreview(false)" [nzWidth]="800">
    <div nz-row *nzModalContent>
        <div style="width: 100%;" [innerHTML]="avisoPreview?.mensagem" class="alert-{{avisoPreview?.tipo}}"></div>
    </div>
    <div *nzModalFooter>
        <button nz-button nzType="link" (click)="modalPreview(false)">Fechar</button>

    </div>
</nz-modal>
